"use client";

import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "next/navigation";

import {
  FieldData,
  SelectOptionComponent,
  UpdateFreshField,
} from "@forms/schema";
import { useAPI } from "apps/website/hooks/useAPI";
import {
  ICatExtraDetails,
  ICustomerCatsResponse,
  MealPlanType,
} from "libs/api/customer/src/lib/types/cats";
import {
  PouchSize,
} from "libs/cat-calculations/src/lib/cat-calculations.types";
import { DisplayState } from "@/constants/state";
import {
  IMealPlanTypeResource,
  ITraySizeResource,
} from "@api-clients/customer";
import {
  MEAL_PLAN_CALORIES,
} from "libs/cat-calculations/src/lib/cat-calculations.constants";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { getFlowFieldValuesForInterpolation } from "@./state";
import {
  MEAL_PLAN_PRETTY_TITLES,
} from "libs/api/customer/src/lib/types/subscriptions";

import Container from "../../layout/Container/Container";
import Button from "../../base/Button/Button";
import Modal from "../../feature/Modal/Modal";
import Text from "../../base/Text/Text";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import Spacer from "../../layout/Spacer/Spacer";
import Section from "../../layout/Section/Section";
import { AlertPrompt } from "../../feature/AlertPrompt/AlertPrompt";
import FreeFlowRichText from "../../feature/Freeflow/FreeFlowRichText/FreeFlowRichText";

import { FormServiceRadioSelect } from "./RadioSelect";

export interface IFormServiceFreshSizeCadenceSelect {
  field: UpdateFreshField;
  flowId: string;
  optional?: boolean;
  linkingId: string;
  selected?: FieldData;
  helpText: string;
  optionSize: string;
}
export type IFormServiceFreshSizeCadenceSelectOptions = {
  traySizes: SelectOptionComponent<string>[];
  mealsPlans: SelectOptionComponent<string>[];
};

export const FormServiceUpdateFresh: FC<IFormServiceFreshSizeCadenceSelect> = ({
  flowId,
  field,
  optional,
  linkingId,
  selected,
}) => {
  const api = useAPI();

  const searchParams = useSearchParams();

  const [ cats, setCats ] = useState<ICustomerCatsResponse>();
  const [ catId, setCatId ] = useState<string>();
  const [ cat, setCat ] = useState<ICatExtraDetails>();
  const [ options, setOptions ] =
    useState<IFormServiceFreshSizeCadenceSelectOptions>();
  const [ availableTraySizes, setAvailableTraySizes ] =
    useState<ITraySizeResource[]>();
  const [ availableMealPlanTypes, setAvailableMealPlanTypes ] =
    useState<IMealPlanTypeResource[]>();
  const [ currentTraySize, setCurrentTraySize ] = useState<PouchSize>();
  const [ currentTrayCadence, setCurrentTrayCadance ] = useState<MealPlanType>();
  const [ selectedTraySize, setSelectedTraySize ] = useState<PouchSize>();
  const [ selectedTrayCadence, setSelectedTrayCadance ] =
    useState<MealPlanType>();
  const [ formState, setFormState ] = useState<DisplayState>(DisplayState.READY);
  const [ showSuccessfullyUpdatedModal, setShowSuccessfullyUpdatedModal ] =
    useState<boolean>(false);
  const [ error, setError ] = useState<string>();

  const [ mealPlanCadence, setMealPlanCadence ] = useState<number>();
  const [ mealPlanQuantity, setMealPlanQuantity ] = useState<number>();
  const [ mealPlanTitle, setMealPlanTitle ] = useState<string>();
  const [ mealPlanCalories, setMealPlanCalories ] = useState<number>();

  const getCats = useCallback(async () => api.Customer.getCats(), []);
  const getAvailableTraySizes = useCallback(
    async (planType: MealPlanType) => api.Customer.getAvailableTraySizes(planType),
    [],
  );
  const getAvailableMealPlanTypes = useCallback(
    async () => api.Customer.getAvailableMealPlanTypes(),
    [],
  );
  const getOptions = useCallback(
    (): IFormServiceFreshSizeCadenceSelectOptions => ({
      traySizes:
        availableTraySizes?.map((tray) => ({
          _uid: tray.traySize as PouchSize,
          title: `${tray.calories} kcal/day`,
          value: tray.traySize as PouchSize,
          component: "select_option",
          selected: currentTraySize === tray.traySize,
          advanced_options: {
            secondaryText: "",
            tag:
              cat?.suggestedTraySize === tray.traySize
                ? "Recommended"
                : undefined,
            tertiaryText: `£${(
              tray.price /
              (availableMealPlanTypes?.find(
                (type) => type.planType === currentTrayCadence,
              )?.cadence || 1)
            ).toFixed(2)}/day`,
            current: currentTraySize === tray.traySize,
          },
        })) || [],
      mealsPlans:
        availableMealPlanTypes?.map((type) => ({
          _uid: type.planType,
          title: MEAL_PLAN_PRETTY_TITLES[type.planType] || `Every ${type.cadence} days`,
          value: type.planType,
          component: "select_option",
          selected: currentTrayCadence === type.planType,
          advanced_options: {
            secondaryText: `${type.quantity} days worth of food every ${type.cadence} days`,
            tertiaryText: `£${currentTraySize && currentTrayCadence
              ? (type.traySize[currentTraySize].price / type.cadence).toFixed(
                2,
              )
              : ""
            }/day`,
            tag: type.planType === "Plan42x42" ? "Best value" : undefined,
            current: currentTrayCadence === type.planType,
          },
        })) || [],
    }),
    [
      cats,
      currentTraySize,
      currentTrayCadence,
      availableMealPlanTypes,
      availableTraySizes,
    ],
  );

  const updateSubscription = () => {
    if (
      (selectedTrayCadence || currentTrayCadence) &&
      (selectedTraySize || currentTraySize) &&
      catId
    ) {
      setFormState(DisplayState.PROCESSING);
      api.Customer.updateFreshSizeAndCadence({
        newSize: selectedTraySize || (currentTraySize as PouchSize),
        newMealPlan:
          selectedTrayCadence || (currentTrayCadence as MealPlanType),
        catId,
        source: "Customer",
        cxUser: undefined,
      })
        .then(() => {
          setShowSuccessfullyUpdatedModal(true);
        })
        .catch(() => {
          setError(
            `Could not update ${cat?.cat.name}'s ${field.type === "mealPlan" ? "meal plan" : "tray size"
            }, please try again.`,
          );
        })
        .finally(() => {
          setFormState(DisplayState.READY);
        });
    }
  };

  const getSelectedMealPlan = useMemo(() => {
    if (cat) {
      const catDefaultPlan = availableMealPlanTypes?.find(
        (plan) => plan.planType === cat.mealPlan,
      );
      if (!selected || field.type === "traySize") {
        return catDefaultPlan;
      }

      return availableMealPlanTypes?.find(
        (plan) => plan.planType === selected.submitValue,
      );
    }
  }, [ cat, cats, selected, field.type, selectedTrayCadence, selectedTraySize ]);

  const getSelectedPouch = useMemo(() => {
    if (cat) {
      const catDefaultTraySize =
        getSelectedMealPlan?.traySize[cat.mealPlanSize];
      if (!selected || field.type === "mealPlan") {
        return catDefaultTraySize;
      }

      return getSelectedMealPlan?.traySize[selected.submitValue as PouchSize];
    }
  }, [
    cat,
    cats,
    selected,
    field.type,
    getSelectedMealPlan,
    selectedTrayCadence,
    selectedTraySize,
  ]);

  const getIsNewPouchSizeSmaller = useMemo(() => {
    if (mealPlanCalories && currentTrayCadence) {
      if (mealPlanCalories < MEAL_PLAN_CALORIES[currentTraySize as PouchSize]) {
        return true;
      }
    }

    return false;
  }, [
    cat,
    cats,
    selected,
    field.type,
    getSelectedMealPlan,
    getSelectedPouch,
    currentTraySize,
    selectedTrayCadence,
    selectedTraySize,
    mealPlanCalories,
  ]);

  useEffect(() => {
    async function setData() {
      setCats(await getCats());
    }
    void setData();
  }, []);

  useEffect(() => {
    async function setData() {
      const getCatId = searchParams.get("catId");
      if (getCatId) {
        setCatId(getCatId);
      }
    }
    void setData();
  }, [ cats, searchParams ]);

  useEffect(() => {
    async function setData() {
      console.log("CAT_ID", catId);
      if (catId) {
        console.log("CAT CAT", cats?.cats);
        setCat(cats?.cats[catId]);
      }
      setAvailableMealPlanTypes(await getAvailableMealPlanTypes());
    }
    void setData();
  }, [ catId, cats ]);

  useEffect(() => {
    console.log("IF CAT", cat);
    if (cat) {
      setCurrentTrayCadance(cat.mealPlan);
      setCurrentTraySize(cat.mealPlanSize);
    }
  }, [ cat, cats ]);

  useEffect(() => {
    async function setData() {
      if (currentTrayCadence) {
        setAvailableTraySizes(await getAvailableTraySizes(currentTrayCadence));
      }
    }
    void setData();

    if (!selectedTrayCadence) {
      setSelectedTrayCadance(currentTrayCadence);
    }
    if (!selectedTraySize) {
      setSelectedTraySize(currentTraySize);
    }
  }, [ currentTrayCadence, currentTraySize, availableMealPlanTypes ]);

  useEffect(() => {
    async function setData() {
      setOptions(await getOptions());
    }
    void setData();
  }, [
    currentTrayCadence,
    currentTraySize,
    availableTraySizes,
    availableMealPlanTypes,
  ]);

  useEffect(() => {
    const plan = availableMealPlanTypes?.find(
      (mappedPlan) => mappedPlan.planType === selectedTrayCadence,
    );
    if (plan && selectedTraySize) {
      setMealPlanCadence(plan.cadence);
      setMealPlanQuantity(plan.quantity);
      setMealPlanTitle(plan.title);
      setMealPlanCalories(plan.traySize[selectedTraySize].calories);
    }
  }, [
    selectedTrayCadence,
    selectedTraySize,
    availableTraySizes,
    availableMealPlanTypes,
  ]);

  return (
    <>
      { error && (
        <AlertPrompt
          style="error"
          title={ `Error updating ${field.type === "mealPlan" ? "meal plan" : "tray size"
          }` }
        >
          { error }
        </AlertPrompt>
      ) }
      { field.tray_size_introduction && (
        <>
          <FreeFlowRichText
            size={ legacySizeCollectionMap.base }
            withSpacer={ false }
          >
            { renderStoryBlokRichText(field.tray_size_introduction, {
              interpolation: {
                values: getFlowFieldValuesForInterpolation(
                  flowId ?? "UNKNOWN",
                  "0",
                  "GET_RELATED",
                ),
              },
              tags: {
                MARK_CODE: {
                  display: "default",
                },
              },
            }) }
          </FreeFlowRichText>
          <Spacer size="md" />
        </>
      ) }
      { [ "traySize", "both" ].includes(field.type as string) && (
        <FormServiceRadioSelect
          flowId={ flowId }
          field={ {
            ...field,
            component: "radio_select",
            label: "Select tray size",
            hide_label: true,
            name: "traySize",
            options:
              options?.traySizes.map((option) => ({
                ...option,
                advanced_options: {
                  ...option.advanced_options,
                },
              })) || [],
            helpText: "",
            optionSize: "",
          } }
          onChange={ (event) => {
            setSelectedTraySize(event.submitValue as PouchSize);
          } }
          optional={ optional }
          linkingId={ linkingId }
          selected={ {
            submitValue: selectedTraySize,
            displayValue: selectedTraySize,
          } }
          optionStyle="confirmationModal"
        />
      ) }
      { field.meal_plan_introduction && (
        <>
          <Spacer size="xl" />
          <FreeFlowRichText
            size={ legacySizeCollectionMap.base }
            withSpacer={ false }
          >
            { renderStoryBlokRichText(field.meal_plan_introduction, {
              interpolation: {
                values: getFlowFieldValuesForInterpolation(
                  flowId ?? "UNKNOWN",
                  "0",
                  "GET_RELATED",
                ),
              },
            }) }
          </FreeFlowRichText>
          <Spacer size="md" />
        </>
      ) }
      { [ "mealPlan", "both" ].includes(field.type as string) && (
        <FormServiceRadioSelect
          flowId={ flowId }
          field={ {
            ...field,
            component: "radio_select",
            name: "mealPlan",
            label: "Select meal plan",
            hide_label: true,
            options:
              options?.mealsPlans.map((option) => ({
                ...option,
                advanced_options: {
                  ...option.advanced_options,
                },
              })) || [],
            helpText: "",
            optionSize: "",
          } }
          onChange={ (event) => {
            setSelectedTrayCadance(event.submitValue as MealPlanType);
          } }
          optional={ optional }
          linkingId={ linkingId }
          selected={ {
            submitValue: selectedTrayCadence,
            displayValue: selectedTrayCadence,
          } }
          optionStyle="confirmationModal"
        />
      ) }

      <Section
        theme="brand"
        size="sm"
        className={ "fixed bottom-0 left-0 w-full p-4 z-50" }
      >
        <Container className="flex items-center justify-center flex-col-reverse md:flex-row">
          { selectedTraySize !== currentTraySize ||
            selectedTrayCadence !== currentTrayCadence ? (
              <Button
                color="secondary"
                onClick={ updateSubscription }
                state={ formState }
              >
              Update my subscription
              </Button>
            ) : (
              <Button color="secondary" to="/manage-plan">
              Keep my subscription
              </Button>
            ) }
        </Container>
      </Section>

      <Modal
        isOpen={ showSuccessfullyUpdatedModal }
        onClose={ () => console.error("Cannot close") }
        hideCloseButton={ true }
        id="modal"
        component="modal"
        modalTitleId="modal"
        size="md"
      >
        <Grid className="py-12 px-8">
          <Column justify="center" align="center">
            { cat && getSelectedMealPlan && getSelectedPouch && (
              <>
                { currentTrayCadence === selectedTrayCadence &&
                  currentTraySize !== selectedTraySize && (
                  <>
                    <Text
                      align="center"
                      display="title"
                      size={ legacySizeCollectionMap.titleMd }
                    >
                        You&apos;ve changed { cat?.cat.name }&apos;s portion size
                    </Text>
                    <Spacer size="lg" />
                    <Text align="center">
                      { cat.cat.name } will start receiving{ " " }
                      { getIsNewPouchSizeSmaller ? "smaller" : "larger" }{ " " }
                        portions.
                      { getIsNewPouchSizeSmaller
                        ? " Use other food to cover the outstanding"
                        : ` ${cat?.cat.name}'s new calorie intake will be` }
                        :
                    </Text>
                    <Text
                      align="center"
                      size={ legacySizeCollectionMap.titleSm }
                      display="title"
                    >
                      { getIsNewPouchSizeSmaller
                        ? `${cat.suggestedCalorieAmount -
                          (mealPlanCalories || 0)
                        }`
                        : mealPlanCalories || 0 }{ " " }
                        kcal / day
                    </Text>
                  </>
                ) }
                { currentTrayCadence !== selectedTrayCadence &&
                  currentTraySize === selectedTraySize && (
                  <>
                    <Text
                      align="center"
                      display="title"
                      size={ legacySizeCollectionMap.titleMd }
                    >
                      <>
                          You&apos;ve changed { cat?.cat.name }&apos;s meal plan
                      </>
                    </Text>
                    <Spacer size="lg" />
                    <Text align="center">
                      <>{ `Because you chose our ${mealPlanTitle}
                      plan, we'll now deliver:` }</>
                    </Text>
                    <Text
                      align="center"
                      size={ legacySizeCollectionMap.titleSm }
                      display="title"
                    >
                      <>{ `${mealPlanQuantity} days worth of food every
                      ${mealPlanCadence} days` }</>
                    </Text>
                    { selectedTrayCadence === "Plan14x14" && (
                      <Text align="center">
                          This will help you save freezer space.
                      </Text>
                    ) }
                  </>
                ) }
                { currentTrayCadence !== selectedTrayCadence &&
                  currentTraySize !== selectedTraySize && (
                  <>
                    <Text
                      align="center"
                      display="title"
                      size={ legacySizeCollectionMap.titleMd }
                    >
                      { cat?.cat.name }&apos;s new meal plan
                    </Text>
                    <Spacer size="lg" />
                    <Text align="center">
                      <>
                          From { cat.cat.gender === "BOY" ? "his" : "her" } next
                          delivery, { cat.cat.name } will start receiving:
                      </>
                    </Text>
                    <Text
                      align="center"
                      size={ legacySizeCollectionMap.titleSm }
                      display="title"
                    >
                      <>{ `${mealPlanQuantity}
                    days worth of food every ${mealPlanCadence}
                    days` }</>
                    </Text>
                  </>
                ) }
                <Text align="center">
                  <>
                    And { cat.cat.gender === "BOY" ? "he" : "she" } will get{ " " }
                    { getIsNewPouchSizeSmaller
                      ? `${cat.suggestedCalorieAmount - (mealPlanCalories || 0)
                      }`
                      : `${mealPlanCalories}` }{ " " }
                    kcal from KatKin fresh per day.
                  </>
                </Text>
                <Text align="center">
                  <>
                    The total cost to feed { cat.cat.name } will be £
                    { (cat.price / 28).toFixed(2) } per day.
                  </>
                </Text>
              </>
            ) }
            <Spacer size="lg" />
            <Button to="/customer">Back to homepage</Button>
          </Column>
        </Grid>
      </Modal>
    </>
  );
};
