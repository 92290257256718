import {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";

import { SelectOptionComponent, StepCounterSelectField } from "@forms/schema";
import Icon from "apps/website/components/base/Icon/Icon";
import { themeRootClassMap } from "apps/website/maps/Theme.map";

export interface IRecommendedAlertBox {
  defaultSelected: string;
  field: StepCounterSelectField; // This should be extended
  selected: string;
}

const RecommendedAlertBox: FC<IRecommendedAlertBox> = ({
  field,
  defaultSelected,
  selected,
}) => {

  const [ defaultSelectedDetails, setDefaultSelectedDetails ] = useState<SelectOptionComponent<string>>();
  const [ isDefaultSelected, setIsDefaultSelected ] = useState<boolean | undefined>(undefined);

  const makeDefaultDataText = useCallback((text: string | undefined): string => {
    if (!text) return "";
    const replace = /\[\[(.*?)\]\]/;
    const variable = replace.exec(text);

    if (!variable) return text;

    const updatedText = text.replace(replace.exec(text)?.[0] ?? "", defaultSelectedDetails?.title ?? "");
    return makeDefaultDataText(updatedText);
  }, [ defaultSelectedDetails ]);

  useEffect(() => {
    setDefaultSelectedDetails(field.options.find((option) => option.value === defaultSelected));
  }, [ defaultSelected, field.options ]);

  useEffect(() => {
    setIsDefaultSelected(defaultSelected === field.options.find((option) => option._uid === selected)?.value);
  }, [ defaultSelected, selected ]);

  return (
    <>
      { (defaultSelected && field.recommended_value_alert?.showAlertBox) && (
        <div className={`p-4 flex items-center my-4 ${themeRootClassMap.green}`} data-theme="green">
          <div className="mr-4">
            <Icon icon={isDefaultSelected ? "tick" : "warning"} size="small"/>
          </div>
          <div className="w-full">
            { isDefaultSelected ? (
              <>
                { makeDefaultDataText(field.recommended_value_alert.recommendedSelectedText) }
              </>
            ) : (
              <>
                { makeDefaultDataText(field.recommended_value_alert.recommendedNotSelectedText) }
              </>
            ) }

          </div>
        </div>
      ) }
    </>
  );
};

export default RecommendedAlertBox;
