import { FC } from "react";

import Column from "apps/website/components/layout/Column/Column";
import FormIconTextList from "apps/website/components/form/FormIconTextList/FormIconTextList";
import {
  IIconTextItem,
} from "apps/website/components/feature/IconTextList/IconTextListItem/IconTextListItem";
import {
  Columns,
} from "apps/website/components/feature/IconTextList/IconTextListItem/IconTextListItem.map";

export interface IFormServiceIconTextList {
  title: string;
  body: string;
  items: IIconTextItem[];
  columns: Columns;
}

export const FormServiceIconTextList: FC<IFormServiceIconTextList> = ({ title, body, items, columns }) => (
  <Column>
    <FormIconTextList title={title} body={body} items={items} columns={columns}/>
  </Column>
);
