"use client";

import clsx from "clsx";
import { FC } from "react";

export interface ISelectItem {
  name: string;
  onClick?: () => void;
  selected: boolean;
}

interface Props {
  items: ISelectItem[]
  disabled?: boolean;
}

const baseStyle = "relative inline-flex items-center border border-b-0 md:border-b border-gray-300 px-4 py-2 text-sm font-medium  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500";
const selectedStyle = "bg-indigo-600 text-white";
const unselectedStyle = "bg-gray-50 hover:bg-gray-200 text-gray-700";

export const SelectableButtonGroup: FC<Props> = ({
  items,
  disabled = false,
}) => (
  <span data-component={SelectableButtonGroup.name} className="isolate flex flex-col md:flex-row rounded-md shadow-sm">
    { items.map((item, index) => (
      <button
        disabled={disabled || !item.onClick}
        key={item.name}
        type="button"
        onClick={item.onClick}
        className={clsx(
          baseStyle,
          index === 0 && "rounded-t-md md:rounded-tr-none md:rounded-l-md border-b-0 md:border-b",
          index === items.length - 1 && "rounded-b-md md:rounded-l-none md:rounded-r-md border-b md:border-t md:border-r",
          item.selected ? selectedStyle : unselectedStyle,
          (disabled || !item.onClick) && "cursor-not-allowed",
          (disabled || !item.onClick) && !item.selected && "bg-gray-200 text-gray-400",
        )}
      >
        { item.name }
      </button>
    )) }
  </span>
);
