import { FC } from "react";

import Grid from "../../layout/Grid/Grid";
import Spacer from "../../layout/Spacer/Spacer";
import {
  IStoryBlokTextBlockWithAccordion,
} from "../../storyblok/sections/fieldsets/StoryBlokFieldsetTextBlockWithAccordion";

import { FieldsetFooterComponent } from "./FieldsetFooterComponent";

type FieldSetFooterComponent = (IStoryBlokTextBlockWithAccordion);

export interface IFieldsetFooter {
  flowId: string;
  linkingId?: string;
  components?: FieldSetFooterComponent[];
}

export const FieldsetFooter: FC<IFieldsetFooter> = ({ flowId, linkingId, components }) => (
  <>
    <Spacer size="lg" />
    <Grid>
      { components?.map((blok: FieldSetFooterComponent) => (
        <FieldsetFooterComponent
          key={blok._uid}
          component={blok.component}
          blok={blok}
          flowId={flowId}
          linkingId={linkingId}
        />
      )) }
    </Grid>
  </>
);
