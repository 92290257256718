"use client";

import { useMemo } from "react";

import { useTextInput } from "@/hooks";

import { ComboBox, ComboBoxProps } from "./ComboBox";

/**
 * Combo box that simply filters options by doing a contains match
 * on the search string operation
 */
export function LocalSearchComboBox<T>({
  options,
  extractName,
  selected: currentlySelected,
  setSelected,
}: ComboBoxProps<T>) {

  const [ searchText, setSearchText ] = useTextInput();

  const filteredOptions = useMemo(
    () => options.filter((option) => extractName(option).toLowerCase().includes(searchText.toLowerCase())),
    [ options, extractName, searchText ],
  );

  return (
    <ComboBox
      options={filteredOptions}
      selected={currentlySelected}
      setSelected={setSelected}
      extractName={extractName}
      setSearchText={setSearchText}
    />
  );
}
