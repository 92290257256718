import {
  FC,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";

import Input from "apps/website/components/form/Input/Input";
import Grid from "apps/website/components/layout/Grid/Grid";
import { Address } from "@./address";
import Column from "apps/website/components/layout/Column/Column";
import Button from "apps/website/components/base/Button/Button";
import Select from "apps/website/components/form/Select/Select";
import { DisplayState } from "@/constants/state";
import {
  IFormattedAddress,
  IGetAddressAddresses,
  IGetAddressResponse,
} from "libs/address/src/lib/address.types";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { useDatadog } from "@auth/client-sdk-react";
import { useNewFormsServiceStore } from "@./state";
import { useFlow } from "apps/website/contexts/flow";

import SimpleCheckbox from "../Checkbox/SimpleCheckbox/SimpleCheckbox";
import FormError from "../../form-service/fields/Error";
import {
  FieldsetStateButton,
} from "../FieldsetStateButton/FieldsetStateButton";

import { ISO_3166_COUNTRY_OPTIONS } from "./AddressLookupCountries";

export interface IAddressLookup {
  onAddressUpdated(shippingAddress: IFormattedAddress, billingAddress?: IFormattedAddress): void;
  hideContinueToPayment?: boolean;
  hideBillingAddress?: boolean;
  defaultPostcode?: string;
  address?: IFormattedAddress;
  billingAddress?: IFormattedAddress;
  optional?: boolean;
}

const AddressLookup: FC<IAddressLookup> = ({ onAddressUpdated,
  address,
  optional,
  defaultPostcode,
  hideContinueToPayment = false,
  hideBillingAddress = false,
}) => {

  const datadog = useDatadog();
  const { fetchAddressFromPostcode, postcodeCheck } = Address();
  const [ addressData, setAddressData ] = useState<IGetAddressResponse | undefined>(undefined);
  const [ availableAddresses, setAvailableAddresses ] = useState<IFormattedAddress[] | undefined>(undefined);
  const [ postcodeValue, setPostcodeValue ] = useState<string>(defaultPostcode ?? "");
  const [ state, setState ] = useState<DisplayState>(DisplayState.READY);
  const [ selectedAddress, setSelectedAddress ] = useState<IFormattedAddress | undefined>(undefined);
  const [ definedAddress, setDefinedAddress ] = useState<IFormattedAddress>({ postcode: defaultPostcode ?? "", address1: "", address2: "", city: "", country: "", phone: "" });
  const [ billingAddress, setBillingAddress ] = useState<IFormattedAddress>({ postcode: defaultPostcode ?? "", address1: "", address2: "", city: "", country: "", phone: "" });
  const [ shouldBillingAddressUseDefinedAddress, setShouldBillingAddressUseDefinedAddress ] = useState(true);
  const [ showPostcodeError, setShowPostcodeError ] = useState(false);
  const [ showAddressMismatch, setShowAddressMismatch ] = useState(false);
  const [ subFieldErrorsToBeShown, setSubFieldErrorsToBeShown ] = useState<string[]>([]);
  const [ showEnterAddressManuallyForm, setShowEnterAddressManuallyForm ] = useState(false);

  const flow = useFlow();

  const { getFlowFieldErrors } = useNewFormsServiceStore();

  const [ hasPhoneErrors, setHasPhoneErrors ] = useState(true);
  const [ hasAddressErrors, setHasAddressErrors ] = useState(true);

  const handleFetchAddressesFromPostcode = async (postcode: string) => {
    setState(DisplayState.PROCESSING);
    try {
      const addressDataResponse = await fetchAddressFromPostcode(postcode);
      datadog.logger.info(
        `Got address data response for postcode ${postcode}`,
        {
          eventName: "GotAddressDataResponseSuccess",
          eventType: "AddressLookup",
          addressDataResponse,
          postcode,
        },
      );
      setAddressData(addressDataResponse);
    } catch (e) {
      datadog.logger.error(
        `Failed to get address data for postcode ${postcode}: ${e}`,
        {
          eventName: "GotAddressDataResponseFailure",
          eventType: "AddressLookup",
          postcode,
          error: e,
        },
        e as Error,
      );
      setShowPostcodeError(true);
      setState(DisplayState.READY);
    }
  };

  const removeSubFieldError = useCallback((subfield: string) => {
    setSubFieldErrorsToBeShown(subFieldErrorsToBeShown.filter((mappedSubfield) => mappedSubfield !== subfield));
  }, [ subFieldErrorsToBeShown ]);

  const makeAddress = useCallback((addressToMake: IGetAddressAddresses): IFormattedAddress => {
    let line1 = "";
    let line2 = "";
    if (addressToMake.line_1) {
      if (addressToMake.line_2) {
        if (addressToMake.line_3) {
          if (addressToMake.line_4) {
            line1 = `${addressToMake.line_1} ${addressToMake.line_2}`;
            line2 = `${addressToMake.line_3} ${addressToMake.line_4}`;
          } else {
            line1 = `${addressToMake.line_1} ${addressToMake.line_2}`;
            line2 = addressToMake.line_3;
          }
        } else {
          line1 = addressToMake.line_1;
          line2 = addressToMake.line_2;
        }
      } else {
        line1 = addressToMake.line_1;
      }
    }
    return {
      formattedAddress: addressToMake.formatted_address,
      address1: line1,
      address2: line2,
      city: addressToMake.town_or_city,
      country: addressToMake.country,
      postcode: addressData?.postcode ?? "",
      phone: "",
    };
  }, [ addressData ]);

  const onSelectAddress = (stringAddress: string) => {
    const addressObject = { ...JSON.parse(stringAddress.replaceAll("\\\"", "\"")), phone: definedAddress?.phone || "" }; // Replace all is to accommodate tests
    setSelectedAddress(addressObject);
    setDefinedAddress(addressObject);
    if (shouldBillingAddressUseDefinedAddress) {
      setBillingAddress(addressObject);
    }
  };

  const onChangePostCode = (postcode: string) => {
    setPostcodeValue(postcode);
    setDefinedAddress({ ...definedAddress, postcode });
  };

  const handleSetDefinedAddress = useCallback((
    updatedAddress: IFormattedAddress,
    subfield: string | undefined = undefined,
  ) => {
    if (shouldBillingAddressUseDefinedAddress) {
      setBillingAddress(updatedAddress);
    }
    setDefinedAddress(updatedAddress);

    if (subfield) {
      setSubFieldErrorsToBeShown(subFieldErrorsToBeShown.filter((fieldName) => fieldName !== subfield));
    }
  }, [ shouldBillingAddressUseDefinedAddress, subFieldErrorsToBeShown ]);

  const handleOnBlur = useCallback((subFieldName: string) => {
    const errors = getFlowFieldErrors(flow.flow.slug, "address", "0", subFieldName);
    if (errors && errors.length) {
      if (subFieldErrorsToBeShown.includes(subFieldName)) {
        return;
      }
      const newArray = [ ...subFieldErrorsToBeShown, subFieldName ];
      setSubFieldErrorsToBeShown(newArray);
    }
  }, [ getFlowFieldErrors, subFieldErrorsToBeShown, definedAddress.phone, billingAddress ]);

  const addressFieldsErrors = useCallback((subFieldName: string) => {
    const errors = getFlowFieldErrors(flow.flow.slug, "address", "0", subFieldName);
    if (errors && errors.length) {
      const matchingError = errors.find((x) => x.subFieldName === subFieldName);
      return (
        <><Spacer size="md" /><FormError>
          { matchingError?.error?.message }
        </FormError></>);
    }
  }, [ getFlowFieldErrors ]);

  useEffect(() => {
    if (address && !selectedAddress) {
      setDefinedAddress(address);
      setPostcodeValue(address.postcode);
    }
  }, [ address ]);

  useEffect(() => {
    setAvailableAddresses(addressData?.addresses.map((mappedAddress) => makeAddress(mappedAddress)));
    setState(DisplayState.READY);
    setShowPostcodeError(false);
  }, [ addressData, makeAddress ]);

  useEffect(() => {
    if (!definedAddress) return;
    const updatedBillingAddress = shouldBillingAddressUseDefinedAddress ? definedAddress : billingAddress;
    onAddressUpdated(definedAddress, updatedBillingAddress);
    if (selectedAddress && (JSON.stringify({ ...definedAddress, phone: "" }) !== JSON.stringify({ ...selectedAddress, phone: "" }))) {
      setShowAddressMismatch(true);
    } else {
      setShowAddressMismatch(false);
    }
  }, [ definedAddress, selectedAddress, billingAddress ]);

  useEffect(() => {
    const errors = getFlowFieldErrors(flow.flow.slug, "address", "0");
    if (errors && errors.length) {
      const matchingPhoneError = errors.some((x) => x.subFieldName === "phone");
      const matchingAddressErrors = errors.some((x) => x.subFieldName !== "phone");
      setHasPhoneErrors(matchingPhoneError);
      setHasAddressErrors(matchingAddressErrors);
    } else {
      setHasPhoneErrors(false);
      setHasAddressErrors(false);
    }
  });

  useEffect(() => {
    if (addressData) {
      setPostcodeValue(addressData.postcode);
    }
  }, [ addressData, selectedAddress ]);

  useEffect(() => {
    if (shouldBillingAddressUseDefinedAddress) {
      setBillingAddress(definedAddress);
    } else {
      setBillingAddress({
        address1: "",
        address2: "",
        city: "",
        country: "",
        postcode: "",
        phone: definedAddress.phone,
      });
    }
  }, [ shouldBillingAddressUseDefinedAddress ]);

  useEffect(() => {
    if (shouldBillingAddressUseDefinedAddress) {
      setBillingAddress(definedAddress);
    }
  }, [ definedAddress ]);

  useEffect(() => {
    handleSetDefinedAddress({ ...definedAddress, country: "United Kingdom" });
  }, []);

  useEffect(() => {
    if (!!definedAddress.address1.length && !!definedAddress.city.length && !!definedAddress.postcode.length) {
      setShowEnterAddressManuallyForm(true);
    }
  }, [ selectedAddress, definedAddress ]);

  useEffect(() => {
    setBillingAddress({ ...billingAddress, phone: definedAddress.phone });
  }, [ definedAddress.phone ]);

  return (
    <>
      <Suspense>
        <Grid component={AddressLookup.name}>
          <Column>
            <Input name="phone" type="tel" label="Phone Number" labelStyle="alternative" value={definedAddress?.phone} onBlur={() => handleOnBlur("phone")} onChange={(event) => handleSetDefinedAddress({ ...definedAddress, phone: event.target.value }, "phone")} optional={optional} isValid={!hasPhoneErrors} inputClassName={`${subFieldErrorsToBeShown.includes("phone") ? "ring-offset-2 ring-2 ring-orange rounded outline-orange" : ""}`} labelLayout="secondary"/>
            { subFieldErrorsToBeShown.includes("phone") && (addressFieldsErrors("phone")) }
          </Column>
          <Column direction="row" align="end" spans={{ default: 6, lg: 7 }}>
            <Input name="postcode" labelStyle="alternative" value={postcodeValue} label="Postcode" onChange={(event) => onChangePostCode(event.target.value)} className="w-full" optional={optional} isValid={postcodeCheck(postcodeValue).errorType === null} labelLayout="secondary" />
          </Column>
          <Column spans={{ default: 6, lg: 5 }} justify="end" align="end">
            <Button
              state={state}
              onClick={() => handleFetchAddressesFromPostcode(postcodeValue)}
              className="max-w-full"
              data-testid="findAddress"
            >
              Find address
            </Button>
          </Column>
        </Grid>
      </Suspense>
      { showPostcodeError ? (
        <>
          <Spacer size="lg"/>
          <FormError>
            There was a problem with the postcode you entered, please check and try again.
          </FormError>
        </>
      ) : (
        <>
          { availableAddresses && (
            <>
              <Spacer size="lg"/>
              <Grid>
                <Column>
                  <Select
                    options={availableAddresses.map((mappedAddress) => ({
                      name: mappedAddress.formattedAddress?.filter((addressPart: string) => addressPart.trim() !== "").join(",") ?? "",
                      value: JSON.stringify(mappedAddress).replaceAll("\"", "\\\""), // Replace all is to accommodate tests
                    }))}
                    label="Addresses"
                    labelStyle="alternative"
                    name="addresses"
                    hideLabel
                    onChange={(event) => onSelectAddress(event.target.value)}
                    selected={JSON.stringify(selectedAddress)?.replaceAll("\"", "\\\"") || ""} // Replace all is to accommodate tests
                    placeholder="Select address"
                    labelLayout="secondary"
                  />
                </Column>
              </Grid>
            </>
          ) }
        </>
      ) }
      <Spacer size="lg"/>
      <Suspense>
        <Grid>
          <Column>
            { (!showEnterAddressManuallyForm) ? (
              <button className="w-full" onClick={() => setShowEnterAddressManuallyForm(true)}>
                <Text display="subtitle" size={legacySizeCollectionMap.titleSm} className="underline" align="center">or enter address manually</Text>
              </button>
            ) : <></> }
          </Column>
          { showEnterAddressManuallyForm ? (
            <>
              <Column>
                <Input name="address_1" label="Address Line 1" labelStyle="alternative" value={definedAddress?.address1} onBlur={() => handleOnBlur("shipping-line1")} onChange={(event) => handleSetDefinedAddress({ ...definedAddress, address1: event.target.value }, "shipping-line1")} optional={optional} max={35} isValid={!!definedAddress?.address1.length} inputClassName={`${subFieldErrorsToBeShown.includes("shipping-line1") ? "ring-offset-2 ring-2 ring-orange rounded outline-orange" : ""}`} labelLayout="secondary"/>
                { subFieldErrorsToBeShown.includes("shipping-line1") && addressFieldsErrors("shipping-line1") }
              </Column>
              <Column>
                <Input name="address_2" label="Address Line 2" labelStyle="alternative" value={definedAddress?.address2} onChange={(event) => handleSetDefinedAddress({ ...definedAddress, address2: event.target.value })} optional max={35} labelLayout="secondary"/>
              </Column>
              <Column>
                <Input name="city" label={"Town or City"} labelStyle="alternative" onBlur={() => handleOnBlur("shipping-city")} value={definedAddress?.city} onChange={(event) => handleSetDefinedAddress({ ...definedAddress, city: event.target.value }, "shipping-city")} optional={optional} max={35} isValid={!!definedAddress?.city.length} inputClassName={`${subFieldErrorsToBeShown.includes("shipping-city") ? "ring-offset-2 ring-2 ring-orange rounded outline-orange" : ""}`} labelLayout="secondary"/>
                { subFieldErrorsToBeShown.includes("shipping-city") && addressFieldsErrors("shipping-city") }
              </Column>
              <>
                { showAddressMismatch && (
                  <Column>
                    <FormError>
              You&apos;ve updated your address, it doesn&apos;t match the
              selected address, please ensure it&apos;s correct before continuing.
                    </FormError>
                  </Column>
                ) }
              </>
              { !hideBillingAddress && (
                <>
                  <Column>
                    <Text display="subtitle" size={legacySizeCollectionMap.titleSm}>Billing Address</Text>
                  </Column>
                  <Column>
                    <SimpleCheckbox
                      name="should_use_defined_address"
                      checked={shouldBillingAddressUseDefinedAddress}
                      label="Billing address is the same as delivery address"
                      onChange={(event) => setShouldBillingAddressUseDefinedAddress(event.target.checked)}
                    />
                  </Column>
                  <Column className={shouldBillingAddressUseDefinedAddress ? "hidden" : ""} aria-hidden={shouldBillingAddressUseDefinedAddress}>
                    <Grid>
                      <Column>
                        <Input name="billing_address_1" label="Address Line 1" labelStyle="alternative" onBlur={() => handleOnBlur("billing-line1")} value={billingAddress?.address1} onChange={(event) => { setBillingAddress({ ...billingAddress, address1: event.target.value }); removeSubFieldError("billing-line1"); }} optional={optional} isValid={!!billingAddress?.address1.length} inputClassName={`${subFieldErrorsToBeShown.includes("billing-line1") ? "ring-offset-2 ring-2 ring-orange rounded outline-orange" : ""}`} labelLayout="secondary"/>
                        { subFieldErrorsToBeShown.includes("billing-line1") && addressFieldsErrors("billing-line1") }
                      </Column>
                      <Column>
                        <Input name="billing_address_2" label="Address Line 2" labelStyle="alternative" value={billingAddress?.address2} onChange={(event) => setBillingAddress({ ...billingAddress, address2: event.target.value })} optional />
                      </Column>
                      <Column>
                        <Input name="billing_city" label="Town or City" labelStyle="alternative" onBlur={() => handleOnBlur("billing-city")} value={billingAddress?.city} onChange={(event) => { setBillingAddress({ ...billingAddress, city: event.target.value }); removeSubFieldError("billing-city"); }} optional={optional} isValid={!!billingAddress?.city.length} inputClassName={`${subFieldErrorsToBeShown.includes("billing-city") ? "ring-offset-2 ring-2 ring-orange rounded outline-orange" : ""}`} labelLayout="secondary"/>
                        { subFieldErrorsToBeShown.includes("billing-city") && addressFieldsErrors("billing-city") }
                      </Column>
                      <Column>
                        <Select
                          name="billing_country"
                          label="Country"
                          hideLabel={false}
                          labelStyle="alternative"
                          onBlur={() => handleOnBlur("billing-country")}
                          selected={billingAddress?.country}
                          onChange={(event) => {
                            setBillingAddress({ ...billingAddress, country: event.target.value });
                            removeSubFieldError("billing-country");
                          }}
                          optional={optional}
                          isValid={!!billingAddress?.country.length}
                          className={
                            `${subFieldErrorsToBeShown.includes("billing-country")
                              ? "ring-offset-2 ring-2 ring-orange rounded outline-orange"
                              : ""}`
                          }
                          labelLayout="secondary"
                          options={ISO_3166_COUNTRY_OPTIONS}
                        />
                        { subFieldErrorsToBeShown.includes("billing-country") && addressFieldsErrors("billing-country") }
                      </Column>
                      <Column>
                        <Input name="billing_postcode" label="Postcode" labelStyle="alternative" onBlur={() => handleOnBlur("billing-postcode")} value={billingAddress?.postcode} onChange={(event) => { setBillingAddress({ ...billingAddress, postcode: event.target.value }); removeSubFieldError("billing-postcode"); }} optional={optional} isValid={!!billingAddress?.postcode.length} inputClassName={`${subFieldErrorsToBeShown.includes("billing-postcode") ? "ring-offset-2 ring-2 ring-orange rounded outline-orange" : ""}`} labelLayout="secondary"/>
                        { subFieldErrorsToBeShown.includes("billing-postcode") && addressFieldsErrors("billing-postcode") }
                      </Column>
                      <Column>
                        <Input name="billing_phone" type="tel" label="Phone" labelStyle="alternative" value={billingAddress?.phone} onChange={(event) => setBillingAddress({ ...billingAddress, phone: event.target.value })} optional={optional} labelLayout="secondary"/>
                      </Column>
                    </Grid>
                  </Column>
                </>
              ) }
            </>
          ) : <></> }
          <>
            { (!hasPhoneErrors && !hasAddressErrors && !hideContinueToPayment) && (
              <Column justify="center" align="center">
                <FieldsetStateButton
                  to={"summary-delivery-details"}
                  flowId={flow.flow.slug}
                  newStates={[
                    { key: "summary-delivery-details", newState: "closed" },
                    { key: "summary-payment-details", newState: "open" },
                  ]}
                >
                    Continue to payment
                </FieldsetStateButton>
              </Column>
            ) }
          </>
        </Grid>
      </Suspense>
    </>
  );
};

export default AddressLookup;
