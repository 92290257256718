import { FC } from "react";

import Column from "apps/website/components/layout/Column/Column";
import { PaymentDetailsField } from "@forms/schema";
import {
  PaymentDetailsType,
} from "apps/website/components/form/PaymentDetails/PaymentDetailsForm/PaymentDetailsForm";
import CardDetailsCheckoutV2 from "apps/website/components/form/CardDetails/CardDetailsCheckoutV2";
import { getFieldValue } from "@./state";

type IFormStructurePaymentDetails = {
  flowId: string;
  field: PaymentDetailsField;
  isSectionValid: boolean;
  paymentType?: PaymentDetailsType;
  onError(): void;
  onSuccess(): void;
  isDisabled?: boolean;
};

export const FormStructurePaymentDetails: FC<IFormStructurePaymentDetails> = ({
  flowId,
  isSectionValid,
  onSuccess,
  paymentType = "default",
  isDisabled,
}) => (
  <>
    <Column>
      <CardDetailsCheckoutV2
        isDisabled={!isSectionValid || !!isDisabled}
        flowSlug={flowId}
        formUsed={flowId}
        onSuccess={onSuccess}
        paymentType={paymentType}
        price={Number(
          getFieldValue(flowId, "costTodayRaw")?.data.submitValue,
        )}
      />
    </Column>
  </>
);
