import { Offset } from "../../layout/Column/Column.map";

type OffsetMap = {
  [key: number]: Offset;
};

export const offsetMap: OffsetMap = {
  1: 5,
  2: 3,
};
