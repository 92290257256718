import { FC } from "react";

interface Props {
  title: string;
  subtitle: string;
}

export const SearchFeedback: FC<Props> = ({
  title,
  subtitle,
}) => (
  <div className="flex flex-1 items-center flex-col justify-center">
    <h3 className="text-2xl font-bold">
      { title }
    </h3>
    { subtitle }
  </div>
);

export const NoResultsFound =
  () => <SearchFeedback
    title="Sorry, we couldn't find any results"
    subtitle="Please try again with a different search query"
  />;
