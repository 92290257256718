import { XCircleIcon } from "@heroicons/react/24/outline";
import { FC, useCallback } from "react";

import { IconButton } from "../IconButton/IconButton";

interface Props {
  onRemove(x: undefined): void;
  label: string;
  size?: number;
}

export const RemovalButton: FC<Props> = ({
  onRemove,
  label,
  size = 28,
}) => {

  const onClick = useCallback(() => onRemove(undefined), [ onRemove ]);
  return (
    <IconButton
      Icon={XCircleIcon}
      className="text-gray-400 hover:text-black mr-1"
      onClick={onClick}
      label={label}
      size={size}
    />
  );
};
