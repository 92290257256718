"use client";

import { usePostHog } from "posthog-js/react";
import { FC, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import {
  ActionCTAField,
  BaseFieldComponentError,
  ICreateAccountActionResponseSuccess,
  SectionContinueAction,
} from "@forms/schema";
import Button from "apps/website/components/base/Button/Button";
import {
  useNewFormsServiceStore,
  getFlowFieldValuesForAction,
  getFlowProduct,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { useCatsStore, useCustomerStore, useDiscountStore } from "@./state";
import { useAPI } from "apps/website/hooks/useAPI";
import { getStoryBlokLink } from "apps/website/utils/storyblok/links";
import { useQueryParams } from "apps/website/hooks/useQueryParams";
import { AuthSDK } from "@auth/client-sdk";
import { useAnalytics } from "apps/website/hooks/useAnalytics";
import { DisplayState } from "@/constants/state";
import {
  FORM_LAST_EMAIL,
  FORM_LAST_OLD_USER_ID,
  FORM_LAST_USER_ID,
  useDatadog,
} from "@auth/client-sdk-react";

import { FormServiceCTA } from "../../fields/CTA";
import {
  getActionTrackingInfo,
} from "../../../../utils/tracking/getActionTrackingInfo";

export interface IFlowContinue {
  onClick(): void;
  onError(error?: BaseFieldComponentError): void;
  isLastSectionInFlow: boolean;
  formContinueWithAction?: SectionContinueAction;
  field: ActionCTAField;
  flowId: string;
  isDisabled: boolean;
  continueText: string;
  alternative: boolean;
}

const continueButtonTestId = "flowContinueButton";

export const FlowContinue: FC<IFlowContinue> = ({
  onClick,
  onError,
  isLastSectionInFlow,
  formContinueWithAction,
  field,
  flowId,
  isDisabled,
  continueText,
  alternative,
}) => {

  const formsActionsAPI = useAPI().FormActions;

  const { cats, setCats } = useCatsStore();
  const { customerIds, setCustomerId } = useCustomerStore();
  const { discountCodes } = useDiscountStore();

  const [ buttonState, setButtonState ] = useState(DisplayState.READY);
  const [ linkState, setLinkState ] = useState(DisplayState.READY);
  const { chainQueryParamsToUrl } = useQueryParams();

  const { setFlowFieldError, setFlowError, clearFlowErrors } = useNewFormsServiceStore();

  const posthog = usePostHog();
  const datadog = useDatadog();
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);

  const analytics = useAnalytics();

  const handleLinkState = () => {
    clearFlowErrors(flowId);
    setLinkState(DisplayState.PROCESSING);
  };

  useEffect(() => {
    setLinkState(DisplayState.READY);
  }, []);

  const continueAndAction = async () => {
    clearFlowErrors(flowId);
    try {
      setButtonState(DisplayState.PROCESSING);
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowId);
      const catsArr = cats ? cats?.find((dc) => dc.flowId === flowId)?.catIds || [] : [];
      fieldSubmitValuesMap.set("catIds", { value: catsArr });
      fieldSubmitValuesMap.set("customerId", { value: customerIds.find((dc) => dc.flowId === flowId)?.customerId || "" });
      fieldSubmitValuesMap.set("discountCode", { value: discountCodes.find((dc) => dc.flowId === flowId)?.discountCode ?? "" });
      if (formContinueWithAction?.action) {
        const res = await formsActionsAPI
          .performAction<unknown>(
          formContinueWithAction?.action,
          fieldSubmitValuesMap,
          getFlowProduct(flowId),
          flowId,
          undefined,
          featureFlags,
          getActionTrackingInfo(cookies),
          datadog.logger,
        );
        if (!res.success) {
          if (res.message) {
            if (res.failedOn) {
              onError({ message: res.message, name: res?.failedOn?.fieldName as string });
              setFlowFieldError(flowId, res.failedOn.fieldName ?? "", { message: res.message });
            } else {
              setFlowError(flowId, res.message);
              window.scroll({
                top: 0,
                left: 0,
              });
            }
          }
          setButtonState(DisplayState.ERROR);
        } else {
          if (res.responseBody) {

            if ((res.responseBody as { catIds: string[] })?.catIds) {
              if ((res.responseBody as { catIds: string[] })?.catIds.length) {
                setCats(flowId, (res.responseBody as { catIds: string[] }).catIds);
              }
            }
            if ((res.responseBody as { customerId: string })?.customerId) {
              setCustomerId(flowId, (res.responseBody as { customerId: string }).customerId);
            }

            if ((res.responseBody as { gtmData: unknown | undefined })?.gtmData
              && typeof window !== undefined
              && window?.dataLayer) {
              try {
                window.dataLayer.push((res.responseBody as { gtmData: unknown | undefined }).gtmData);
              } catch (e) {
                console.error(`Failed to push gtmData in response for action '${formContinueWithAction?.action}'`);
                console.error(e);
              }
            }

            if (typeof res.responseBody === "object"
              && "authData" in (res.responseBody as ICreateAccountActionResponseSuccess)) {
              const { authData } = res.responseBody as ICreateAccountActionResponseSuccess;
              if (authData) {
                const payload = AuthSDK.decode(authData.access_token);
                const { firstName, lastName, email, old, iold, sub, isub } = payload;

                const oldUserId = iold || old!;
                void analytics.identify(oldUserId, {
                  email,
                  name: `${firstName} ${lastName}`,
                }).catch();

                const newUserId = isub || sub;
                posthog.identify(
                  oldUserId,
                  {
                    email,
                    firstName,
                    lastName,
                  },
                );

                if (newUserId) {
                  datadog.setGlobalContextProperty(FORM_LAST_USER_ID, newUserId);
                  localStorage.setItem(FORM_LAST_USER_ID, newUserId);
                }
                datadog.setGlobalContextProperty(FORM_LAST_EMAIL, email);
                localStorage.setItem(FORM_LAST_EMAIL, email);
                if (oldUserId) {
                  datadog.setGlobalContextProperty(FORM_LAST_OLD_USER_ID, oldUserId);
                  localStorage.setItem(FORM_LAST_OLD_USER_ID, oldUserId);
                }

              }
            }
          }
          if (formContinueWithAction.navigate_to?.url) {
            window.location.href = chainQueryParamsToUrl(getStoryBlokLink(formContinueWithAction.navigate_to));
          } else {
            onClick();
            setButtonState(DisplayState.READY);
          }
        }
      } else {
        datadog.logger.error("Flow continue missing action!", {
          flowId,
          formContinueWithAction,
          featureFlags,
          fieldSubmitValuesMap,
          catsArr,
        });
        setButtonState(DisplayState.ERROR);
      }
    } catch (error) {
      datadog.logger.error("Flow continue error", { flowId, formContinueWithAction }, error as Error);
      setButtonState(DisplayState.ERROR);
    }
  };

  const continueDefault = () => {
    clearFlowErrors(flowId);
    onClick();
  };

  return (
    <>
      { (formContinueWithAction) ? (
        <>
          { formContinueWithAction.action === "navigateTo" ? (
            <>
              <Button
                to={formContinueWithAction?.navigate_to ? chainQueryParamsToUrl(
                  getStoryBlokLink(formContinueWithAction?.navigate_to),
                ) : undefined}
                disabled={isDisabled}
                state={linkState}
                onClick={handleLinkState}
                data-testid={continueButtonTestId}
              >
                { formContinueWithAction.name }
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={continueAndAction}
                disabled={isDisabled}
                state={buttonState}
                data-testid={continueButtonTestId}
              >
                { formContinueWithAction.name }
              </Button>
            </>
          )
          }
        </>
      ) : (
        <>
          { isLastSectionInFlow ?
            <FormServiceCTA
              field={field}
              flowId={flowId}
              disabled={isDisabled}
              className="w-full"
              data-testid={continueButtonTestId}
            />
            :
            (
              <Button
                onClick={continueDefault}
                disabled={isDisabled}
                design={alternative ? "underline" : "default"}
                data-testid={continueButtonTestId}
              >
                { continueText }
              </Button>
            ) }
        </>
      ) }
    </>
  );
};
