import { FC } from "react";

import {
  FieldData,
  FieldsetComponentDisplayOption,
  FillableFieldComponent,
} from "@forms/schema";
import { ColumnSize, fieldSpanObjectMap } from "apps/website/maps/Form.map";

import Column from "../../layout/Column/Column";

import { FormServiceAddress } from "./specialist/Address";

const formComponents = {
  address: FormServiceAddress,
};

export type FormComponentName = keyof typeof formComponents;

interface IReviewField {
  component: FormComponentName;
  field: FillableFieldComponent;
  linkingId: string;
  flowSlug: string;
  fieldsetDisplay?: FieldsetComponentDisplayOption;
  onChange(value: FieldData): void;
}

export const ReviewField: FC<IReviewField> = ({
  component,
  field,
  flowSlug,
  linkingId,
  fieldsetDisplay,
  onChange,
}) => {
  const Component = formComponents[component];
  return (
    <>
      { Component && (
        <Column
          component={ReviewField.name}
          spans={field?.column_size ? fieldSpanObjectMap[field.column_size as ColumnSize] : 12 }
          className={`${
            (
              [ "withImage", "withIcon" ].includes(fieldsetDisplay ?? "") &&
              field?.column_size === "breakout"
            ) && "transform -translate-x-[70px] w-[calc(100%+70px)]"
          }`}
        >
          <>
            <Component
            // @ts-ignore
              field={field}
              optional={field.optional}
              onChange={(changeData) => onChange(changeData)}
              isSectionActive={true}
              flowId={flowSlug}
              linkingId={linkingId}
            />
          </>
        </Column>
      ) }
    </>
  );
};
