"use client";

import { FunnelIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

import { Button } from "../Button/Button";

interface Props {
  onClick(): void;
}

export const FilterColumnsButton: FC<Props> = ({
  onClick,
}) => (
  <Button
    variant="tertiary"
    onClick={onClick}
  >
    <FunnelIcon
      width={20}
      className="mr-2 -my-10 py-0"
    />
    <span>
      Columns
    </span>
  </Button>
);
