"use client";

import { ArrowLongDownIcon, ArrowLongUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { FC } from "react";

type SortStatus = "asc" | "desc" | undefined;

interface Props {
  toggleSort?: (_: unknown) => void;
  status?: SortStatus;
}

const baseIconStyle = "w-4 h-4 group-hover:text-indigo-600";

export const SortButtonGroup: FC<Props> = ({
  toggleSort,
  status,
}) => {

  const iconColor = status ? "text-black" : "text-gray-300";
  return (
    <div className="flex w-5 justify-center">
      { status === undefined && (
        <button
          type="button"
          className="flex flex-row group"
          onClick={toggleSort}
        >
          <ArrowLongDownIcon
            className={clsx(
              baseIconStyle,
              "-mr-1",
              iconColor,
            )}
          />
          <ArrowLongUpIcon
            className={clsx(
              baseIconStyle,
              "-ml-1",
              iconColor,
            )}
          />
        </button>
      ) }
      { status === "asc" && (
        <button
          type="button"
          className="flex flex-row group"
          onClick={toggleSort}
        >
          <ArrowLongUpIcon
            className={clsx(
              baseIconStyle,
              iconColor,
            )}
          />
        </button>
      ) }
      { status === "desc" && (
        <button
          type="button"
          className="flex flex-row group"
          onClick={toggleSort}
        >
          <ArrowLongDownIcon
            className={clsx(
              baseIconStyle,
              iconColor,
            )}
          />
        </button>
      ) }
    </div>
  );
};
