import { FC, useMemo, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";

import { ActionCTAField } from "libs/forms-schema/src";
import Button from "apps/website/components/base/Button/Button";
import {
  getFlowFieldValuesForAction,
  getFlowProduct,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { DisplayState } from "@/constants/state";
import { useReturnToPath } from "apps/website/hooks/useReturnToPath";
import { useAPI } from "apps/website/hooks/useAPI";
import { useDiscountStore } from "@./state";
import { useFlow } from "apps/website/contexts/flow";
import { useQueryParams } from "apps/website/hooks/useQueryParams";
import { useDatadog } from "@auth/client-sdk-react";

import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import {
  getActionTrackingInfo,
} from "../../../utils/tracking/getActionTrackingInfo";

interface IFormServiceCTA {
  field: ActionCTAField;
  flowId: string;
  wrapped?: boolean;
  disabled?: boolean;
  className?: string;
  next?: (forceNext: boolean | undefined) => void;
}

interface IFormServiceCTAButton {
  field: ActionCTAField;
  performAction(): Promise<void>;
  state: DisplayState;
  disabled?: boolean;
}

const CtaButton: FC<IFormServiceCTAButton> = ({
  field,
  performAction,
  state,
  disabled,
}) => (
  <Button
    onClick={performAction}
    design={field.design}
    color={field.color}
    state={state}
    disabled={disabled}
  >
    { field.name }
  </Button>
);

export const FormServiceCTA: FC<IFormServiceCTA> = ({
  field,
  flowId,
  wrapped = true,
  disabled = false,
  className,
  next,
}) => {
  const { canContinue, flow, saveSurvey } = useFlow();
  const posthog = usePostHog();
  const datadog = useDatadog();
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);

  const { chainQueryParamsToUrl } = useQueryParams();

  const { discountCodes } = useDiscountStore();
  const [ state, setState ] = useState<DisplayState>(DisplayState.READY);

  const formsActionsAPI = useAPI().FormActions;

  const returnToPath = useReturnToPath();

  const urlWithReturnToPath = useMemo(
    () => returnToPath || field.link?.url,
    [ field.link?.url, returnToPath ],
  );

  const goToSuccessPage = () => {
    window.location.href = chainQueryParamsToUrl(urlWithReturnToPath);
  };

  const performAction = async () => {
    if (!field.action) {
      if (flow.mappedFlow.survey) {
        await saveSurvey(true);
      }
      goToSuccessPage();
      return;
    }
    if (field.action === "navigateToNextSection" && next) {
      next(field.force_action);
      return;
    }
    setState(DisplayState.PROCESSING);
    try {
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowId);
      fieldSubmitValuesMap.set("discountCode", {
        value:
          discountCodes.find((dc) => dc.flowId === flowId)?.discountCode ?? "",
      });
      const resp = await formsActionsAPI.performAction<unknown>(
        field.action,
        fieldSubmitValuesMap,
        getFlowProduct(flowId),
        flowId,
        undefined,
        featureFlags,
        getActionTrackingInfo(cookies),
        datadog.logger,
      );
      if (!resp.success) {
        setState(DisplayState.ERROR);
      } else {
        setState(DisplayState.COMPLETE);
        void goToSuccessPage();
      }
    } catch (error) {
      datadog.logger.error(`CTA Error for action '${field?.action}'`, {
        action: field?.action,
        flowId,
        field,
      }, error as Error);
      setState(DisplayState.ERROR);
    }
  };

  return (
    <Column component={FormServiceCTA.name} className={className}>
      { wrapped ? (
        <Grid gap="none">
          <Column align="center" justify="center">
            <CtaButton
              field={field}
              state={state}
              performAction={performAction}
              disabled={
                disabled || (field.respect_section_validation && !canContinue)
              }
            />
          </Column>
        </Grid>
      ) : (
        <CtaButton
          field={field}
          state={state}
          performAction={performAction}
          disabled={
            disabled || (field.respect_section_validation && !canContinue)
          }
        />
      ) }
    </Column>
  );
};
