import { FC } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import { getFlowFieldValuesForInterpolation } from "@./state";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import TaggedSummary from "apps/website/components/feature/TaggedSummary/TaggedSummary";
import {
  ITaggedSummaryGroupProps,
} from "apps/website/components/feature/TaggedSummary/TaggedSummaryGroup";
import { Recipe } from "@forms/schema";
import { IconSvg } from "apps/website/components/base/Icon/Icon.map";
import Icon from "apps/website/components/base/Icon/Icon";

interface IFormServiceCatSummaryProps {
  flowSlug: string;
  linkingId: string;
  values: Record<string, string>;
}

interface IRecipeImagery {
  image: IImageProps;
  icon: IconSvg;
}

const RECOMMENDED_RECIPE_IMAGE_MAP: Record<Recipe, IRecipeImagery> = {
  BAAA: {
    image: {
      src: "/images/products/baaa.webp",
      width: 1000,
      height: 1000,
    },
    icon: "sheep",
  },
  CLUCK: {
    image: {
      src: "/images/products/cluck.webp",
      width: 1000,
      height: 1000,
    },
    icon: "chicken",
  },
  GOBBLE: {
    image: {
      src: "/images/products/gobble.webp",
      width: 1000,
      height: 1000,
    },
    icon: "turkey",
  },
  MOOO: {
    image: {
      src: "/images/products/mooo.webp",
      width: 1000,
      height: 1000,
    },
    icon: "cow",
  },
  OINK: {
    image: {
      src: "/images/products/oink.webp",
      width: 1000,
      height: 1000,
    },
    icon: "pig",
  },
  QUACK: {
    image: {
      src: "/images/products/quack.webp",
      width: 1000,
      height: 1000,
    },
    icon: "duck",
  },
  SPLASH: {
    image: {
      src: "/images/products/splash.webp",
      width: 1000,
      height: 1000,
    },
    icon: "fish",
  },
};

export const FormServiceCatSummaryHighlightingRecipesTest: FC<IFormServiceCatSummaryProps> = (
  { values, flowSlug, linkingId },
) => {

  const groups: ITaggedSummaryGroupProps[] = [];
  const allergies = values.$catAllergies?.split(",") || [];
  const defaultRecipes: Recipe[] = values.$defaultRecipes?.split(",") as Recipe[] || [];
  const defaultRecipesReversed: Recipe[] = values.$defaultRecipes?.split(",").reverse() as Recipe[] || [];
  const calorieTags = values.$catKcalPerDay ? [ values.$catKcalPerDay ] : [];

  const aboutCatKeys = [ "$catAge", "$catSex", "$catPedigreeOrMoggie", "$catBreed", "$catActivity", "$catBodyCondition", "$catWeight" ];
  const aboutCatTags: string[] = [];
  aboutCatKeys.forEach((key) => {
    if (values[key] && values[key].trim() !== "") {
      const value = key === "$catWeight" ? `${values[key]}kg` : values[key];
      aboutCatTags.push(value);
    }
  });

  if (aboutCatTags.length) {
    groups.push({
      title: "Based on:",
      tags: aboutCatTags,
    });
  }

  if (calorieTags.length) {
    groups.push({
      title: `${values.$catPronounHeSheThey} needs to eat:`,
      tags: calorieTags,
    });
  }

  if (allergies.length) {
    groups.push({
      title: `We made sure ${values.$catPronounHisHerTheir} plan doesn't contain any:`,
      tags: allergies,
    });
  }

  groups.push({
    title: `Start with these ${defaultRecipes.length} recipes for ${values.$catName}'s smoothest switch to fresh:`,
    tags: defaultRecipes,
  });

  const getPositionClass = (numberOfRecipes: number, index: number): string => {
    if (numberOfRecipes === 4) {
      if (index === 0) return "translate-x-[52.5%]";
      if (index === 1) return "translate-x-[17.5%]";
      if (index === 2) return "-translate-x-[17.5%]";
      if (index === 3) return "-translate-x-[52.5%]";
    }
    if (numberOfRecipes === 3) {
      if (index === 0) return "translate-x-[35%]";
      if (index === 1) return "";
      if (index === 2) return "-translate-x-[35%]";
    }
    if (numberOfRecipes === 2) {
      if (index === 0) return "translate-x-[17.5%]";
      if (index === 1) return "-translate-x-[17.5%]";
    }
    return "";
  };

  return (
    <>
      { !!groups.length && (
        <>
          <Column className="bg-brand" component="CatSummaryB" data-linkingid={linkingId}>
            <>
              <div className="flex items-center px-4 pt-4 space-x-4">
                <div className={"items-center justify-center relative aspect-[3/4] w-16 flex-none"}>
                  <Image image={{
                    src: "https://a.storyblok.com/f/176726/290x381/f3b5cf231b/dom.png/m/320x0",
                    width: 290,
                    height: 381,
                  }} alt="In house vet Dom" className={themeRootClassMap.green}/>
                  <Image image={{
                    src: "/images/features/team/frame.png",
                    height: 232,
                    width: 183,
                  }} alt=""
                  className="absolute top-0 left-0"
                  />
                </div>
                <div className="flex items-center justify-center mr-4">
                  <Text tag="h2" display="subtitle" size={legacySizeCollectionMap.titleSm} className="pb-0">Dom, our in-house cat nutrition expert, recommends the following for { interpolateString("[[$catName]]", getFlowFieldValuesForInterpolation(flowSlug, linkingId, "GET_RELATED"), Interpolator.FRONTEND) }:</Text>
                </div>
              </div>
              <TaggedSummary
                groups={groups}
              />
            </>
            <div className="p-4">
              <div className="flex justify-center transform pt-8 bg-white">
                <div className="relative aspect-square w-[50%]">
                  { defaultRecipesReversed.map((recipe, index) => (
                    <div key={recipe} className={`absolute h-full w-full transform ${getPositionClass(defaultRecipes.length, index)}`}>
                      <Icon icon={RECOMMENDED_RECIPE_IMAGE_MAP[recipe].icon} className="absolute -top-6 right-4 md:-top-4 md:right-8 lg:-top-6 lg:right-4 xl:-top-4 xl:right-8" />
                      <Image image={RECOMMENDED_RECIPE_IMAGE_MAP[recipe].image} alt={recipe} />
                    </div>
                  )) }
                </div>
              </div>
            </div>
            <div className="px-4">
              <Text display="subtitle" align="center" size={legacySizeCollectionMap.titleSm}>Every recipe is complete + balanced. And perfectly portioned to meet { interpolateString("[[$catName]]", getFlowFieldValuesForInterpolation(flowSlug, linkingId, "GET_RELATED"), Interpolator.FRONTEND) }&apos;s daily calorie requirements.</Text>
            </div>
          </Column>
        </>
      ) }
    </>
  );
};
