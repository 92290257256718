"use client";

import { ChangeEvent, FC, ReactNode } from "react";

import useA11y from "apps/website/hooks/useA11y";
import * as TextMap from "apps/website/components/base/Text/Text.map";
import { WithTestID } from "apps/website/types";

import Label, { TLabelLayout } from "../Label/Label";
import { FontStyle } from "../Label/Label.map";
import Tooltip, { ITooltip, getToolTipId } from "../../feature/Tooltip/Tooltip";
import { ValidInput } from "../ValidInput/ValidInput";
import { AllowedTypes } from "../Input/Input.map";

export interface ITextAreaProps extends WithTestID {
  type?: AllowedTypes;
  disabled?: boolean;
  name: string;
  value?: string;
  placeholder?: string;
  label: string;
  labelPosition?: TextMap.Align;
  labelStyle?: FontStyle;
  hideLabel?: boolean;
  optional?: boolean;
  onChange(event: ChangeEvent<HTMLTextAreaElement>): void;
  onEnter?: () => void;
  onKeyDown?:(event: KeyboardEvent) => void
  onFocus?:() => void;
  onBlur?:() => void;
  component?: string;
  className?: string;
  fontSize?: TextMap.Size;
  tabbable?: boolean;
  children?: ReactNode;
  inputClassName?: string;
  tooltip?: ITooltip;
  autocomplete?: string;
  min?: number;
  max?: number;
  isValid?: boolean;
  labelLayout?: TLabelLayout;
}

const TextArea: FC<ITextAreaProps> = ({
  "data-testid": testId,
  labelPosition = "default",
  value,
  name,
  onFocus,
  onBlur,
  label,
  labelStyle,
  optional,
  placeholder,
  onChange,
  hideLabel = false,
  fontSize = "xs",
  disabled = false,
  className,
  component = TextArea.displayName,
  tabbable = true,
  children,
  inputClassName = "",
  tooltip,
  autocomplete,
  isValid,
  labelLayout = "default",
}) => {

  const { UUID } = useA11y();

  return (
    <div
      data-component={component}
      className={className}
      data-theme="form-input"
    >
      { (tooltip && tooltip.placement === "above") && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
      <Label
        align={labelPosition}
        label={label}
        layout={labelLayout}
        fontStyle={labelStyle}
        hideLabel={hideLabel}
        UUID={UUID}
        optional={optional}
      >
        <div className="relative">
          <textarea
            data-testid={testId}
            id={UUID}
            placeholder={placeholder}
            className={`${TextMap.defaultSizeClassMap[fontSize]} min-h-24 lg:min-h-36 box-border border-2 font-sentence w-full p-4 bg-white appearance-none ${inputClassName}`}
            name={name}
            tabIndex={tabbable ? 0 : -1}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            aria-describedby={tooltip ? getToolTipId(UUID) : undefined}
            autoComplete={autocomplete}
          >{ value }</textarea>
          <ValidInput isValid={isValid} input="input" />
        </div>
      </Label>
      { children }
      { (tooltip && (!tooltip.placement || tooltip.placement === "default")) && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
    </div>
  );
};

TextArea.displayName = "TextArea";

export default TextArea;
