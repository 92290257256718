import { FC, memo } from "react";

import Text from "apps/website/components/base/Text/Text";
import Icon from "apps/website/components/base/Icon/Icon";

export interface ITypeToSelectOptionMessage {
  icon: "success" | "error",
  body: string;
}

export const TypeToSelectOptionMessage: FC<ITypeToSelectOptionMessage> = ({ icon, body }) => (
  <div className="flex bg-white space-x-4 p-2 items-center" data-theme="light">
    <div className="w-6 h-6 flex-none flex justify-center items-center">
      <Icon icon={icon} size="fit" />
    </div>
    <div>
      <Text size={{ default: "3xs", md: "2xs" }}>{ body }</Text>
    </div>
  </div>
);

export default memo(TypeToSelectOptionMessage);
