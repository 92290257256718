import { KatKinProductCategory } from "@forms/schema";

export const processingModalPhraseMap: Partial<Record<KatKinProductCategory, string[]>> = {
  FRESH: [
    "Getting our claws into your details...",
    "Tracking down your turf...",
    "Snagging your cat's selected recipes...",
    "Cooking up your fresh, real meat...",
    "Adding a dash of KatKin's nutrient mix...",
    "Perfectly portioning your cat's fresh recipes...",
    "Boxing up 100% human-quality meat cat food...",
    "Packing happiness...",
    "Writing your cat's name on the box...",
    "Bear with us just a bit longer...",
    "(Our fresh, healthy cat food is worth the wait)",
  ],
  SCOOP: [
    "Please bear with us...",
    "We won't be long now...",
    "Just a bit longer...",
    "A few more seconds...",
    "Packing happiness...",
    "We're counting your bags...",
    "Upgrading your litter...",
    "Scoop will be coming your way any minute...",
    "Say hello to a fresher litter box...",
  ],
};
