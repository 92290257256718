import { FC, useEffect } from "react";
import Balancer from "react-wrap-balancer";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Tag, { ITagProps } from "apps/website/components/base/Tag/Tag";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Button from "apps/website/components/base/Button/Button";
import Icon from "apps/website/components/base/Icon/Icon";
import { themeRootClassMap } from "apps/website/maps/Theme.map";

export interface IAddProductCardProps {
  name: string;
  tag: ITagProps;
  image: IImageProps;
  title: string;
  body: string;
  price: string;
  salePrice: string;
  productSize: string;
  billingInformation?: string;
  hasBeenAddedToBasket: boolean;
  onToggleInBasket(inBasket: boolean, fieldName: string, quantity: number): void;
  canToggleInBasket?: boolean;
  quantity: number;
  ctaText?: string;
  hidden?: boolean;
}

const AddProductCard: FC<IAddProductCardProps> = (
  {
    name,
    title,
    body,
    image,
    tag,
    productSize,
    price,
    salePrice,
    hasBeenAddedToBasket,
    onToggleInBasket,
    canToggleInBasket = true,
    billingInformation,
    quantity,
    ctaText,
    hidden,
  },
) => {

  useEffect(() => {
    if (!canToggleInBasket) {
      onToggleInBasket(true, name, quantity);
    }
  }, []);

  return (
    <Grid component={AddProductCard.name} gap="none" className={hidden ? "hidden" : "bg-white h-full"}>
      <Column spans={{ default: 6, lg: 12 }} justify="center" align="center" className="bg-light-grey relative">
        { tag.text && (
          <Tag theme={tag.theme} className="absolute top-4 left-4">{ tag.text }</Tag>
        ) }
        <div>
          <Image image={image} alt={title} />
        </div>
      </Column>
      <Column spans={{ default: 6, lg: 12 }} justify={{ default: "center", lg: "default" }} className="p-2 lg:p-4 h-full">
        <Grid className="h-full" gap="none">
          <Column align="center" justify={{ default: "center", lg: "between" }}>
            <div>
              <Text display="title" size={legacySizeCollectionMap.bodyLg} align="center" data-testid="title">{ title }</Text>
              <Text align="center" size={legacySizeCollectionMap.base} data-testid="body" className="relative after:bg-black after:absolute after:-bottom-2 after:h-[2px] after:w-[30px] after:block after:left-1/2 after:transform after:-translate-x-1/2">
                <Balancer>{ body }</Balancer>
              </Text>
              <Text align="center" size={legacySizeCollectionMap.body2Xs} data-testid="details">
                { productSize } | <Text tag="s" size="inherit" data-testid="price">{ price }</Text> <Text tag="strong" size="inherit" display="title" data-testid="salePrice">{ salePrice }</Text>
              </Text>
              { billingInformation && (
                <Text align="center" size={legacySizeCollectionMap.body2Xs} data-testid="billingInformation">
                  { billingInformation }
                </Text>
              ) }
            </div>
            <div className="flex justify-center items-center pt-4">
              { canToggleInBasket ? (
                <Button onClick={() => onToggleInBasket(!hasBeenAddedToBasket, name, quantity)} design={hasBeenAddedToBasket ? "line" : "default"} size="fit-content" data-testid="addRemoveButton">
                  { hasBeenAddedToBasket ? "Remove" : (ctaText ?? "Add Plan") }
                </Button>
              ) : (
                <div className={`px-2 lg:px-4 h-11 flex justify-center items-center ${themeRootClassMap.green}`} data-theme="green" data-testid="alwaysInBox">
                  <Icon icon="tickHeavy" size="small" />
                  <Text tag="span" display="title">
                  In your box
                  </Text>
                </div>
              ) }
            </div>
          </Column>
        </Grid>
      </Column>
    </Grid>
  );
};

export default AddProductCard;
