import { useMemo } from "react";

import {
  CheckboxField,
  CheckboxGroupDbField,
  CheckboxGroupField,
  InputField,
  RadioSelectField,
  SelectField,
  StepCounterSelectField,
  TextAreaField,
  TypeToSelectField,
} from "@forms/schema";

import { FontStyle } from "../components/form/Label/Label.map";

type TCoreFields = (
  InputField |
  SelectField |
  CheckboxField |
  CheckboxGroupField |
  CheckboxGroupDbField |
  RadioSelectField |
  TypeToSelectField |
  StepCounterSelectField |
  TextAreaField
);

interface IUseField {
  getLabelStyle: FontStyle;
}

export default function useField(field: TCoreFields): IUseField {

  const getLabelStyle = useMemo((): FontStyle => {
    if (field.label_style) return field.label_style;
    return field.label_position === "center" ? "title" : "alternative";
  }, [ field.label_style, field.label_position ]);

  return {
    getLabelStyle,
  };
}
