"use client";

import { VisibilityState } from "@tanstack/react-table";
import { useState } from "react";

import { useUpdateEffect } from "@/hooks";

const DEFAULT_DEFAULT = {};

export function useTableColumnVisibility(
  key: string,
  defaults: Record<string, boolean> = DEFAULT_DEFAULT,
) {
  const [ columnVisibility, setColumnVisibility ] = useState<VisibilityState>(() => {
    if (typeof window !== "undefined") {
      const localStorageResult = localStorage[key];
      if (localStorageResult) {
        try {
          const result = JSON.parse(localStorageResult);
          // We use the spread operator to ensure that if there were no defaults in the past but now there are,
          // we'll use the default value instead of always displaying the newly added column
          return {
            ...defaults,
            ...result,
          };
        } catch (e) {
          delete localStorage[key];
          return defaults;
        }
      } else {
        return defaults;
      }
    }
  });

  useUpdateEffect(() => {
    if (columnVisibility !== undefined) {
      localStorage[key] = JSON.stringify(columnVisibility);
    }
  }, [ columnVisibility ]);

  return [ columnVisibility, setColumnVisibility ] as const;
}
