import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { isNumber } from "class-validator";

import useA11y from "apps/website/hooks/useA11y";
import { IFormFieldSelectOptionAdvancedOptions } from "apps/website/types/form";

import Label from "../Label/Label";
import Icon from "../../base/Icon/Icon";
import { SelectOptionTag } from "../Select/SelectOptionTag/SelectOptionTag";
import { FontStyle } from "../Label/Label.map";
import Tooltip, { ITooltip } from "../../feature/Tooltip/Tooltip";
import { Align } from "../../base/Text/Text.map";

export interface IStepCounterSelectOption {
  name: string;
  value: string;
  advancedOptions?: IFormFieldSelectOptionAdvancedOptions;
}
export interface IStepCounterSelect {
  name: string;
  selected?: string;
  label: string;
  labelStyle?: FontStyle;
  hideLabel?: boolean;
  labelPosition?: Align;
  options: IStepCounterSelectOption[];
  optional?: boolean;
  allowMultipleSelect?: boolean;
  onChange(value: string): void;
  component?: string;
  className?: string;
  tabbable?: boolean;
  children?: ReactNode;
  tooltip?: ITooltip;
}

const Select: FC<IStepCounterSelect> = ({ name, labelStyle, label, hideLabel = true, selected, onChange, options, optional, className, component = "StepCounterSelect", tabbable = true, children, tooltip, labelPosition = "default" }) => {
  const { UUID } = useA11y();
  const [ orderedOptions, setOrderedOptions ] = useState<IStepCounterSelectOption[]>([]);
  const [ currentOptionIndex, setCurrentOptionIndex ] = useState<number>();
  const [ isFirstOption, setIsFirstOption ] = useState<boolean>();
  const [ isLastOption, setIsLastOption ] = useState<boolean>();

  useEffect(() => {
    setOrderedOptions(options.sort((a, b) => Number(a.value) - Number(b.value)));
  }, []);

  useEffect(() => {
    const optionIndex = orderedOptions?.findIndex((option) => option.value === selected);
    setCurrentOptionIndex((optionIndex ?? 0) >= 0 ? optionIndex : 0);
  }, [ orderedOptions, selected ]);

  useEffect(() => {
    setIsFirstOption(currentOptionIndex === 0);
    setIsLastOption((currentOptionIndex ?? 0) >= (orderedOptions?.length || 0) - 1);
  }, [ currentOptionIndex, orderedOptions?.length ]);

  const increment = useCallback(() => {
    onChange(orderedOptions[(currentOptionIndex ?? 0) + 1].value);
  }, [ orderedOptions, currentOptionIndex, onChange ]);

  const decrement = useCallback(() => {
    onChange(orderedOptions[(currentOptionIndex ?? 1) - 1].value);
  }, [ orderedOptions, currentOptionIndex, onChange ]);

  const buttonStyles = "disabled:opacity-30 lg:hover:opacity-60 transition-opacity duration-300 ease-in-out disabled:cursor-not-allowed bg-black text-white h-12 w-12 rounded-full flex flex-none items-center justify-center";

  return (
    <div data-component={component} className={className} data-theme="form-input">
      { (tooltip && tooltip.placement === "above") && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
      <Label
        label={label}
        align={labelPosition}
        fontStyle={labelStyle}
        hideLabel={hideLabel}
        UUID={UUID}
        optional={optional}
      >
        <div className="flex">
          <button disabled={isFirstOption} onClick={decrement} className={`mr-2 ${buttonStyles}`} aria-label={`${label} previous value`} tabIndex={tabbable ? 0 : -1}>
            <Icon icon="minusAlt" color="inherit" size="small" />
          </button>
          <div className="relative w-full">
            <input
              id={UUID}
              className={"hidden"}
              value={currentOptionIndex ? orderedOptions?.[currentOptionIndex]?.name : ""}
              name={name}
              onChange={() => onChange}
              readOnly
            />
            <div className="box-border flex justify-center items-center border-black border-2 font-sentence w-full h-10 px-4 bg-white rounded-full appearance-none lg:min-h-[50px] text-center">
              <span className="font-display uppercase text-md mx-2">{ isNumber(currentOptionIndex) ? orderedOptions?.[currentOptionIndex]?.name : "" }</span>
              { (
                isNumber(currentOptionIndex) &&
                orderedOptions?.[currentOptionIndex]?.advancedOptions?.secondaryText
              ) ? (
                  <span>{ orderedOptions?.[currentOptionIndex]?.advancedOptions?.secondaryText }</span>
                ) : (
                  <></>
                ) }
            </div>
            { isNumber(currentOptionIndex) && orderedOptions?.[currentOptionIndex]?.advancedOptions?.tag ? (
              <SelectOptionTag>
                { orderedOptions[currentOptionIndex].advancedOptions?.tag }
              </SelectOptionTag>
            ) : (
              <></>
            ) }
          </div>
          <button
            disabled={isLastOption}
            onClick={increment}
            className={`ml-2 ${buttonStyles}`}
            aria-label={`${label} next value`}
            tabIndex={tabbable ? 0 : -1}
          >
            <Icon icon="plusAlt" color="inherit" size="small" />
          </button>
        </div>
      </Label>
      { children }
      { (tooltip && (!tooltip.placement || tooltip.placement === "default")) && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
    </div>
  );
};

export default Select;
