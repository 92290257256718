import { FC } from "react";

import { ISelectItem } from "../SelectableButtonGroup/SelectableButtonGroup";

interface Props {
  items: ISelectItem[]
  // disabled?: boolean;
}

export const MultiSelectCheckbox: FC<Props> = ({
  items,
}) => (
  <div className="grid grid-cols-6 gap-2">
    { items.map((item) => (
      <div
        className="col-span-2"
        key={item.name}
      >
        <label
          htmlFor={`${item.name}-checkbox`}
          className="flex items-center hover:cursor-pointer"
        >
          <input
            className="rounded-sm accent-indigo-600 mr-1"
            type="checkbox"
            checked={item.selected}
            onChange={item.onClick}
            id={`${item.name}-checkbox`}
            name={`${item.name}-checkbox`}
          />
          { item.name }
        </label>
      </div>
    )) }
  </div>
);
