import { FC, ReactNode } from "react";
import clsx from "clsx";

import { Theme } from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import { WithTestID } from "apps/website/types";

import Image, { IImageProps } from "../../base/Image/Image";
import Column from "../../layout/Column/Column";
import Grid from "../../layout/Grid/Grid";
import Tag from "../../base/Tag/Tag";
import Spacer from "../../layout/Spacer/Spacer";
import Icon from "../../base/Icon/Icon";

/*
 * This is the base props for every quantity selection card.
 * i.e. available and out of stock and allergic.
 */
export interface IBaseQuantitySelectionCardProps extends WithTestID {
  tagText?: string;
  tagStyle?: Theme;
  image?: IImageProps;
  name: string;
  description: string | ReactNode;
  recommended?: boolean;
}

export interface IAvailableQuantitySelection {
  value: number;
  onClickPlus(): void;
  plusEnabled: boolean;
  onClickMinus(): void;
  minusEnabled: boolean;
}

export interface IOutOfStockQuantitySelection {
  outOfStock: true;
  outOfStockText?: string;
}

export interface IAllergicQuantitySelection {
  allergic: true;
  allergicText?: string;
}

export type IQuantitySelectionCardProps = IBaseQuantitySelectionCardProps
& (IAvailableQuantitySelection | IOutOfStockQuantitySelection | IAllergicQuantitySelection);

const addMinusButtonClassName = "bg-black rounded-full text-white h-[30px] w-[30px] flex items-center justify-center lg:hover:opacity-60 disabled:bg-dark-grey disabled:cursor-not-allowed";

const QuantitySelectionCard: FC<IQuantitySelectionCardProps> = ({
  description,
  image,
  name,
  recommended = true,
  tagStyle = "brand",
  tagText,
  "data-testid": testId,
  ...otherProps
}) => (
  <Column
    tag="li"
    data-testid={testId}
    component={QuantitySelectionCard.name}
    direction="row"
    className={clsx(recommended && "bg-white")}
  >
    <Grid
      gap="none"
      className="w-full"
    >

      <Column
        spans={5}
        className="relative"
      >
        { image?.src && (
          <Image
            image={image}
            alt={name}
            cover
          />
        ) }
        { tagText && <Tag
          theme={tagStyle}
          className="absolute z-10 top-1 left-1 lg:top-4 lg:left-4"
          size="small"
        >
          { tagText }
        </Tag> }
      </Column>

      <Column
        spans={7}
        className="p-2 flex flex-col justify-center items-center"
      >
        <Text
          tag="h3"
          display="title"
          size={{
            default: "default",
            md: "xl",
          }}
          align="center"
        >
          { name }
        </Text>

        <div className="w-[25px] h-0 border my-1" />

        <Text
          tag="div"
          align="center"
          className="px-4"
          size={{
            default: "2xs",
            md: "md",
          }}
        >
          { description }
        </Text>

        <Spacer size="md" />

        { "value" in otherProps && (

          <div
            className="flex flex-row justify-center items-center"
          >
            <button
              disabled={!otherProps.minusEnabled}
              onClick={otherProps.onClickMinus}
              className={addMinusButtonClassName}
              aria-label={`Remove ${name}`}
            >
              <Icon
                icon="minusAlt"
                color="inherit"
                size="small"
              />
            </button>
            <Text
              tag="h3"
              display="title"
              size={{
                default: "md",
                md: "xl",
              }}
              align="center"
              className="mx-3"
            >
              { otherProps.value }
            </Text>
            <button
              disabled={!otherProps.plusEnabled}
              onClick={otherProps.onClickPlus}
              className={addMinusButtonClassName}
              aria-label={`Add ${name}`}
            >
              <Icon
                icon="plusAlt"
                color="inherit"
                size="small"
              />
            </button>
          </div>
        ) }

        { "outOfStock" in otherProps && (
          <div
            className="bg-black text-white mx-2 px-4 py-1"
          >
            <Text
              tag="h3"
              display="title"
              size={{
                default: "default",
                md: "xl",
              }}
              align="center"
              color="inherit"
            >
              { otherProps.outOfStockText }
            </Text>
          </div>
        ) }

        { "allergic" in otherProps && (
          <div
            className="bg-orange px-2 mx-2 py-1"
          >
            <Text
              tag="h3"
              display="title"
              size={{
                default: "default",
                md: "xl",
              }}
              align="center"
            >
              { otherProps.allergicText }
            </Text>
          </div>
        ) }

      </Column>
    </Grid>

  </Column>
);

export default QuantitySelectionCard;
