"use client";

import { FC, useTransition } from "react";

/**
 * NOTE: This does not seem to work yet, see https://github.com/vercel/next.js/discussions/42016
 * NOTE: This component requires custom CSS for the progress and left-right classes
 * at the global level
 */
export const NavigationProgress: FC = () => {

  const [ navigating ] = useTransition();

  return (
    <div className='w-full h-1.5'>
      { navigating &&
      <div className='h-1.5 w-full bg-indigo-100 overflow-hidden'>
        <div className='animate-progress w-full h-full bg-indigo-600 origin-left-right'></div>
      </div>
      }
    </div>
  );
};
