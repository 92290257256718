import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IPurchaseStore {
  canUseQuickCheckout: boolean;
  purchaseInProgress: boolean;
  setCanUseQuickCheckout(canUse: boolean): void;
  setPurchaseInProgress(inProgress: boolean): void;
}

export const usePurchaseStore = create<IPurchaseStore>()(persist((set) => ({
  canUseQuickCheckout: false,
  purchaseInProgress: false,
  setCanUseQuickCheckout: (canUse: boolean) => set((state) => ({ ...state, canUseQuickCheckout: canUse })),
  setPurchaseInProgress: (inProgress: boolean) => set((state) => ({ ...state, purchaseInProgress: inProgress })),
}), { name: "purchase-store" }));

export default usePurchaseStore;
