import { FC, ChangeEvent } from "react";

import useA11y from "apps/website/hooks/useA11y";
import { IFormFieldSelectOptionAdvancedOptions } from "apps/website/types/form";

import {
  CheckableSelectOption,
} from "../Select/CheckableSelectOption/CheckableSelectOption";
import { ILabelLink } from "../../page/api.types";
import Tooltip, { ITooltip } from "../../feature/Tooltip/Tooltip";
import { IconSvg } from "../../base/Icon/Icon.map";
import { Display } from "../../base/Text/Text.map";

export interface ICheckboxOption {
  value?: string;
  name: string;
  selected?: boolean;
  advancedOptions?: IFormFieldSelectOptionAdvancedOptions;
  icon?: IconSvg;
}

export interface ICheckbox extends ICheckboxOption {
  label: string;
  labelLink?: ILabelLink;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  optional?: boolean;
  checked?: boolean;
  component?: string;
  className?: string;
  tabbable?: boolean;
  tooltip?: ITooltip;
  display?: Display;
  disabled?: boolean;
}

const Checkbox: FC<ICheckbox> = ({ onChange, value, checked = false, label, advancedOptions, icon, className, component = "Checkbox", tabbable = true, tooltip, display, disabled = false }) => {
  const { UUID } = useA11y();
  return (
    <div data-component={component} className={className} data-theme="form-input">
      { (tooltip && tooltip.placement === "above") && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
      <label className="relative flex flex-1 flex-wrap justify-between items-center group pr-2 w-full rounded-full outline-none">
        <input
          type="checkbox"
          id={UUID}
          name={value}
          value={value}
          onChange={onChange}
          defaultChecked={checked}
          className="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-full cursor-pointer outline-none box-border"
          tabIndex={tabbable ? 0 : -1}
          disabled={!checked && disabled}
        />
        <CheckableSelectOption
          display={display}
          name={label}
          secondaryText={advancedOptions?.secondaryText}
          tag={advancedOptions?.tag}
          icon={icon}
        />
      </label>
      { (tooltip && (!tooltip.placement || tooltip.placement === "default")) && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
    </div>
  );
};

export default Checkbox;
