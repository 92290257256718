import clsx from "clsx";
import { FC } from "react";

import { LoadingSpinner } from "../../form/LoadingSpinner/LoadingSpinner";
import Spacer from "../../layout/Spacer/Spacer";
import Text from "../../base/Text/Text";

export interface ILoadingPopover {
  show: boolean;
  message: string;
}

const LoadingPopover: FC<ILoadingPopover> = ({ show, message }) => (
  <div
    data-component={LoadingPopover.name}
    data-theme="dark"
    className={clsx(
      "z-50 fixed w-screen h-screen bg-black bg-opacity-95 top-0 left-0 flex flex-col justify-center items-center transition-all",
      show ? "opacity-100 visible" : "opacity-0 invisible",
    )}>
    <LoadingSpinner color="white" />
    <Spacer size="md" />
    <Text>{ message }</Text>
  </div>
);

export default LoadingPopover;
