"use client";

import { useCallback, useState } from "react";
import debounce from "lodash.debounce";

import { useTextInput, useUpdateEffect } from "@/hooks";

import { ComboBox, ComboBoxProps } from "./ComboBox";

interface Props<T> extends Omit<ComboBoxProps<T>, "options" | "setSearchText" > {
  searchFn(s?: string): Promise<T[]>;
}

export function LiveSearchComboBox<T>({
  extractName,
  selected: currentlySelected,
  setSelected,
  searchFn,
}: Props<T>) {

  const [ searchText, setSearchText ] = useTextInput();

  const [ options, setOptions ] = useState<T[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchAndSetOptions = useCallback(debounce(async (
    query?: string,
  ) => {
    const results = await searchFn(query);
    setOptions(results);
  }, 500), [ searchFn, setOptions ]);

  useUpdateEffect(() => {
    void searchAndSetOptions(searchText);
  }, [ searchText, searchAndSetOptions ]);

  return (
    <ComboBox
      options={options}
      selected={currentlySelected}
      setSelected={setSelected}
      extractName={extractName}
      setSearchText={setSearchText}
    />
  );
}
