"use client";

import { FC, useEffect, useState } from "react";
import { notFound, useRouter } from "next/navigation";

import { ITrustpilotInfo } from "apps/website/api/Trustpilot";
import { FlowProvider } from "apps/website/contexts/flow";
import FlowContent from "apps/website/components/form-service/sections/FlowContent";
import { useAuth } from "@auth/client-sdk-react";
import {
  FlowDataStructureResponse,
} from "apps/forms-structure/src/app/dto/FormDataStructureResponse.dto";
import { FormsStructureAPI } from "apps/website/api/FormsStructure";
import { PublicConfig } from "apps/website/config/public";
import { SBHelper } from "apps/website/storyblok/client";

import {
  IStoryBlokCookieBar,
  IStoryBlokFooter,
  IStoryBlokHeader,
  IStoryBlokSeo,
} from "../../page/api.types";

export interface IFlowParams {
  slug: string[];
}

export interface IFlowSearchParams extends Record<string, unknown> {
  preview?: string;
  isFlow?: string;
}

type Props = {
  flow: FlowDataStructureResponse;
  form: string;
  section: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story?: any | boolean;
  trustpilot: ITrustpilotInfo;
  preview: boolean;
  redirect?: string;
  key: string | boolean;
};

interface IFlowWrapper {
  trustpilot: ITrustpilotInfo;
  params: IFlowParams;
  searchParams: IFlowSearchParams;
}

interface IFlowPageStory {
  header: IStoryBlokHeader;
  footer: IStoryBlokFooter;
  cookieBar: IStoryBlokCookieBar;
  fallbackTags: IStoryBlokSeo;
}

const getFormsPageProps = async (
  params: IFlowParams,
  searchParams: IFlowSearchParams,
  accessToken: string | undefined,
  trustpilot: ITrustpilotInfo,
): Promise<Props | undefined> => {
  if (params) {
    const { slug } = params;
    const preview = !!searchParams.preview;
    const isFlow = !!searchParams.isFlow;
    const [ flow, form, section ] = slug as string[];
    const remainingPartsToRequestForPreview = slug.filter((_, i) => i > 2);

    const flowStructureApi = new FormsStructureAPI(PublicConfig.NEXT_PUBLIC_FORMS_STRUCTURE_URL, accessToken);

    try {

      const flowMap = !!preview && !isFlow ? await flowStructureApi.getForm(encodeURIComponent([ flow, form, section.split("&")[0], ...remainingPartsToRequestForPreview ].join("|"))) : await flowStructureApi.getFlow(flow);
      if (!flowMap) {
        console.error("Flow Not found!");
        return;
      }
      const storyData = Promise.all([
        SBHelper.getDefaultHeader(),
        SBHelper.getDefaultFooter(),
        SBHelper.getDefaultCookieBar(),
        SBHelper.getFallbackHeaderTags(),
      ]);
      const [ header, footer, cookieBar, fallbackTags ] = await storyData;

      const story: IFlowPageStory = {
        header,
        footer,
        cookieBar,
        fallbackTags,
      };
      const output = {
        flow: flowMap as FlowDataStructureResponse,
        form: form ?? null,
        section: section ?? null,
        story,
        key: false, // form ? form.storyId : false,
        trustpilot,
        preview,
        redirect: "redirectPath" in flowMap ? flowMap.redirectPath : undefined,
      };

      return output;
    } catch (err) {
      console.error(`Could not render form see error: ${err}`);

    }
  }

};

const FlowWrapper: FC<IFlowWrapper> = (
  { trustpilot, params, searchParams },
) => {

  const [ props, setProps ] = useState<Props>();
  const [ flowReady, setFlowReady ] = useState(false);
  const { accessToken } = useAuth();
  const router = useRouter();

  useEffect(() => {
    const effectGetFormsPageProps = async () => {
      const effectProps = await getFormsPageProps(params, searchParams, accessToken, trustpilot);
      if (effectProps?.redirect) {
        void router.push(effectProps?.redirect);
        return;
      }
      setProps(effectProps);
      setFlowReady(true);
    };
    void effectGetFormsPageProps();

  }, [ accessToken ]);

  return (
    <>
      { flowReady && (
        <>
          { props?.flow ? (
            <FlowProvider
              flow={props.flow}
              initialForm={props.form}
              initialSection={props.section}
              preview={props.preview}
            >
              <FlowContent {...props} key={typeof props.key === "string" ? props.key : undefined} />
            </FlowProvider>
          ) : (
            <>{ notFound() }</>
          ) }
        </>
      ) }
    </>
  );
};

export default FlowWrapper;
