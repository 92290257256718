import { ChangeEvent, FC } from "react";

import useA11y from "apps/website/hooks/useA11y";

import Label from "../Label/Label";
import { ITooltip } from "../../feature/Tooltip/Tooltip";
import { FontStyle } from "../Label/Label.map";
import { Align } from "../../base/Text/Text.map";
import ReactionSelect, {
  IReactionSelectProps,
} from "../ReactionSelect/ReactionSelect";

export interface IReactionSelectListProps {
  label: string;
  optional?: boolean;
  hideLabel?: boolean;
  labelPosition?: Align;
  labelStyle?: FontStyle;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  component?: string;
  className?: string;
  tabbable?: boolean;
  tooltip?: ITooltip;
  selected?: string;
  children: IReactionSelectProps[];
}

const ReactionSelectList: FC<IReactionSelectListProps> = ({ onChange, label, optional = false, className, hideLabel = false, component = "ReactionSelectList", labelStyle, labelPosition = "default", children }) => {
  const { UUID } = useA11y();
  return (
    <div data-component={component} className={`${className}`} data-theme="form-input">
      <Label
        label={label}
        align={labelPosition}
        fontStyle={labelStyle}
        hideLabel={hideLabel}
        UUID={UUID}
        optional={optional}
        className="flex items-center"
      />
      { children.map((listItem) => (
        <div key={listItem.name} className="w-full py-4 border-b border-b-solid">
          <ReactionSelect {...listItem} onChange={onChange}/>
        </div>
      )) }
    </div>
  );
};

export default ReactionSelectList;
