import { FC, useCallback } from "react";

import {
  BaseTypeToSelectFieldOption,
  TypeToSelectField,
} from "libs/forms-schema/src";
import {
  FieldData,
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { getStoryBlokTooltip } from "apps/website/utils/storyblok/forms";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import useField from "apps/website/hooks/useField";

import TypeToSelect, {
  ITypeToSelectOption,
} from "../../form/TypeToSelect/TypeToSelect";

export interface IFormServiceTypeToSelect {
  flowId: string;
  field: TypeToSelectField;
  selected?: FieldData;
  optional?: boolean,
  onChange(value: FieldData): void;
  linkingId: string;
}

export const FormServiceTypeToSelect: FC<IFormServiceTypeToSelect> = ({
  flowId,
  field,
  optional,
  onChange,
  linkingId,
  selected,
}) => {

  const handleChange = useCallback((options: ITypeToSelectOption[]) => {
    onChange({
      submitValue: options.map((mappedOption) => mappedOption.value),
      displayValue: options.map((mappedOption) => mappedOption.title),
    });
  }, [ onChange ]);

  const isSelected = useCallback(
    (optionToCheck: BaseTypeToSelectFieldOption) => {
      if (selected?.submitValue && Array.isArray(selected.submitValue)) {
        return selected.submitValue?.some((sv) => sv === optionToCheck.value);
      }
      return optionToCheck.value === selected?.submitValue;
    },
    [ selected?.submitValue ],
  );

  const { getLabelStyle } = useField(field);

  return (
    <TypeToSelect
      labelStyle={ getLabelStyle }
      name={`${field.name}[${linkingId}]`}
      label={field.label ?? ""}
      optional={optional}
      placeholder={field.placeholder as string}
      hideLabel={field.hide_label}
      labelPosition={field.label_position}
      options={field.options.map(({ content }) => ({
        value: content.value,
        title: content.title,
        aliases: content.aliases?.split(",").map((alias) => alias.trim().toLocaleLowerCase()),
        optionMessage: (content.message && content.message_icon) ? {
          icon: content.message_icon,
          body: interpolateString(content.message, getFlowFieldValuesForInterpolation(flowId, linkingId, "GET_RELATED"), Interpolator.FRONTEND),
        } : undefined,
        selected: isSelected(content),
      }))}
      optionsGroupMessage={field.options_group_message && {
        icon: field.options_group_message.message_icon,
        body: interpolateString(field.options_group_message.message, getFlowFieldValuesForInterpolation(flowId, linkingId, "GET_RELATED"), Interpolator.FRONTEND),
      }}
      onChange={(options) => handleChange(options)}
      tooltip={field.tooltip ? getStoryBlokTooltip(
        field.tooltip,
        getFlowFieldValuesForInterpolation(flowId, linkingId, field.tooltip?.[0]?.interpolation_behavior),
      ) : undefined}
    />
  );
};
