import { FC } from "react";
import dayjs from "dayjs";

import Text from "apps/website/components/base/Text/Text";

import Button from "../../base/Button/Button";
import Icon from "../../base/Icon/Icon";
import Image, { IImage, IImageProps } from "../../base/Image/Image";
import Tag from "../../base/Tag/Tag";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import ImageCarousel from "../../feature/Carousel/ImageCarousel/ImageCarousel";
import Column from "../../layout/Column/Column";
import Spacer from "../../layout/Spacer/Spacer";
import Grid from "../../layout/Grid/Grid";
import SectionComparisonCardList, {
  ISectionComparisonCardListProps,
} from "../SectionComparisonCardList/SectionComparisonCardList";

interface ISectionOneClickPurchaseIcon {
  image: IImageProps;
  title: string;
}

export interface IOneClickPurchaseCard {
  title?: string;
  pricePrefix: string;
  price: string;
  salePrice: string;
  tag: string;
  subscriptionDetails?: string;
  addCtaText: string;
  removeCtaText: string;
  footer: string;
  images: IImage[],
  isProductAdded?: boolean,
  onRemoveProduct?: () => void,
  onAddProduct?(): void,
  allowAddProduct?: boolean,
  deliveryDate?: string,
  body?: string,
  icons?: ISectionOneClickPurchaseIcon[];
  comparisonCards?: ISectionComparisonCardListProps;
  comparisonCardsTitle?: string;
}

export const SectionOneClickCardB: FC<IOneClickPurchaseCard> = ({
  title,
  pricePrefix,
  price,
  salePrice,
  tag,
  subscriptionDetails,
  addCtaText,
  removeCtaText,
  footer,
  images,
  isProductAdded,
  onRemoveProduct,
  onAddProduct,
  allowAddProduct,
  deliveryDate,
  body,
  comparisonCards,
  comparisonCardsTitle,
  icons,
}) => {
  const formatDeliveryDate = (dateString: string) => dayjs(dateString).format("ddd MMM D YYYY");
  return (
    <>
      <Grid>
        <Column data-testid="card" spans={{ md: 6, "2xl": 4 }} offset={{ md: 4, "2xl": 5 }} className="bg-white">
          <div className="relative">
            <Tag theme="pink" className="absolute top-4 left-4 z-10">{ tag }</Tag>
            <ImageCarousel images={images} />
          </div>
          <div className="p-4">
            <Grid gap="lg">
              <Column justify={"between"}>
                { title && (
                  <>
                    <Text tag="h2" display="title" size={legacySizeCollectionMap.titleSm} align="center" data-testid="cardTitle">
                      { title }
                    </Text>
                    <Spacer size="sm" />
                  </>
                ) }

                { icons && (
                  <>
                    <Spacer size="md" />
                    <Grid>
                      { icons.map((icon) => (
                        <Column key={icon.title} spans={3} align="center">
                          <div className="max-w-[50px]">
                            <Image image={icon.image} alt={icon.title} />
                          </div>
                          <Spacer size="sm" />
                          <Text display="subtitle" align="center" size={{ default: "3xs", md: "2xs" }}>{ icon.title }</Text>
                        </Column>
                      )) }
                    </Grid>
                    <Spacer size="lg" />
                  </>
                ) }

                { body && (
                  <>
                    <Text tag="div" size="2xs" align="center" data-testid="cardFooter">
                      { body }
                    </Text>
                    <Spacer size="md" />
                  </>
                ) }
                <div className="flex justify-center items-center">
                  <Text size={legacySizeCollectionMap.bodySm} align="center" className="flex justify-center items-center">
                    <span data-testid="cardPricePrefix">
                      { pricePrefix }
                    </span>
                    { price && (
                      <>
                  &nbsp;{ salePrice ? (<s data-testid="cardPrice">{ price }</s>) : price }
                      </>
                    ) }
                    { salePrice && (
                      <>
                  &nbsp;<Text tag="span" display="subtitle" data-testid="cardSalePrice">{ salePrice }</Text>
                      </>
                    ) }
                  </Text>
                  { tag && (
                    <Tag theme="pink" className="max-w-[75px] ml-2" whitespace="preWrap" size="small">{ tag }</Tag>
                  ) }
                </div>
                <Grid>
                  <>
                    { deliveryDate &&
                <Column spans={6}>
                  <Text size="2xs" className="flex items-center justify-center">
              First delivery:&nbsp;
                    <Text tag="span" display="subtitle" size={legacySizeCollectionMap.titleXs} data-testid="cardDeliveryDate">
                      { deliveryDate && formatDeliveryDate(deliveryDate) }
                    </Text>
                  </Text>
                </Column>
                    }
                  </>
                  <>
                    { subscriptionDetails &&
                <Column spans={6}>
                  <Text size="2xs" className="flex items-center justify-center">After that:&nbsp;
                    <Text tag="span" display="subtitle" size={legacySizeCollectionMap.titleXs} data-testid="cardSubscriptionDetails">
                      { subscriptionDetails }
                    </Text>
                  </Text>
                </Column>
                    }
                  </>
                </Grid>
                <Spacer size="md" />
                <Column align="center" data-testid="cardAddProductWrapper">
                  { isProductAdded ? (
                    <div className="bg-green px-4 h-[50px] flex justify-center items-center space-x-4">
                      <Icon icon="tick" size="small"/>
                      <Text display="title">In your box</Text>
                    </div>
                  ) : <>{ allowAddProduct && (
                    <Button onClick={onAddProduct} disabled={!deliveryDate} data-testid="cardAddCta">
                      { addCtaText }
                    </Button>
                  ) }</> }
                </Column>
                <Column align="center">
                  { isProductAdded && (
                    <Button design="underline" onClick={onRemoveProduct}>
                      { removeCtaText }
                    </Button>
                  ) }
                </Column>
                <Text tag="div" size="2xs" align="center" data-testid="cardFooter">
                  { footer }
                </Text>
              </Column>
            </Grid>
          </div>
        </Column>
      </Grid>
      { comparisonCards?.cards && (
        <SectionComparisonCardList title={comparisonCardsTitle} {...comparisonCards} />
      ) }
    </>
  );
};
