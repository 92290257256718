import { IFieldTooltip } from "@forms/schema";
import { ITooltip } from "apps/website/components/feature/Tooltip/Tooltip";
import {
  interpolateString,
  Interpolator,
} from "libs/form-utils/src/lib/interpolate";

import { renderStoryBlokRichText } from "./text";

export const getStoryBlokTooltip = (
  tooltip: [IFieldTooltip],
  interpolationValues: Record<string, string>,
): ITooltip | undefined => {
  if (!tooltip?.length) return undefined;
  return {
    ...tooltip[0],
    buttonName: tooltip[0].label,
    body: typeof tooltip[0].body === "string" ?
      interpolateString(tooltip[0].body, interpolationValues, Interpolator.FRONTEND) :
      renderStoryBlokRichText(tooltip[0].body, {
        interpolation: {
          values: interpolationValues,
        },
      }),
  };
};
