export const parseParamsFromUrl = (url: string) => {

  const split = url.split("?");

  // No "?" found, so return empty search params
  if (split.length === 1) return new URLSearchParams();

  const [ , paramString ] = split;

  return new URLSearchParams(paramString);

};

export const getUrlWithoutParams = (url: string): string => url.split("?")[0];

export const mergeParams = (...params: URLSearchParams[]) => {

  const objectified = params.map(Object.fromEntries);

  const allEntries = objectified.flatMap(Object.entries);

  const mergedObject = Object.fromEntries(allEntries);

  const merged = new URLSearchParams(
    mergedObject,
  );

  return merged;
};

export const appendQueryparams = (url: string, ...additionalQueryParams: URLSearchParams[]) => {

  const urlWithoutQuery = getUrlWithoutParams(url);
  const originalQuery = parseParamsFromUrl(url);

  const mergedParams = mergeParams(originalQuery, ...additionalQueryParams);

  return `${urlWithoutQuery}?${mergedParams.toString()}`;

};
