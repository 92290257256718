"use client";

import clsx from "clsx";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
} from "react";

interface Props {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  min?: number;
  max?: number;
}

const baseContainerStyle = "p-2";
const baseButtonStyle = "text-md font-bold px-4";
const activeButtonStyle = "bg-indigo-600 hover:bg-indigo-700 text-white";
const inactiveButtonStyle = "bg-gray-200 text-white";

export const QuantityInput: FC<Props> = ({
  value,
  setValue,
  min = 1,
  max = 50,
}) => {

  const increment = useCallback(() => {
    setValue((old) => Math.min(max, old + 1));
  }, [ setValue, max ]);

  const decrement = useCallback(() => {
    setValue((old) => Math.max(min, old - 1));
  }, [ setValue, min ]);

  return (
    <div className="flex flex-row">

      <button
        onClick={decrement}
        type="button"
        disabled={value <= min}
        className={clsx(
          baseButtonStyle,
          baseContainerStyle,
          value > min ? activeButtonStyle : inactiveButtonStyle,
          "rounded-l-md",
        )}>
        -
      </button>
      <select
        value={value}
        onChange={(e) => setValue(parseInt(e.target.value, 10))}
        className={clsx(
          "pr-8 w-16 py-2 text-center border-l-0 border-r-0 border-gray-300",
        )}>
        { Array(max).fill(undefined).map((_, idx) => (
          <option
            key={idx} value={idx + 1}
          >
            { idx + 1 }
          </option>
        )) }
        { value }
      </select>
      <button
        onClick={increment}
        type="button"
        disabled={value >= max}
        className={clsx(
          baseButtonStyle,
          baseContainerStyle,
          value < max ? activeButtonStyle : inactiveButtonStyle,
          "rounded-r-md",
        )}>
        +
      </button>

    </div>
  );

};
