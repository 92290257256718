"use client";

import { SortingState } from "@tanstack/react-table";
import { useState } from "react";

export function useTableSorter() {
  const [ sorting, setSorting ] = useState<SortingState>([]);

  return {
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  };
}
