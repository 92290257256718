"use client";

import clsx from "clsx";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

import { useTextInput } from "@/hooks";

export interface ComboBoxProps<T> {
  options: T[];
  extractName: (_?: T) => string;
  selected?: T;
  setSelected(_?: T): void;
  setSearchText: ReturnType<(typeof useTextInput)>[1];
}

export function ComboBox<T>({
  options,
  extractName,
  selected: currentlySelected,
  setSelected,
  setSearchText,
}: ComboBoxProps<T>) {

  return (
    <Combobox as="div" value={currentlySelected} onChange={setSelected} nullable>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setSearchText(event.target.value)}
          displayValue={extractName}
          placeholder="Type to search..."
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        { options.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            { options.map((option) => (
              <Combobox.Option
                key={extractName(option)}
                value={option}
                className={({ active }) => clsx(
                  "relative cursor-default select-none py-2 pl-3 pr-9",
                  active ? "bg-indigo-600 text-white" : "text-gray-900",
                )
                }
              >
                { ({ active, selected }) => (
                  <>
                    <span className={clsx("block truncate", selected && "font-semibold")}>{ extractName(option) }</span>

                    { selected && (
                      <span
                        className={clsx(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600",
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) }
                  </>
                ) }
              </Combobox.Option>
            )) }
          </Combobox.Options>
        ) }
      </div>
    </Combobox>
  );
}
