import { FC, ReactNode, useState } from "react";

import Text from "apps/website/components/base/Text/Text";
import Table from "apps/website/components/feature/Table/Table";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import { DisplayState } from "@/constants/state";
import { ISummaryItem, ISummaryDiscountItem, ITitlePrice } from "@forms/schema";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";

import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import IconButton from "../../base/IconButton/IconButton";

export interface ISummary {
  title: string;
  image?: IImageProps;
  originalPrice?: string;
  items: (ISummaryItem | ISummaryDiscountItem)[];
  footer?: ISummaryItem[];
  children?: ReactNode;
  state: DisplayState;
  canToggle?: boolean;
  isExpandedByDefault?: boolean;
  titlePrice?: ITitlePrice;
}

const Summary: FC<ISummary> = ({
  title,
  image,
  items,
  footer,
  state,
  canToggle = false,
  isExpandedByDefault = false,
  children,
  titlePrice,
}) => {

  const [ isExpanded, setIsExpanded ] = useState(isExpandedByDefault || !canToggle);

  const cellStyle = "py-0 lg:py-2";
  const leftCellStyle = `text-left px-4 ${cellStyle}`;
  const rightCellStyle = `text-right pr-4 ${cellStyle}`;
  const footerCellStyle = "pt-2 lg:pt-8";

  return (
    <div data-component={Summary.name}>
      { image?.src && (
        <Image image={image} alt={title} />
      ) }
      <Table className="w-full border-collapse bg-white">
        <tr>
          <th
            className={`text-left px-4 ${themeRootClassMap.dark} ${canToggle ? "" : "py-2"}`}
            data-theme="dark"
            scope="col"
            colSpan={2}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Text tag="span" display="subtitle" size={legacySizeCollectionMap.bodyLg}>{ isExpanded ? title : "Plan summary" }</Text>
                { canToggle && (
                  <IconButton icon={isExpanded ? "arrowUp" : "arrowDown" } label="Toggle summary" size="xsmall" onClick={() => setIsExpanded(!isExpanded)} />
                ) }
              </div>
              { canToggle && (
                <>
                  { !isExpanded && (
                    <div className="flex justify-center items-center">
                      { (titlePrice && titlePrice.fullPrice && titlePrice.discountedPrice) && (
                        <Text tag="span" className="mr-2"><s>{ titlePrice?.fullPrice }</s></Text>
                      ) }
                      <Text tag="span" display="title" size={legacySizeCollectionMap.bodyLg}>{ titlePrice?.discountedPrice }</Text>
                    </div>
                  ) }
                </>
              ) }
            </div>
          </th>
        </tr>
        { state === DisplayState.READY ? (
          <>
            { items?.map(((item, index) => (
              <tr key={index} aria-hidden={!isExpanded} className={`${!isExpanded ? "h-0 overflow-hidden hidden" : "h-auto"}`}>
                <th scope="row" className={`${index === 0 && "pt-2 lg:pt-4"} ${leftCellStyle}`}><Text tag="span" size={legacySizeCollectionMap.bodySm}>{ item?.title?.value }</Text></th>
                <td className={`${index === 0 && "pt-2 lg:pt-4"} ${rightCellStyle}`}>
                  <div className="flex flex-row justify-end items-center">
                    <Text tag="span" className={`text-right pr-3 ${item?.preBody?.strike ? "line-through" : ""}`} display={`${item?.preBody?.bold ? "subtitle" : "default"}`} size={legacySizeCollectionMap.title2xs}>{ item?.preBody?.value }</Text>
                    <Text
                      tag="span"
                      className={`text-right ${item?.body?.strike ? "line-through" : ""}`}
                      size={legacySizeCollectionMap.bodySm}
                      display={`${item?.body?.bold ? item?.body?.uppercase ? "title" : "subtitle" : "default"}`}
                      color={item.body?.highlight ? "success" : "default"}
                    >{ item?.body?.value }</Text>
                  </div>
                  { item.bodyExtra?.value && (<Text tag="span" size={legacySizeCollectionMap.bodySm}>{ item.bodyExtra?.value } </Text>) }
                </td>
              </tr>
            ))) }
            <tr aria-hidden={!isExpanded} className={!isExpanded ? "h-0 overflow-hidden hidden" : "h-auto"}>
              <td className="text-left px-4" colSpan={2}>
                { children }
              </td>
            </tr>
            { footer?.map(((foot, index) => (
              <tr key={index} aria-hidden={!isExpanded} className={!isExpanded ? "h-0 overflow-hidden hidden" : "h-auto"}>
                <th scope="row" className={`${footerCellStyle} ${leftCellStyle}`}><Text tag="span" display={`${foot?.title?.bold ? "subtitle" : "default"}`} size={foot?.title?.bold ? legacySizeCollectionMap.bodyLg : legacySizeCollectionMap.bodySm}>{ foot?.title?.value }</Text></th>
                <td className={`${footerCellStyle} ${rightCellStyle}`}>{ foot.bodyExtra?.value ? (
                  <>
                    <Text tag="span" display="subtitle">{ foot.bodyExtra?.value }</Text>
                  </>
                ) : (<Text tag="span" display="subtitle" size={foot?.title?.bold ? legacySizeCollectionMap.bodyLg : legacySizeCollectionMap.bodySm} >{ foot?.body?.value }</Text>) } </td>
              </tr>
            ))) }
          </>
        ) : (
          <tr>
            <td colSpan={2} className="flex justify-center items-center py-8">
              <LoadingSpinner color="black" size={32}/>
            </td>
          </tr>
        ) }
      </Table>
    </div>
  );
};

export default Summary;
