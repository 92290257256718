import { FC, ReactNode, memo } from "react";

import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

interface IFlowBannerProps {
  children: ReactNode
}

const FlowBanner: FC<IFlowBannerProps> = ({ children }) => (
  <div className="bg-brand py-2 lg:py-4">
    <Container>
      <Grid>
        <Column justify="center" align="center">
          <Text tag="span" align="center" size={legacySizeCollectionMap.titleSm} display="title">{ children }</Text>
        </Column>
      </Grid>
    </Container>
  </div>
);

export default memo(FlowBanner);
