import { FC, useMemo } from "react";
import clsx from "clsx";

import {
  ISelectItem,
  SelectableButtonGroup,
} from "../SelectableButtonGroup/SelectableButtonGroup";
import { NumberInput } from "../NumberInput/NumberInput";

interface Props {
  value?: number;
  onChange: (_: number | string) => void;
  placeholder?: string;
  numberClassName?: string;
  numberContainerClassName?: string;
  containerClass?: string;
  disabled?: boolean;
  min?: number;
  single?: number;
  unlimited?: number;
  singleText?: string;
  multiText?: string;
  unlimitedText?: string;
  /** If not set to true, the number input will be hidden if multi is not selected */
  alwaysShowNumber?: boolean;
}
export const SingleMultiUnlimitedInput: FC<Props> = ({
  value,
  onChange,
  singleText = "Single",
  multiText = "Multiple",
  unlimitedText = "Unlimited",
  placeholder = unlimitedText,
  numberContainerClassName = "",
  numberClassName = "",
  containerClass = "",
  disabled = false,
  single = 1,
  unlimited,
  min = typeof unlimited === "number" ? Math.min(single, unlimited) : single,
  alwaysShowNumber = false,
}) => {
  const multiSelected = useMemo(
    () => value !== single && value !== unlimited,
    [ value, single, unlimited ],
  );
  const itemSingle: ISelectItem = useMemo(() => ({
    name: singleText,
    onClick: () => onChange(single),
    selected: value === single,
  }), [ singleText, onChange, single, value ]);
  const itemMulti: ISelectItem = useMemo(() => ({
    name: multiText,
    onClick: () => (multiSelected
      ? undefined
      : onChange(single + 1)),
    selected: multiSelected,
  }), [ multiText, onChange, single, multiSelected ]);
  const itemUnlimited: ISelectItem = useMemo(() => ({
    name: unlimitedText,
    onClick: () => onChange(unlimited ?? ""),
    selected: value === unlimited,
  }), [ unlimitedText, onChange, unlimited, value ]);
  const items: ISelectItem[] = useMemo(
    () => [ itemSingle, itemMulti, itemUnlimited ],
    [ itemSingle, itemMulti, itemUnlimited ],
  );
  return (<div data-component={SingleMultiUnlimitedInput.name} className={containerClass}>
    <SelectableButtonGroup items={items} disabled={disabled}/>
    <NumberInput
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={numberClassName}
      containerClass={clsx(numberContainerClassName, multiSelected || alwaysShowNumber || "hidden")}
      disabled={disabled}
      step={1}
      min={min}
      emptyOnUndefined={true}
    />
  </div>);
};
