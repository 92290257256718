import { FC, useCallback, useEffect } from "react";
import dayjs from "dayjs";

import Select from "apps/website/components/form/Select/Select";
import { SelectField } from "libs/forms-schema/src";
import {
  FieldData,
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { getStoryBlokTooltip } from "apps/website/utils/storyblok/forms";
import {
  shouldLabelWrap,
  shouldPreserveLabelWhiteSpace,
} from "apps/website/utils/forms/labels";
import useField from "apps/website/hooks/useField";

export interface IFormServiceSelect {
  flowId: string;
  field: SelectField;
  selected?: FieldData;
  onChange(value: FieldData): void;
  optional?: boolean;
  linkingId: string;
  isValid?: boolean;
}

export const FormServiceSelect: FC<IFormServiceSelect> = ({
  flowId,
  field,
  selected,
  onChange,
  optional,
  linkingId,
  isValid,
}) => {

  useEffect(() => {
    if (!selected) {
      const newSelectedOption = field.options.map((option) => ({
        isSelected: option.selected,
        optValue: option.value,
        title: option.title,
      })).find((s) => s.isSelected);
      if (newSelectedOption) {
        onChange({
          submitValue: newSelectedOption.optValue,
          displayValue: newSelectedOption.title,
        });
      }
    }
  }, [ selected ]);

  const findSelectedOption = useCallback(() => {
    if (selected) {
      return selected?.submitValue;
    }
    const newSelectedOption = field.options.map((option) => ({
      isSelected: option.selected,
      optValue: option.value,
      title: option.title,
    })).find((s) => s.isSelected);
    if (newSelectedOption) {
      return newSelectedOption?.optValue;
    }
  }, [ field.options, selected ]);

  const handleSelect = useCallback((selectedValue: string) => {
    const options = field.options.map((option) => ({
      optValue: option.value,
      title: option.title,
    }));

    const actualSelectedOption = options.find((s) => {
      if (dayjs(s.optValue).isValid()) {
        const isDateSame = dayjs(selectedValue).isSame(s.optValue, "date");
        return isDateSame;
      }
      if (typeof s.optValue === "number") {
        return s.optValue === +selectedValue;
      }
      return s.optValue === selectedValue;
    });
    onChange({
      submitValue: actualSelectedOption?.optValue,
      displayValue: actualSelectedOption?.title,
    });

  }, [ field.options, onChange ]);

  const { getLabelStyle } = useField(field);

  return (
    <>
      <Select
        name={`${field.name}[${linkingId}]`}
        labelStyle={ getLabelStyle }
        label={field.label ?? ""}
        placeholder={field.placeholder || field.label as string}
        optional={optional}
        options={field.options.map((option) => ({
          value: option.value as string,
          name: option.title,
          selected: option.selected,
          advancedOptions: option.advanced_options,
          display: field.display,
        }))}
        selected={findSelectedOption() as string}
        allowMultipleSelect={field.allowMultipleSelections}
        hideLabel={field.hide_label}
        preserveLabelWhiteSpace={ shouldPreserveLabelWhiteSpace(field, field.hide_label || false) }
        shouldLabelWrap={ shouldLabelWrap(field, field.hide_label || false) }
        labelPosition={field.label_position}
        onChange={(event) => handleSelect(event.target.value)}
        tooltip={field.tooltip ? getStoryBlokTooltip(
          field.tooltip,
          getFlowFieldValuesForInterpolation(flowId, linkingId, field.tooltip?.[0]?.interpolation_behavior),
        ) : undefined}
        isValid={isValid}
      />
    </>

  );
};
