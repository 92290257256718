import {
  FC,
  useCallback,
  useMemo,
  useState,
} from "react";

import {
  AddProductCardFieldName,
  AddProductField,
  Allergens,
  FieldData,
} from "@forms/schema";
import { getCurrentFlow, useNewFormsServiceStore } from "@./state";
import Column from "apps/website/components/layout/Column/Column";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import Button from "apps/website/components/base/Button/Button";
import Grid from "apps/website/components/layout/Grid/Grid";
import {
  SectionOneClickCard,
} from "apps/website/components/section/SectionOneClickCard/SectionOneClickCard";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import {
  IComparisonCardProps,
} from "apps/website/components/feature/ComparisonCardList/ComparisonCard/ComparisonCard";
import {
  SectionOneClickCardB,
} from "apps/website/components/section/SectionOneClickCardB/SectionOneClickCardB";

const defaultProductTitle: Record<AddProductCardFieldName, string> = {
  sprinklesQuantity: "Chicken Sprinkles",
  nibblesChickenQuantity: "Chicken Nibbles",
  nibblesSalmonQuantity: "Salmon Nibbles",
};

const productAllergies: Record<AddProductCardFieldName, Allergens[]> = {
  sprinklesQuantity: [ "CHICKEN" ],
  nibblesChickenQuantity: [ "CHICKEN" ],
  nibblesSalmonQuantity: [ "SALMON" ],
};

export interface IFormServiceAddProductCardList {
  field: AddProductField;
  flowId: string;
  linkingId: string;
  next(): void;
}

export const FormServiceAddProduct: FC<IFormServiceAddProductCardList> = ({ field, flowId, linkingId, next }) => {
  const { setFlowFieldValue } = useNewFormsServiceStore();
  const [ isInBasket, setIsInBasket ] = useState<boolean | null>(null);

  const onToggleInBasket = useCallback((addToBasket: boolean, fieldName: string, quantity: number) => {
    const updatedQuantity: FieldData = addToBasket ?
      { submitValue: quantity, displayValue: quantity } :
      { submitValue: 0, displayValue: 0 };
    setFlowFieldValue(flowId, fieldName, updatedQuantity, linkingId);
    setFlowFieldValue(flowId, "addedNibbles", { submitValue: addToBasket }, linkingId);
    setIsInBasket(addToBasket);
    if (addToBasket) {
      next();
    }
  }, [ flowId, linkingId, next, setFlowFieldValue ]);

  const getAllCatAllergies = useCallback((): Allergens[] => getCurrentFlow(flowId)?.fields?.filter((mappedField) => mappedField.key.fieldName === "catAllergies")
    .map((mappedAllergies) => mappedAllergies.data.submitValue as Allergens)
    .filter((mappedAllergy) => mappedAllergy !== undefined).flat() ?? [], [ flowId ]);

  const getIsProductInBasket = useCallback((fieldName: string): boolean => {
    const flow = getCurrentFlow(flowId);
    const foundField = flow?.fields?.find(
      (mappedField) => mappedField.key.fieldName === fieldName && mappedField.key.linkingId === linkingId,
    );
    if (!foundField) return false;
    return Number(foundField.data.submitValue) > 0;
  }, [ flowId, linkingId ]);

  const productToDisplay = useMemo(() => {
    const withoutAllergies = field.products.filter(
      (product) => !getAllCatAllergies()?.some(
        (allergy) => productAllergies[product.field_name].includes(allergy),
      ),
    );
    const productsToShow = withoutAllergies.filter((x) => !x.hidden);
    return {
      ...productsToShow[0],
      title: productsToShow[0].title ?? defaultProductTitle[productsToShow[0].field_name],
      fieldName: productsToShow[0].field_name,
      imageTagText: productsToShow[0].image_tag_text,
      images: productsToShow[0].images.map((img) => ({
        image: getStoryBlokImage(img, true), alt: productsToShow[0].field_name })),
      allowAddProduct: false,
      isProductAdded: isInBasket === null ? getIsProductInBasket(productsToShow[0].field_name) : isInBasket,
      price: productsToShow[0].price,
      pricePrefix: productsToShow[0].price_prefix,
      salePrice: productsToShow[0].sale_price,
      addCtaText: productsToShow[0].add_cta_text || "Add to box",
      removeCtaText: productsToShow[0].remove_cta_text || "Remove",
      tag: productsToShow[0].tag,
      footer: renderStoryBlokRichText(productsToShow[0].footer),
      body: renderStoryBlokRichText(productsToShow[0].body),
      onRemoveProduct: () => onToggleInBasket(false, productsToShow[0].field_name, 1),
      icons: productsToShow[0].icons?.map((icon) => ({
        image: getStoryBlokImage(icon.image, true, 50),
        title: icon.title,
      })),
      comparisonCardsTitle: productsToShow[0].comparison_cards_title,
      comparisonCards: {
        cards: productsToShow[0].comparison_cards?.[0]?.cards?.map((card, cardIndex) => ({
          image: getStoryBlokImage(card.image, true, ImageMax.HALF_CONTAINER_MD),
          theme: card.override_theme ? card.override_theme : cardIndex === 0 ? "green" : "dark",
          title: card.title,
          tag: card.tag ? { theme: "pink", children: card.tag } : undefined,
          listItems: card.list_items.map((item) => ({
            title: item.title,
            icon: item.icon ? item.icon : cardIndex === 0 ? "tickHeavy" : "crossHeavy",
          })),
        })) as [IComparisonCardProps, IComparisonCardProps],
      },
    };
  }, [ field.products, getAllCatAllergies, getIsProductInBasket, isInBasket, onToggleInBasket ]);

  return (
    <Column>
      <>
        { productToDisplay.component === "form_add_product_card" && (
          <SectionOneClickCard { ...productToDisplay } />
        ) }
        { productToDisplay.component === "form_add_product_card_b" && (
          <SectionOneClickCardB { ...productToDisplay } />
        ) }
        <div className={`fixed bottom-0 left-0 w-full p-4 z-[35] ${themeRootClassMap.brand}`}>
          { getIsProductInBasket(productToDisplay.fieldName) ?
            <Column align="center" justify={"center"} direction={"row"}>
              <Button
                onClick={next}
                design="default"
              >
                { "Continue" }
              </Button>
            </Column>
            :
            <Grid >
              <Column align="center" justify={"center"} direction={"row"}>
                <Button onClick={() => next()} design="underline" data-testid="continueWithoutProductCta">
                  <span className="flex justify-center items-center">No Thanks</span>
                </Button>
                <Button onClick={() => onToggleInBasket(true, productToDisplay.fieldName, 1)} data-testid="continueWithProductCta">
                  <span className="flex justify-center items-center">Add To box</span>
                </Button>
              </Column>
            </Grid>
          }
        </div>
      </>
    </Column>
  );

};
