import { FC } from "react";

import Column from "../../layout/Column/Column";
import {
  IStoryBlokTextBlockWithAccordion,
  StoryBlokFieldsetTextBlockWithAccordion,
} from "../../storyblok/sections/fieldsets/StoryBlokFieldsetTextBlockWithAccordion";
import {
  StoryBlokFormDiscountCodeContent,
} from "../../storyblok/sections/forms/StoryBlokFormDiscountCodeContent";
import {
  IStoryBlokDiscountCodeContent,
  IStoryBlokDiscountCodeContentWithCode,
} from "../../storyblok/sections/StoryBlokDiscountCodeContent";
import {
  StoryBlokFormIconCallout,
} from "../../storyblok/sections/forms/StoryBlokFormIconCallout";
import {
  IStoryBlokIconCallout,
} from "../../storyblok/sections/StoryBlokIconCallout";

type FieldSetFormComponent = "text_block_with_accordion" | "section_discount_code_content" | "section_icon_callout";

export interface IFieldsetFooter {
  flowId: string;
  linkingId?: string;
  component?: FieldSetFormComponent[number];
  blok?: (
    IStoryBlokTextBlockWithAccordion |
    IStoryBlokDiscountCodeContent |
    IStoryBlokDiscountCodeContentWithCode |
    IStoryBlokIconCallout
  );
}

export const FieldsetFooterComponent: FC<IFieldsetFooter> = ({ component, flowId, linkingId, blok }) => (
  <Column>
    { component === "text_block_with_accordion" && (
      <StoryBlokFieldsetTextBlockWithAccordion flowId={flowId} blok={blok as IStoryBlokTextBlockWithAccordion} />
    ) }
    { component === "section_discount_code_content" && (
      <>
        <StoryBlokFormDiscountCodeContent
          flowId={flowId}
          blok={(blok as IStoryBlokDiscountCodeContent) }
        />
      </>
    ) }
    { component === "section_icon_callout" && (
      <>
        <StoryBlokFormIconCallout
          linkingId={linkingId}
          flowId={flowId}
          blok={(blok as IStoryBlokIconCallout)}
        />
      </>
    ) }
  </Column>
);
