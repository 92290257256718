import { FC } from "react";
import Balancer from "react-wrap-balancer";

import Text from "apps/website/components/base/Text/Text";
import Button from "apps/website/components/base/Button/Button";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { Theme } from "apps/website/maps/Theme.map";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

import Modal from "../Modal";

export interface IDialogModalProps {
  id: string;
  title: string;
  subtitle: string;
  confirmText: string;
  onConfirm(): void ;
  cancelText: string;
  onCancel(): void;
  isOpen: boolean;
  theme?: Theme;
  modalTitleId: string;
  modalDescriptionId: string;
}

const DialogModal: FC<IDialogModalProps> = ({
  id,
  title,
  subtitle,
  confirmText,
  onConfirm,
  cancelText,
  onCancel,
  isOpen,
  modalTitleId,
  modalDescriptionId,
  theme = "default",
}) => (
  <Modal
    id={id}
    component={DialogModal.name}
    isOpen={isOpen}
    onClose={onCancel}
    modalTitleId={modalTitleId}
    modalDescriptionId={modalDescriptionId}
    hideCloseButton
    size="sm"
    theme={theme}
  >
    <div
      className="py-2 px-2 md:px-6 flex flex-col items-center"
    >
      <Spacer size="xl" />
      <Text
        data-title
        size={legacySizeCollectionMap.titleLg}
        display="title"
        align="center"
      >
        <Balancer>
          { title }
        </Balancer>
      </Text>
      <Text
        data-body
        align="center"
        size={legacySizeCollectionMap.titleMd}
        display="subtitle"
      >
        <Balancer>
          { subtitle }
        </Balancer>
      </Text>
      <Spacer size="lg" />
      <Button
        size="fit-content"
        onClick={onConfirm}
      >
        { confirmText }
      </Button>
      <Spacer size="lg" />
      <button>
        <Text
          data-label="cancel-modal-text"
          onClick={onCancel}
          display="subtitle"
          className="underline font-bold"
          size="sm"
        >
          { cancelText }
        </Text>
      </button>
      <Spacer size="xl" />
    </div>
  </Modal>
);

export default DialogModal;
