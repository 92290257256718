"use client";

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";

import { INavBarItem, NavBarItem } from "./NavBarItem";
import { NavBarUserPreview } from "./NavBarUserPreview";

interface Props {
  appName?: string;
  entries: INavBarItem[];
  displayName: string;
  displayInitials: string;
  onLogout(): void;
}

export function Navbar({
  appName = "KatKin Admin",
  entries,
  displayName,
  displayInitials,
  onLogout,
}: Props) {

  const [ sidebarOpen, setSidebarOpen ] = useState(false);

  return (
    <div data-component={Navbar.name}>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                      alt="Workflow"
                    />
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    { entries.map((item) => (
                      <NavBarItem key={item.name} name={item.name} href={item.href} icon={item.icon}/>
                    )) }
                  </nav>
                </div>
                <NavBarUserPreview
                  name={displayName}
                  initials={displayInitials}
                  image="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  onLogout={onLogout}
                />
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">{ /* Force sidebar to shrink to fit close icon */ }</div>
          </div>
        </Dialog>
      </Transition.Root>

      { /* Static sidebar for desktop */ }
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        { /* Sidebar component, swap this element with another sidebar if you like */ }
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4 text-2xl font-bold">
              <Link href="/" className="no-underline text-black">

                { appName }

              </Link>
            </div>
            <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
              { entries.map((item) => (
                <NavBarItem key={item.name} name={item.name} href={item.href} icon={item.icon}/>
              )) }
            </nav>
          </div>
          <NavBarUserPreview
            name={displayName}
            initials={displayInitials}
            onLogout={onLogout}
          />
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
