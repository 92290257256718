import { FC } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { PublicConfig } from "apps/website/config/public";

import CardDetailsFormCheckoutV2, {
  PaymentDetailsType,
} from "./CardDetailsFormCheckoutV2/CardDetailsFormCheckoutV2";

const stripePromise = loadStripe(PublicConfig.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);
const fonts = [
  {
    family: "GreedBold",
    src: "url(/fonts/greed-bold.woff2)",
    display: "block",
  },
];

export interface ICardDetails {
  flowSlug: string;
  formUsed: string;
  isDisabled?: boolean;
  onSuccess(): void;
  paymentType?: PaymentDetailsType;
  price?: number;
}

const CardDetailsCheckoutV2: FC<ICardDetails> = ({
  flowSlug,
  formUsed,
  isDisabled = false,
  onSuccess,
  paymentType,
  price,
}) => (
  <div data-component={CardDetailsCheckoutV2.name}>
    <Elements stripe={stripePromise} options={{ fonts }}>
      <CardDetailsFormCheckoutV2
        flowSlug={flowSlug}
        formUsed={formUsed}
        isDisabled={isDisabled}
        onSuccess={onSuccess}
        paymentType={paymentType}
        price={price}
      />
    </Elements>
  </div>
);

export default CardDetailsCheckoutV2;
