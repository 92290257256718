"use client";

import { FC, PropsWithChildren, useCallback } from "react";
import {
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import Link from "next/link";

interface BaseProps {
  current: number;
  max: number;
}

interface WithHref extends BaseProps {
  computeHref(i: number): string;
}

interface WithOnClick extends BaseProps {
  onClick(i: number): void;
}

type Props = WithHref | WithOnClick;

const pageNumberContainerBaseStyle = "relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20";
const iconProps = {
  className: "w-5 h-5",
  "aria-hidden": true,
};

export const PageNumbers: FC<Props> = ({ max, current, ...props }) => {

  const createActionProps = (i: number): HrefOrOnClick => {
    if ("computeHref" in props) {
      return { href: props.computeHref(i) };
    }
    return { onClick: () => props.onClick(i) };

  };

  const router = useRouter();

  const onChange = useCallback((i: number) => {
    if ("computeHref" in props) {
      router.push(props.computeHref(i));
    } else {
      props.onClick(i);
    }

  }, [ props, router ]);

  return (
    <div data-component={PageNumbers.name}>
      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">

        <LinkOrButton
          {...createActionProps(1)}
          disabled={current === 1}
          className={clsx(
            pageNumberContainerBaseStyle,
            "rounded-l-md",
          )}
        >

          <span className="sr-only">Previous</span>

          <ChevronDoubleLeftIcon
            {...iconProps}
          />
        </LinkOrButton>
        <LinkOrButton
          {...createActionProps(current - 1)}
          disabled={current === 1}
          className={pageNumberContainerBaseStyle}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon
            {...iconProps}
          />
        </LinkOrButton>

        <select
          value={current}
          onChange={(e) => onChange(parseInt(e.target.value, 10))}
          className={clsx(
            pageNumberContainerBaseStyle,
            "pr-8 w-16 py-2 text-center",
          )}>
          { Array(max).fill(undefined).map((_, idx) => (
            <option
              key={idx} value={idx + 1}
            >
              { idx + 1 }
            </option>
          )) }
          { current }
        </select>

        <LinkOrButton
          {...createActionProps(current + 1)}
          disabled={current === max}
          className={pageNumberContainerBaseStyle}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon
            {...iconProps}
          />
        </LinkOrButton>
        <LinkOrButton
          {...createActionProps(max)}
          disabled={current === max}
          className={clsx(
            pageNumberContainerBaseStyle,
            "rounded-r-md",
          )}
        >
          <span className="sr-only">Next</span>
          <ChevronDoubleRightIcon
            {...iconProps}
          />
        </LinkOrButton>
      </nav>
    </div>
  );
};

type HrefOrOnClick = { href: string } | { onClick(): void };
type LinkOrButtonProps = HrefOrOnClick & {
  className?: string;
  disabled?: boolean;
};

const LinkOrButton: FC<LinkOrButtonProps & PropsWithChildren> = ({
  className,
  children,
  disabled,
  ...props
}) => {

  if (disabled) {
    return <div className={className}>
      { children }
    </div>;
  }

  if ("href" in props) {
    return (
      (<Link href={props.href} className={className}>

        { children }

      </Link>)
    );
  }

  return (
    <button type="button" onClick={props.onClick} className={className}>
      { children }
    </button>
  );
};
