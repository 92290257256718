import { FC } from "react";

import Icon from "apps/website/components/base/Icon/Icon";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

import { legacySizeCollectionMap } from "../../base/Text/Text.map";

type InformationStyle = "default" | "icon";

export interface IInformation {
  description: string;
  style?: InformationStyle;
}

const styleMap = {
  default: "flex justify-center",
  icon: "flex",
};

const Information: FC<IInformation> = ({ description, style = "default" }) => (
  <div data-component="Information">
    <div className={styleMap[style]}>
      { style === "icon" && (
        <div className="mr-4">
          <Icon icon="information" size="small"/>
        </div>
      ) }
      <div className="flex items-center">
        <Text size={legacySizeCollectionMap.bodyXs} parseChildren whitespace="preWrap">{ description }</Text>
      </div>
    </div>
    <Spacer size="lg" />
  </div>
);

export default Information;
