import { FC, useCallback, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";

import {
  AddressField,
  AddressValue,
  FieldData,
  FieldName,
  PaymentDetailsField,
} from "@forms/schema";
import DialogModal from "apps/website/components/feature/Modal/DialogModal/DialogModal";
import CardDetails from "apps/website/components/form/CardDetails/CardDetails";
import {
  getFlowFieldValuesForAction,
  getFlowProduct,
} from "libs/state/src/lib/stores/useFormServiceStore";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Button from "apps/website/components/base/Button/Button";
import { DisplayState } from "@/constants/state";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { useAPI } from "apps/website/hooks/useAPI";
import { useDatadog } from "@auth/client-sdk-react";

import { ReviewField } from "../ReviewField";
import {
  getActionTrackingInfo,
} from "../../../../utils/tracking/getActionTrackingInfo";

interface IReviewDetails {
  flowSlug: string;
  label: string;
  field: [PaymentDetailsField | AddressField];
  fieldName: FieldName;
  linkingId: string;
  action: string;
  intialValue: string;
}

export const ReviewDetails: FC<IReviewDetails> = ({
  flowSlug,
  label,
  field,
  fieldName,
  action,
  intialValue,
}) => {
  const [ dialogOpen, setDialogOpen ] = useState<boolean>(false);
  const [ showField, setShowField ] = useState<boolean>(false);
  const [ addressFieldData, setAddressFieldData ] = useState<FieldData>();
  const [ buttonState, setButtonState ] = useState(DisplayState.READY);
  const [ displayValue, setDisplayValue ] = useState<string>(intialValue);

  const formsActionsAPI = useAPI().FormActions;
  const posthog = usePostHog();
  const datadog = useDatadog();
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);

  const openField = useCallback(() => {
    setDialogOpen(false);
    setShowField(true);
    console.log("Field to show", field);
  }, []);

  const confirmAddress = async () => {
    try {
      setButtonState(DisplayState.PROCESSING);
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowSlug);
      if (addressFieldData?.submitValue) {
        fieldSubmitValuesMap.set(fieldName, { value: addressFieldData.submitValue });
      }
      const res = await formsActionsAPI.performAction<never>(
        action,
        fieldSubmitValuesMap,
        getFlowProduct(flowSlug),
        flowSlug,
        undefined,
        featureFlags,
        getActionTrackingInfo(cookies),
        datadog.logger,
      );
      if (!res.success) {
        setButtonState(DisplayState.ERROR);
      } else {
        setButtonState(DisplayState.READY);
        setDisplayValue(`${(addressFieldData?.displayValue as AddressValue).shippingAddress.postcode}, ${(addressFieldData?.displayValue as AddressValue).shippingAddress.line1}`);
        setShowField(false);
      }
    } catch (error) {
      console.error("Update address error", error);
      setButtonState(DisplayState.READY);
    }
  };

  const confirmCard = async (setupIntentId: string) => {
    try {
      setButtonState(DisplayState.PROCESSING);
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowSlug);
      fieldSubmitValuesMap.set("setupIntentId", { value: setupIntentId });
      const res = await formsActionsAPI.performAction<never>(
        action,
        fieldSubmitValuesMap,
        getFlowProduct(flowSlug),
        flowSlug,
        undefined,
        featureFlags,
        getActionTrackingInfo(cookies),
        datadog.logger,
      );
      if (!res.success) {
        // onError({ message: res.message, ...res.failedOn });
        setButtonState(DisplayState.ERROR);
      } else {
        setButtonState(DisplayState.READY);
        setDisplayValue("**** **** **** ****");
        setShowField(false);
      }
    } catch (error) {
      console.error("Payment error", error);
      setButtonState(DisplayState.ERROR);
    }

  };

  const onCancel = useCallback(() => {
    setShowField(false);
  }, []);

  const showModal = useCallback(() => {
    setDialogOpen(true);
  }, []);

  return (
    <>
      { showField ? (
        <Column component={ReviewDetails.name}>
          { field[0]?.component === "address" && (
            <>
              <ReviewField component={"address"} field={field[0]} linkingId={"0"} flowSlug={flowSlug} onChange={setAddressFieldData} />
              <Spacer size="xl" />
              <Grid>
                <Column spans={4} offset={{ lg: 3 }} align={"end"}>
                  <Button onClick={onCancel} design="underline">
                  Cancel
                  </Button>
                </Column>
                <Column spans={{ default: 8, lg: 4 }} align={"start"}>
                  <Button state={buttonState} onClick={confirmAddress}>
                    <span className="flex justify-center items-center"><span className="ml-2">Confirm</span></span>
                  </Button>
                </Column>
              </Grid>
              <Spacer size="xl" />
            </>
          ) }
          { field[0].component === "payment_details" && (
            <>
              <CardDetails
                onSubmit={confirmCard}
                onCancel={onCancel}
                flowSlug={flowSlug}
              />
              <Spacer size="xl" />
            </>
          ) }
        </Column>
      ) : (
        <Column>
          <div className="flex justify-between">
            <Text tag="h3" display="title" size={legacySizeCollectionMap.titleXs}>{ label }</Text>
            <button onClick={showModal}>
              <Text tag="span" display="title" className="underline" size={legacySizeCollectionMap.titleXs}>Change</Text>
            </button>
          </div>
          <Text display="subtitle">
            { displayValue }
          </Text>
          <Spacer size="md" />
        </Column>
      ) }

      <DialogModal
        id={`${fieldName}-modal`}
        title="Keep in mind"
        subtitle="Changing these details wil affect all your existing subscriptions."
        confirmText="I understand"
        cancelText="Never mind, keep my details as they are."
        isOpen={dialogOpen}
        onConfirm={() => openField()}
        onCancel={() => setDialogOpen(false)}
        modalDescriptionId=""
        modalTitleId=""
        theme="light-grey"
      />
    </>
  );
};
