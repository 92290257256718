import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { AvailableTag } from "apps/website/components/base/List/List.map";
import IconTextList from
  "apps/website/components/feature/IconTextList/IconTextList";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import {
  IIconTextItem,
} from "apps/website/components/feature/IconTextList/IconTextListItem/IconTextListItem";
import {
  Columns,
} from "apps/website/components/feature/IconTextList/IconTextListItem/IconTextListItem.map";

export interface IFormIconTextList {
  title?: string;
  body?: string;
  items: IIconTextItem[];
  theme?: Theme;
  listType?: AvailableTag;
  hasBorders?: boolean;
  columns: Columns;
}

const FormIconTextList: FC<IFormIconTextList> = ({
  title,
  body,
  items,
  theme = "light",
  listType,
  columns,
  hasBorders = true,
}) => (
  <Section component={FormIconTextList.name} theme={hasBorders ? theme : "default"} size={hasBorders ? "lg" : "none"}>
    <Container theme={theme} className={`${themeRootClassMap[theme]}`}>
      { !hasBorders && (
        <Spacer size="lg" />
      ) }
      { title && (
        <>
          <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center" parseChildren>{ title }</Text>
          <Spacer size="lg" />
        </>
      ) }
      { body && (
        <>
          <Text align="center" size={legacySizeCollectionMap.bodySm} parseChildren>{ body }</Text>
          <Spacer size="lg" />
        </>
      ) }
      <IconTextList items={items} listType={listType} columns={columns} gap="sm" />
      { !hasBorders && (
        <Spacer size="lg" />
      ) }
    </Container>
  </Section>
);

export default FormIconTextList;
