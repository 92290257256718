import {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";

import { CheckboxField } from "libs/forms-schema/src";
import SimpleCheckbox from
  "apps/website/components/form/Checkbox/SimpleCheckbox/SimpleCheckbox";
import {
  FieldData,
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { getStoryBlokTooltip } from "apps/website/utils/storyblok/forms";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";

export interface IFormServiceCheckbox {
  flowId: string;
  linkingId: string;
  field: CheckboxField;
  onChange(value: FieldData): void;
  optional?: boolean;
  selected?: FieldData;
}

export const FormServiceCheckbox: FC<IFormServiceCheckbox> = ({
  flowId,
  linkingId,
  field,
  onChange,
  optional,
  selected,
}) => {
  const [ defaultChecked, setDefaultSelected ] = useState(typeof selected?.submitValue === "boolean" ? selected.submitValue : field.selected);

  useEffect(() => {
    const shouldBeSelected = (typeof selected?.submitValue === "boolean" ? selected.submitValue : field?.selected) || false;
    onChange({ submitValue: shouldBeSelected, displayValue: shouldBeSelected });
  }, [ ]);

  const handleSelect = useCallback((selectedValue: boolean) => {
    setDefaultSelected(selectedValue);
    onChange({
      submitValue: selectedValue,
      displayValue: selectedValue,
    });
  }, [ onChange ]);

  return (
    <>
      <SimpleCheckbox
        label={field.label ?? ""}
        advancedLabel={renderStoryBlokRichText(field.advanced_label ?? "", { unwrapResolvers: true })}
        labelLink={field.label_link?.[0]}
        name={field.name}
        onChange={(event) => handleSelect(event.target.checked)}
        optional={optional}
        checked={defaultChecked}
        hideCheckbox={field.hide_checkbox}
        tooltip={field.tooltip ? getStoryBlokTooltip(
          field.tooltip,
          getFlowFieldValuesForInterpolation(flowId, linkingId, field.tooltip?.[0]?.interpolation_behavior),
        ) : undefined}
      />
    </>
  );
};
