"use client";

import type { PencilIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  label: string;
  className?: string;
  size?: number;
  Icon: typeof PencilIcon;
}

export const IconButton: FC<Props> = ({
  onClick,
  disabled = false,
  label,
  size = 18,
  className = "",
  Icon,
}) => (
  <button
    type="button"
    aria-label={label}
    disabled={disabled}
    onClick={onClick}
  >
    <Icon width={size} height={size} className={className}/>
  </button>
);
