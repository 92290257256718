import {
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { FC } from "react";

interface Props {
  tip: string;
  position?: "above" | "below"
  error?: boolean;
}

export const Tooltip: FC<Props> = ({
  tip,
  position = "above",
  error,
}) => (
  <div
    className="group flex relative"
  >
    { error ? (
      <ExclamationCircleIcon
        className="h-6 w-6 text-red-500"
      />
    ) : (
      <QuestionMarkCircleIcon
        className="h-6 w-6 text-indigo-500"
      />
    ) }
    <span
      className={clsx(
        "absolute group-hover:opacity-100 min-w-[240px] block  rounded shadow-lg p-2 bg-gray-200 -mt-8 opacity-0 text-center transition-opacity invisible group-hover:visible z-10",
        position === "above" ? "-translate-y-3/4" : "translate-y-3/4",
      )}
    >
      { tip }
    </span>
  </div>
);
