"use client";

import { FC, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";

import { PublicConfig } from "apps/website/config/public";
import {
  getFlowFieldValuesForAction,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { useAPI } from "apps/website/hooks/useAPI";
import { useDatadog } from "@auth/client-sdk-react";

import {
  getActionTrackingInfo,
} from "../../../utils/tracking/getActionTrackingInfo";

import CardDetailsForm from "./CardDetailsForm/CardDetailsForm";

const stripePromise = loadStripe(PublicConfig.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);
const fonts = [
  {
    family: "GreedBold",
    src: "url(/fonts/greed-bold.woff2)",
    display: "block",
  },
];

export interface ICardDetails {
  onSubmit(setupIntent: string): void;
  onCancel(): void;
  flowSlug: string;
}

const CardDetails: FC<ICardDetails> = ({ onSubmit, onCancel, flowSlug }) => {

  const formsActionsAPI = useAPI().FormActions;
  const posthog = usePostHog();
  const datadog = useDatadog();
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);

  const [ clientSecretForCardDetails, setClientSecretForCardDetails ] = useState<string>();
  const [ shouldBeDisabled, setShouldBeDisabled ] = useState<boolean>(false);
  const [ error, setError ] = useState<string | undefined>(undefined);

  useEffect(() => {
    const setupClientSecretForCardDetails = async () => {
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowSlug);
      const result = await formsActionsAPI.performAction<string>(
        "setupStripeIntent",
        fieldSubmitValuesMap,
        "UNKNOWN",
        flowSlug,
        undefined,
        featureFlags,
        getActionTrackingInfo(cookies),
        datadog.logger,
      );
      if (result.success) {
        setClientSecretForCardDetails(result.responseBody);
      } else {
        setShouldBeDisabled(true);
        datadog.logger.error(
          "CardDetails: setupStripeIntent action failed",
          {
            "data-component": CardDetails.name,
            result,
          },
        );
        setError(result.message || "Could not prepare card details component. Please refresh the page and try again.");
      }
    };
    void setupClientSecretForCardDetails();
  }, [ flowSlug, formsActionsAPI ]);

  return (
    <div data-component={CardDetails.name}>
      <Elements stripe={stripePromise} options={{ fonts }}>
        <CardDetailsForm
          onSubmit={onSubmit}
          isDisabled={shouldBeDisabled}
          onCancel={onCancel}
          setupIntentClientSecret={clientSecretForCardDetails ?? ""}
          error={error}
          setError={setError}
        />
      </Elements>
    </div>
  );
};

export default CardDetails;
