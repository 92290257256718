import { FC } from "react";

import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Accordion from "apps/website/components/feature/Accordion/Accordion";

import {
  IStoryBlokAdvancedAccordion,
  StoryBlokAccordionContent,
} from "../StoryBlokAccordion";

export interface IStoryBlokTextBlockWithAccordion {
  _uid: string;
  component: "text_block_with_accordion";
  title: string;
  body: string;
  accordion: [IStoryBlokAdvancedAccordion];
}

export interface IStoryBlokFieldsetTextBlockWithAccordion
  extends IStoryBlokComponent<IStoryBlokTextBlockWithAccordion> {
  flowId: string
}

export const StoryBlokFieldsetTextBlockWithAccordion: FC<IStoryBlokFieldsetTextBlockWithAccordion> = (
  { blok },
) => {
  const Content = () => <>
    { blok.accordion[0].content?.map((contentPart) => (
      <StoryBlokAccordionContent key={contentPart._uid} {...contentPart} />
    ))
    }
  </>;
  return (
    <div data-component={StoryBlokFieldsetTextBlockWithAccordion.name} className="flex flex-col">
      <Text display="subtitle" size={legacySizeCollectionMap.titleSm} align="center">{ blok.title }</Text>
      <Text align="center" size={legacySizeCollectionMap.base}>{ renderStoryBlokRichText(blok.body) }</Text>
      <Spacer size="lg" />
      <Accordion
        title={blok.accordion[0].title}
        Content={<Content />}
        type={blok.accordion[0].design}
      />
    </div>
  );
};
