import { FC } from "react";

import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";

import AddProductCard, {
  IAddProductCardProps,
} from "./AddProductCard/AddProductCard";
import { offsetMap } from "./AddProductCardList.map";

export interface IAddProductCardListProps {
  maxToShow?: number;
  products: IAddProductCardProps[];
}

const AddProductCardList: FC<IAddProductCardListProps> = ({ products }) => (
  <Grid component={AddProductCardList.name} tag="ul">
    { products.map((product, index) => (
      <Column
        tag="li"
        spans={{ default: 12, lg: 4 }}
        offset={{ lg: (index === 0 && products.length < 3) ? (offsetMap[products.length]) : undefined }}
        key={product.name}
      >
        <AddProductCard {...product} />
      </Column>
    )) }
  </Grid>
);

export default AddProductCardList;
