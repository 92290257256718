import {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";

import AddressLookup from
  "apps/website/components/form/AddressLookup/AddressLookup";
import { AddressField, AddressValue, FieldValue } from "@forms/schema";
import {
  FieldData,
  getFieldValue,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { IFormattedAddress } from "libs/address/src/lib/address.types";

export interface IFormServiceAddress {
  flowId: string;
  value?: FieldData;
  onChange(value: FieldData): void;
  optional?: boolean;
  hideContinueToPayment?: boolean,
  field?: AddressField;
}

interface SimpleAddress {
  shipping: IFormattedAddress;
  billing: IFormattedAddress;
}

export const FormServiceAddress: FC<IFormServiceAddress> = ({
  value,
  flowId,
  onChange,
  optional,
  field,
}) => {
  const [ structuredAddress, setStructuredAddress ] = useState<SimpleAddress | undefined>(undefined);

  const address = useCallback(() => {
    if (!value) return;
    if (value?.submitValue && isAddress(value.submitValue)) {
      const addresses = value.submitValue;
      return {
        shipping: {
          address1: addresses?.shippingAddress?.line1 ?? "",
          address2: addresses?.shippingAddress?.line2 ?? "",
          city: addresses?.shippingAddress?.city ?? "",
          country: addresses?.shippingAddress?.country ?? "",
          postcode: addresses?.shippingAddress?.postcode ?? "",
          phone: addresses?.shippingAddress?.phone ?? "",
        },
        billing: {
          address1: addresses?.billingAddress?.line1 ?? "",
          address2: addresses?.billingAddress?.line2 ?? "",
          city: addresses?.billingAddress?.city ?? "",
          country: addresses?.billingAddress?.country ?? "",
          postcode: addresses?.billingAddress?.postcode ?? "",
          phone: addresses?.billingAddress?.phone ?? "",
        },
      };
    }
  }, [ value ]);

  const isAddress = (valueToTest: FieldValue | FieldValue[]): valueToTest is AddressValue => (typeof valueToTest === "object" && "shippingAddress" in valueToTest);

  useEffect(() => {
    setStructuredAddress(address);
  }, []);

  const handleAddressUpdated = useCallback((shippingAddress: IFormattedAddress, billingAddress?: IFormattedAddress) => {
    if (billingAddress) {
      const mappedAddress: AddressValue = {
        billingAddress: {
          line1: billingAddress?.address1,
          line2: billingAddress?.address2,
          postcode: billingAddress?.postcode,
          city: billingAddress?.city,
          country: billingAddress?.country,
          phone: billingAddress?.phone,
        },
        shippingAddress: {
          line1: shippingAddress?.address1,
          line2: shippingAddress?.address2,
          postcode: shippingAddress?.postcode,
          city: shippingAddress?.city,
          country: shippingAddress?.country,
          phone: shippingAddress?.phone,
        },
      };
      onChange({ displayValue: mappedAddress, submitValue: mappedAddress });
    } else {
      const mappedAddress: AddressValue = {
        billingAddress: {
          line1: shippingAddress?.address1,
          line2: shippingAddress?.address2,
          postcode: shippingAddress?.postcode,
          city: shippingAddress?.city,
          country: shippingAddress?.country,
          phone: shippingAddress?.phone,
        },
        shippingAddress: {
          line1: shippingAddress?.address1,
          line2: shippingAddress?.address2,
          postcode: shippingAddress?.postcode,
          city: shippingAddress?.city,
          country: shippingAddress?.country,
          phone: shippingAddress?.phone,
        },
      };
      onChange({ displayValue: mappedAddress, submitValue: mappedAddress });
    }
  }, [ onChange ]);

  return (
    <AddressLookup
      onAddressUpdated={(
        shippingAddress: IFormattedAddress,
        billingAddress?: IFormattedAddress,
      ) => handleAddressUpdated(shippingAddress, billingAddress)}
      defaultPostcode={(getFieldValue(flowId, "postcode")?.data?.submitValue || "") as string}
      address={structuredAddress?.shipping}
      billingAddress={structuredAddress?.billing}
      optional={optional}
      hideContinueToPayment={field?.hide_continue_to_checkout}
      hideBillingAddress={field?.hide_billing_address}
    />
  );
};
