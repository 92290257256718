import { AxiosError } from "axios";

import {
  FlowDataStructureResponse,
} from "apps/forms-structure/src/app/dto/FormDataStructureResponse.dto";
import { BaseAPI } from "@utils/base-api";
import { ANON_USER_ID } from "@/constants/auth";

const FLOW_REDIRECTS: Record<string, string> = {
  "fresh-new-user": "/forms/fresh-existing-user",
  "scoop-planet-new-user": "/forms/scoop-planet-existing-user",
  "scoop-health-new-user": "/forms/scoop-health-existing-user",
};

interface RedirectFlow {
  redirectPath: string;
}

export class FormsStructureAPI extends BaseAPI {

  public async getFlow(flowSlug: string): Promise<FlowDataStructureResponse | RedirectFlow | null> {
    try {
      const response = await this.axios.get(`form-structure/${flowSlug}`);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log("Axios Error getting flow", error);
        if (error.response?.status === 403) {
          return {
            redirectPath: FLOW_REDIRECTS[flowSlug] ?? `/login?redirectTo=/forms/${flowSlug}`,
          };
        }
      }
      console.log("Error getting flow", error);
    }
    return null;
  }

  public async getForm(formSlug: string): Promise<FlowDataStructureResponse | null> {
    try {
      const response = await this.axios.get(`form-structure/form/${formSlug}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  public setAnonCookie(idCookie: string) {
    this.axios.defaults.headers.common.Cookie = `${ANON_USER_ID}=${idCookie}`;
  }

}
