import { KatKinProduct, KatKinProductCategory } from "@forms/schema";

export const PRODUCT_CATEGORIES: Record<KatKinProduct, KatKinProductCategory> = {
  HEALTH_SCOOP_LITTER: "SCOOP",
  PLANET_SCOOP_LITTER: "SCOOP",
  FRESH: "FRESH",
  FRESH_TRIAL: "FRESH",
  UNKNOWN: "UNKNOWN",
  FRESH_SAVER: "FRESH",
  FRESH_FREEZER: "FRESH",
};
