import { FC, ReactNode } from "react";

import { FieldsetComponentTogglableDefaultState } from "@forms/schema";
import { useNewFormsServiceStore } from "@./state";

import Button from "../../base/Button/Button";

interface IFieldsetStateMap {
  key: string;
  newState: FieldsetComponentTogglableDefaultState;
}

interface IFieldsetStateButton {
  flowId: string;
  children: ReactNode;
  disabled?: boolean;
  newStates: IFieldsetStateMap[];
  linkingId?: string;
  to?: string;
}

export const FieldsetStateButton: FC<IFieldsetStateButton> = ({ flowId, newStates, children, disabled = false, linkingId = "0", to }) => {
  const { setFlowFieldsetData } = useNewFormsServiceStore();
  const onClick = () => {
    newStates.forEach((newState) => {
      setFlowFieldsetData(flowId, newState.key, { state: newState.newState }, linkingId);
    });

    // Have to do this because of how forms internal routing works, can't use to on button
    if (to) {
      const fieldset = document.getElementById(to);
      if (fieldset) {
        window.scrollTo({
          top: (fieldset.getBoundingClientRect().y + window.scrollY) - 100,
          behavior: "smooth",
        });
      }
    }
  };
  return (
    <>
      <Button onClick={onClick} disabled={disabled}>
        { children }
      </Button>
    </>
  );
};
