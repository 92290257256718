"use client";

import { FC } from "react";

import { Dropdown } from "../Dropdown/Dropdown";

interface INavBarUserProfile {
  name: string;
  initials: string;
  image?: string;
  onLogout(): void;
}

export const NavBarUserPreview: FC<INavBarUserProfile> = ({ name, initials, image, onLogout }) => (
  <div className="flex-shrink-0 flex border-t border-gray-200 hover:bg-gray-100 flex-row">
    <a href="#" className="no-underline flex-1 group block  p-4  ">
      <div className="flex items-center">
        { image ? (
          <div>
            <img
              className="inline-block h-10 w-10 rounded-full"
              src={image}
              alt={name}
            />
          </div>
        ) : (
          <div className="inline-block h-9 w-9 rounded-full bg-indigo-700 items-center justify-center flex font-bold text-white text-lg">
            { initials }
          </div>
        ) }
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
            { name }
          </p>
          <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
        </div>
      </div>
    </a>
    <Dropdown
      options={[ { text: "Logout", onClick: onLogout } ]}
    />
  </div>
);
