"use client";

import { FC, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

interface IDropdownItem {
  text: string;
  onClick?: () => void;
  href?: string;
}

interface Props {
  options: IDropdownItem[];
}

export const Dropdown: FC<Props> = ({ options }) => (
  <Menu as="div" className="relative flex items-center justify-center text-left px-2">
    <div>
      <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="h-7 w-7" aria-hidden="true" />
      </Menu.Button>
    </div>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute flex flex-col no-underline -translate-y-full right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          { options.map((option) => (
            <Menu.Item key={option.text}>
              { ({ active }) => (option.href ? (
                <a
                  href={option.href}
                  className={clsx(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "no-underline flex px-4 py-2 text-sm",
                  )}
                >
                  Account settings
                </a>
              ) : (
                <button
                  className={clsx(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "flex px-4 py-2 text-sm w-full",
                  )}
                  onClick={option.onClick}
                  type="button"
                >
                  { option.text }
                </button>
              )) }
            </Menu.Item>
          )) }
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
);
