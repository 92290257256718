"use client";

import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";

import { useTableSorter } from "../hooks";
import { DataTable } from "../DataTable/DataTable";

interface Props<T> {
  rows: T[];
  header: string;
  extractName(_: T): string;
  extractCount(_: T): number;
  component?: string;
  onClickRow?: (_: T) => void;
  groupedCounter?: (_: number)=> number;
  groupedHeader?: string;
}

export function CountsTable <T>({
  rows,
  header,
  extractCount,
  extractName,
  component = CountsTable.name,
  onClickRow,
  groupedCounter,
  groupedHeader = "Pallets",
}: Props<T>) {

  const columns = useMemo(() => {

    const columnHelper = createColumnHelper<T>();
    const tableColumns = [
      columnHelper.accessor(
        extractName,
        {
          id: header,
        },
      ),
      columnHelper.accessor(
        extractCount,
        {
          id: "Count",
        },
      ),
    ];

    if (groupedCounter) tableColumns.push(
      // @ts-ignore
      columnHelper.accessor(
        (row: T) => groupedCounter(extractCount(row)),
        {
          id: groupedHeader,
        },
      ),
    );

    return tableColumns;
  }, [ header, groupedCounter, groupedHeader ]);

  const sorting = useTableSorter();

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...sorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <DataTable
      onClickRow={onClickRow}
      component={component}
      allowClientSorting
      data={table}
    />
  );

}
