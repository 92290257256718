import {
  FC,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import Column from "apps/website/components/layout/Column/Column";
import AddProductCardList from "apps/website/components/feature/AddProductCardList/AddProductCardList";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import {
  AddProductCardFieldName,
  AddProductCardListField,
  Allergens,
  FieldData,
} from "@forms/schema";
import { useNewFormsServiceStore } from "@./state";
import {
  getCurrentFlow,
  getFieldValue,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { Theme } from "apps/website/maps/Theme.map";

const defaultProductTitle: Record<AddProductCardFieldName, string> = {
  sprinklesQuantity: "Chicken Sprinkles",
  nibblesChickenQuantity: "Chicken Nibbles",
  nibblesSalmonQuantity: "Salmon Nibbles",
};

const productAllergies: Record<AddProductCardFieldName, Allergens[]> = {
  sprinklesQuantity: [ "CHICKEN" ],
  nibblesChickenQuantity: [ "CHICKEN" ],
  nibblesSalmonQuantity: [ "SALMON" ],
};

export interface IFormServiceAddProductCardList {
  field: AddProductCardListField;
  flowId: string;
  linkingId: string;
  next(): void;
}

export const FormServiceAddProductCardList: FC<IFormServiceAddProductCardList> = (
  { field, flowId, linkingId, next },
) => {
  const { setFlowFieldValue } = useNewFormsServiceStore();

  const onToggleInBasket = (inBasket: boolean, fieldName: string, quantity: number) => {
    const updatedQuantity: FieldData = inBasket ?
      { submitValue: quantity, displayValue: quantity } :
      { submitValue: 0, displayValue: 0 };
    setFlowFieldValue(flowId, fieldName, updatedQuantity, linkingId);
    const anyNibblesInBasket = productsToDisplay.filter((i) => i.can_toggle_in_basket).map((x) => getFieldValue(flowId, x.fieldName, "0")).some((y) => +(y?.data?.submitValue || 0) > 0);
    setFlowFieldValue(flowId, "addedNibbles", { submitValue: anyNibblesInBasket }, linkingId);
  };

  const getIsProductInBasket = useCallback((fieldName: string): boolean => {
    const flow = getCurrentFlow(flowId);
    const foundField = flow?.fields?.find(
      (mappedField) => mappedField.key.fieldName === fieldName && mappedField.key.linkingId === linkingId,
    );
    if (!foundField) return false;
    return Number(foundField.data.submitValue) > 0;
  }, [ flowId, linkingId ]);

  const getAllCatAllergies = useCallback((): Allergens[] => getCurrentFlow(flowId)?.fields?.filter((mappedField) => mappedField.key.fieldName === "catAllergies")
    .map((mappedAllergies) => mappedAllergies.data.submitValue as Allergens)
    .filter((mappedAllergy) => mappedAllergy !== undefined).flat() ?? [], [ flowId ]);

  const productsToDisplay = useMemo(() => {
    const withoutAllergies = field.products.filter(
      (product) => !getAllCatAllergies()?.some(
        (allergy) => productAllergies[product.fieldName].includes(allergy),
      ),
    );
    const hiddenProducts = withoutAllergies.filter((x) => x.hidden);
    if (field.max_to_show) {
      return [ ...withoutAllergies.slice(0, field.max_to_show), ...hiddenProducts ];
    }
    return [ ...withoutAllergies, ...hiddenProducts ];
  }, [ field.max_to_show, field.products, getAllCatAllergies ]);

  useEffect(() => {
    if (productsToDisplay.length < 1) {
      next();
    }
  }, [ productsToDisplay ]);

  return (
    <Column>
      <>
        <AddProductCardList
          products={
            productsToDisplay.map((product) => ({
              ...product,
              component: product.component,
              title: product.title ?? defaultProductTitle[product.fieldName],
              name: product.fieldName,
              image: getStoryBlokImage(product.image, true, ImageMax.HALF_CONTAINER_LG),
              canToggleInBasket: product.can_toggle_in_basket,
              hasBeenAddedToBasket: getIsProductInBasket(product.fieldName),
              tag: {
                theme: (product.tag_theme || "brand") as Theme,
                text: product.tag,
              },
              productSize: product.product_size,
              salePrice: product.sale_price,
              ctaText: product.cta_text ?? "Add to box",
              billingInformation: product.billing_information,
              onToggleInBasket: (
                inBasket: boolean,
                fieldName: string,
                quantity: number,
              ) => onToggleInBasket(inBasket, fieldName, quantity),
            }))}
          maxToShow={field.max_to_show}
        />
      </>
    </Column>
  );
};
