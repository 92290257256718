"use client";

import { FC, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  component?: string;
}

export const Panel: FC<Props> = ({
  component = Panel.name,
  children,
}) => (
  <div
    data-component={component}
    className="bg-white col-span-4 rounded-lg overflow-hidden p-2 py-4 md:px-4 border border-gray-200"
  >
    { children }
  </div>
);
