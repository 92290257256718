import { FC, ReactNode } from "react";

import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Button from "apps/website/components/base/Button/Button";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { ErrorAction } from "@forms/schema";
import Error from "apps/website/components/form/Error/Error";
import Column from "apps/website/components/layout/Column/Column";

export interface IFormError {
  message?: string;
  action?: ErrorAction;
  children: ReactNode;
}

interface PerformErrorAction {
  text: string;
  to: string;
}

const formErrorActionMap: Record<ErrorAction, PerformErrorAction > = {
  LOGIN: {
    text: "Login",
    to: "/login",
  },
};

const FormError: FC<IFormError> = ({ message, action, children }) => (
  <Column>
    <Error>
      { message && (
        <>
          <Text tag="span" size={legacySizeCollectionMap.body} display="title" align="center">{ message }</Text>
          <Spacer size="md" />
        </>
      ) }
      <Text size={{ default: "3xs", md: "2xs" }} align="center">{ children }</Text>
      { action && (
        <>
          <Spacer size="lg" />
          <Button to={formErrorActionMap[action].to}>{ formErrorActionMap[action].text }</Button>
        </>
      ) }
    </Error>
  </Column>
);

export default FormError;
