"use client";

import clsx from "clsx";
import { FC } from "react";

interface Props {
  value?: number;
  onChange?: (_: string) => void;
  placeholder?: string;
  className?: string;
  containerClass?: string;
  disabled?: boolean;
  step?: number | string;
  min?: number | string;
  max?: number | string;
  /** If this is true and someone sets the value to undefined,
   * we unset the value (useful if you want to explicitly unset a number by setting it to undefined).
   * If this is false, we keep the current value (useful if you want to allow "in-between" outputs, e.g. "-"
   * when someone is trying to type -1 or "1." if someone is trying to type 1.2).
   * False by default. */
  emptyOnUndefined?: boolean;
}
export const NumberInput: FC<Props> = ({
  value,
  onChange = () => {},
  placeholder = "",
  className = "",
  containerClass = "",
  disabled = false,
  step = "1",
  min,
  max,
  emptyOnUndefined = false,
}) => (
  <div data-component={NumberInput.name} className={containerClass}>
    <div className={clsx(
      "relative bg-white flex items-center rounded-md border border-gray-300 shadow-sm focus-within:border-indigo-500 sm:text-sm",
      disabled && "bg-gray-100",
    )}>
      <input
        disabled={disabled}
        type="number"
        value={value ?? (emptyOnUndefined ? "" : value)}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        min={min}
        max={max}
        step={step}
        className={clsx(
          "placeholder-gray-300 flex-1 border-none appearance-none focus:ring-0 bg-transparent",
          className,
          disabled && "text-gray-400 cursor-not-allowed",
        )}
      />
    </div>
  </div>
);
