"use client";

import { FC, useEffect, useState } from "react";
import { usePathname, useSearchParams } from "next/navigation";

import {
  FlowDataStructureResponse,
} from "apps/forms-structure/src/app/dto/FormDataStructureResponse.dto";
import { ITrustpilotInfo } from "apps/website/api/Trustpilot";
import { FlowProvider, useFlow } from "apps/website/contexts/flow";
import { TrustpilotProvider } from "apps/website/contexts/trustpilot";
import { getDefaultLayoutData } from "apps/website/utils/content/layout";
import FlowLayout from "apps/website/layouts/Flow";
import { useReturnToPath } from "apps/website/hooks/useReturnToPath";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

import { IHeaderLink } from "../../base/Button/Button.map";

import FormsContent from "./FormContent";

interface IFlowContent {
  flow: FlowDataStructureResponse;
  form: string;
  section: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story?: any | boolean;
  trustpilot: ITrustpilotInfo;
  preview: boolean;
}

const FlowContent: FC<IFlowContent> = (
  {
    flow,
    form: initialForm,
    section: initialSection,
    preview,
    trustpilot,
    story,
  },
) => {
  const flowData = useFlow();
  const { back, currentSectionIndex, currentSection } = flowData;
  const [ hasHydrated, setHasHydrated ] = useState<boolean>(false);
  const [ flowGuestLinks, setFlowGuestLinks ] = useState<IHeaderLink[]>([ { id: "g-0", name: "Login", to: "/login" } ]);
  const { chainQueryParamsToUrl } = useQueryParams();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  useEffect(() => {
    setHasHydrated(true);
  }, []);
  const layoutData = getDefaultLayoutData(story);
  const returnToPath = useReturnToPath();

  const [ hideBackButton, setHideBackButton ] = useState(true);

  useEffect(() => {
    setHideBackButton((currentSectionIndex === 0 && !returnToPath) || currentSection.hide_back_button || false);
  }, [ currentSectionIndex, returnToPath, currentSection.hide_back_button ]);

  useEffect(() => {
    setFlowGuestLinks(flowGuestLinks.map((link) => ({ ...link, to: chainQueryParamsToUrl(link.to) })));
  }, [ searchParams, pathname ]);

  return (
    <FlowProvider
      flow={flow}
      initialForm={initialForm}
      initialSection={initialSection}
      preview={preview}
    >
      <TrustpilotProvider info={trustpilot}>
        <FlowLayout
          {
            ...layoutData
          }
          headerLinks={{ ...layoutData.headerLinks, guestLinks: flowGuestLinks }}
          hideFooter={flow.mappedFlow.hide_site_footer}
          historyNavigation={{
            back,
            hide: hideBackButton,
          }}
        >
          <div className="bg-light-grey">
            { hasHydrated && (
              <>
                <FormsContent { ...flowData } />
              </>
            ) }
          </div>
        </FlowLayout>
      </TrustpilotProvider>
    </FlowProvider>
  );
};
export default FlowContent;
