import { FC, useCallback, useState } from "react";
import { usePostHog } from "posthog-js/react";

import Text from "apps/website/components/base/Text/Text";
import {
  FieldsetComponentDisplayOption,
  FillableFieldComponent,
} from "@forms/schema";
import {
  useNewFormsServiceStore,
  FieldData,
  getFieldValue,
  getFlowFieldValuesForInterpolation,
} from "@./state";
import { ColumnSize, fieldSpanObjectMap } from "apps/website/maps/Form.map";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import { useUpdateEffect } from "@/hooks";
import {
  instanceOfAddress,
} from "apps/forms-structure/src/app/utils/forms-schema-type-guards";

import Column from "../../layout/Column/Column";
import Spacer from "../../layout/Spacer/Spacer";

import { FormServiceCheckboxGroup } from "./CheckboxGroup";
import { FormServiceInput } from "./Input";
import { FormServiceRadioSelect } from "./RadioSelect";
import { FormServiceScoreSelect } from "./ScoreSelect";
import { FormServiceSelect } from "./Select";
import { FormServiceStepCounterSelect } from "./StepCounterSelect";
import { FormServiceCheckbox } from "./Checkbox";
import { FormServiceAddress } from "./specialist/Address";
import FormError from "./Error";
import { FormServiceTypeToSelect } from "./TypeToSelect";
import { FormServiceUpdateFresh } from "./UpdateFresh";
import { FormServiceTextArea } from "./Textarea";
import { FormServiceDataMatcher } from "./DataMatcher";

const formComponents = {
  input: FormServiceInput,
  textarea: FormServiceTextArea,
  select: FormServiceSelect,
  radio_select: FormServiceRadioSelect,
  data_matcher: FormServiceDataMatcher,
  step_counter_select: FormServiceStepCounterSelect,
  checkbox_group: FormServiceCheckboxGroup,
  checkbox: FormServiceCheckbox,
  address: FormServiceAddress,
  type_to_select: FormServiceTypeToSelect,
  score_field: FormServiceScoreSelect,
  update_fresh_field: FormServiceUpdateFresh,
};

export type FormComponentName = keyof typeof formComponents;

interface IField {
  component: FormComponentName;
  field: FillableFieldComponent;
  linkingId: string;
  flowSlug: string;
  fieldsetDisplay?: FieldsetComponentDisplayOption;
  error?: string;
  showField?: boolean;
  captureFieldValue?: boolean;
}

export const Field: FC<IField> = ({
  component,
  field,
  flowSlug,
  fieldsetDisplay,
  linkingId,
  error,
  showField,
  captureFieldValue,
}) => {
  const Component = formComponents[component];
  // TODO: Enable for isValid on all input and select fields
  // const { setFlowFieldValue, getFlowFieldErrors, flows, errors } = useNewFormsServiceStore();
  const { setFlowFieldValue, getFlowFieldErrors } = useNewFormsServiceStore();
  const posthog = usePostHog();

  // @ts-ignore
  const fieldValue = getFieldValue(flowSlug, field.component === "data_matcher" ? field.to_match : field.name, linkingId);
  const setFieldValue = useCallback((slug: string, fieldName: string, value: FieldData, linkId: string) => {
    setFlowFieldValue(slug, fieldName, value, linkId);
  }, [ setFlowFieldValue ]);
  // const [ isValid, setIsValid ] = useState(false);
  const [ isValid ] = useState(false);

  const [ wasFieldVisible, setWasFieldVisible ] = useState<boolean>(showField || false);

  useUpdateEffect(() => {
    if (wasFieldVisible && showField === false) {
      const unsetValue: undefined | string[] = Array.isArray(fieldValue) ? [] : undefined;
      setFieldValue(flowSlug, field.name, { submitValue: unsetValue, displayValue: undefined }, linkingId);
    }
    setWasFieldVisible(showField || false);

  }, [ showField ]);

  const onChange = useCallback((changeData: FieldData) => {
    console.log(changeData);
    if (captureFieldValue) {
      posthog.capture("set_field_value", {
        fieldName: field.name,
        fieldValue: changeData.submitValue,
        linkingId,
      });
    }
    setFieldValue(flowSlug, field.name, changeData, linkingId);

    if (changeData.additionalData && Array.isArray(changeData.additionalData)) {
      if (Array.isArray(changeData.additionalData)) {
        changeData.additionalData.forEach((mappedAdditionalData) => {
          if (mappedAdditionalData?.data) {
            Object.entries(mappedAdditionalData.data).forEach(([ fieldName, data ]) => {
              setFieldValue(
                flowSlug,
                fieldName,
                data,
                mappedAdditionalData.linkingId,
              );
            });
          }
        });
      }
    }
  }, [ captureFieldValue, field.name, flowSlug, linkingId, posthog, setFieldValue ]);

  // TODO: Enable for isValid on all input and select fields
  // useEffect(() => {
  //   setIsValid(isFieldValid(flowSlug, linkingId, field.name as FieldName, field.optional || true));
  // }); // We want to run this on every change because it relies on data outside it's scope.
  return (
    <>
      { Component && showField && (
        <Column
          spans={ field?.column_size ? fieldSpanObjectMap[field.column_size as ColumnSize] : 12 }
          className={ `${(
            [ "withImage", "withIcon" ].includes(fieldsetDisplay ?? "") &&
            field?.column_size === "breakout"
          ) && "transform -translate-x-[70px] w-[calc(100%+70px)]"
          } ${(field?.component === "type_to_select" && "relative focus-within:z-10")}` }
        >
          <>
            { field.name === "address" && (
              <>
                { (instanceOfAddress(field) && !field.hide_asterix_message) && (
                  <>
                    <Text size={ "xs" }> * indicates a required field </Text>
                    <Spacer size="md" />
                  </>
                ) }
              </>
            ) }
            <Component
              // @ts-ignore
              field={ { ...field, label: field.label ? interpolateString(field.label, getFlowFieldValuesForInterpolation(flowSlug, linkingId, "GET_RELATED"), Interpolator.FRONTEND) : undefined } }
              optional={ field.optional }
              onChange={ (changeData) => onChange(changeData) }
              value={ fieldValue?.data || { submitValue: "", displayValue: "" } }
              selected={ fieldValue?.data }
              flowId={ flowSlug }
              linkingId={ linkingId }
              error={ error }
              isValid={ isValid }
            />
            { field.name !== "address" && getFlowFieldErrors(flowSlug, field.name, linkingId)?.map((x) => (
              <>
                <Spacer size="sm" />
                <FormError
                  key={ `${x.fieldName}-${x.linkingId}` }
                  message={ fieldValue?.data.error?.details }
                  action={ fieldValue?.data.error?.action }
                >
                  { x.error?.message }
                </FormError>
              </>
            )) }
          </>
        </Column>
      ) }
    </>
  );
};
