"use client";

import { ComponentType, FC } from "react";
import Axios, { AxiosError } from "axios";

import { useToggle } from "@/hooks";

import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

import { Button, ButtonSize, ButtonVariant } from "./Button";

interface Props {
  /** This should also do anything you need to be done once the action succeeds
   * (e.g. reloading the page or navigating away).
   * If the function returns a boolean, then if false, the button will stay disabled,
   * else if true, the button will be enabled. If the function returns undefined,
   * the button will stay disabled. */
  action: () => Promise<void | boolean>;
  /** Should be in the form Click OK to if you're sure you want to X */
  confirmationText?: string;
  buttonText: string;
  size?: ButtonSize;
  variant?: ButtonVariant;
  Icon?: ComponentType;
  /** Used for error logs/popups. Should be the X in "There was a problem ${X}" */
  actionNameForError: string;
}

export const ActionButton: FC<Props> = ({
  action,
  confirmationText,
  buttonText,
  size,
  variant,
  Icon,
  actionNameForError,
}: Props) => {
  const [ performingAction, setPerformingAction ] = useToggle(false);

  const performAction = async () => {
    if (confirmationText && !window.confirm(confirmationText)) {
      return;
    }
    try {
      setPerformingAction(true);
      setPerformingAction(!await action());
    } catch (e) {
      console.error(`There was a problem ${actionNameForError}`);
      console.error(e);
      if (Axios.isAxiosError(e)) {
        const axiosError: AxiosError = e;
        if (axiosError.response) {
          console.error(`status: ${JSON.stringify(axiosError.response.status)}`);
          console.error(`data: ${JSON.stringify(axiosError.response.data)}`);
          alert(`There was a problem ${actionNameForError}:\n` +
            `status: ${JSON.stringify(axiosError.response.status)}\n` +
            `data: ${JSON.stringify(axiosError.response.data)}`);
        } else {
          alert(`Sorry, something went wrong with ${
            actionNameForError
          }. Please try again later.\n${
            e.message
          }`);
        }
      } else {
        alert(`Sorry, something went wrong with ${
          actionNameForError
        }. Please try again later.\n${
          (typeof e === "object" && e && "message" in e) ? e.message : ""
        }`);
      }
      setPerformingAction(false);
    }
  };

  return (<Button
    disabled={performingAction}
    onClick={performAction}
    size={size}
    variant={variant}
    Icon={Icon}>
    { performingAction
      ? <LoadingSpinner size={22} color="white"/>
      : <span>{ buttonText }</span> }
  </Button>);
};
