"use client";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

interface Props {
  value: string;
  onChange?: (_: string) => void;
  loading?: boolean;
  onSubmit?: () => void;
  placeholder?: string;
  className?: string;
  autocomplete?: boolean;
}

export const SearchBar: FC<Props> = ({
  value,
  onChange = () => {},
  loading = false,
  placeholder = "Type here to search...",
  onSubmit,
  className = "",
}) => (
  <div data-component={SearchBar.name} className={className}>
    <div className="relative bg-white flex items-center rounded-md border-2 border-gray-300 shadow-sm focus-within:border-indigo-500 sm:text-sm">
      <input
        autoComplete="off"
        type="search"
        name="search-orders"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="flex-1 border-none focus:ring-0 bg-transparent"
        onKeyDown={(e) => {
          if (e.key === "Enter" && onSubmit) onSubmit();
        }}
      />
      { loading && <LoadingSpinner size={24} className="m-2"/> }
      { !loading && (
        <button
          type="button"
          onClick={onSubmit}
          className="m-1 hover:text-indigo-500"
        >
          <MagnifyingGlassIcon height={20} className="m-1"/>
        </button>
      ) }
    </div>
  </div>
);
