import { ChangeEvent, FC, ReactNode } from "react";
import clsx from "clsx";

import useA11y from "apps/website/hooks/useA11y";

import Tooltip, { ITooltip } from "../../feature/Tooltip/Tooltip";
import { FontStyle } from "../Label/Label.map";
import Label from "../Label/Label";
import { Align } from "../../base/Text/Text.map";
import Text from "../../base/Text/Text";

export interface IScoreSelectProps {
  label: string;
  name: string;
  optional?: boolean;
  hideLabel?: boolean;
  labelPosition?: Align;
  labelStyle?: FontStyle;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  component?: string;
  className?: string;
  children?: ReactNode;
  tabbable?: boolean;
  tooltip?: ITooltip;
  value?: string;
}

const ScoreSelect: FC<IScoreSelectProps> = ({ onChange, name, label, labelStyle, optional = false, className, hideLabel = false, component = "ScoreSelect", tabbable = true, children, tooltip, labelPosition = "default", value }) => {
  const { UUID } = useA11y();

  return (
    <div data-component={component} className={className} data-theme="form-input">
      { (tooltip && tooltip.placement === "above") && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
      <Label
        label={label}
        align={labelPosition}
        fontStyle={labelStyle}
        hideLabel={hideLabel}
        UUID={UUID}
        optional={optional}
      />
      <div className="w-full flex justify-between">
        <>
          { Array.from(Array(10).keys()).map((number) => (
            <label key={number} className={"relative flex flex-1 flex-wrap justify-between space-x-1 items-center group max-w-[50px]" }>
              <input
                type="radio"
                id={UUID}
                name={name}
                value={`${number + 1}`}
                onChange={onChange}
                className={"absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none outline-none cursor-pointer"}
                tabIndex={tabbable ? 0 : -1}
                data-testid={`ScoreSelectRadio-[${number}]`}
                checked={value === `${number + 1}`}
              />
              <div
                className={clsx(
                  "aspect-square rounded-full block bg-white cursor-pointer box-border border-2",
                  "flex flex-1 justify-center items-center border-solid border-black peer-checked:bg-black peer-checked:text-white relative",
                )}>
                <Text display="title" size={{ default: "3xs", md: "2xs" }} color="inherit">{ number + 1 }</Text>
              </div>
            </label>
          ))
          }
        </>
      </div>
      { children }
      { (tooltip && (!tooltip.placement || tooltip.placement === "default")) && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
    </div>
  );
};

export default ScoreSelect;
