"use client";

import Link from "next/link";
import { FC } from "react";
import { UsersIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { usePathname } from "next/navigation";

export interface INavBarItem {
  name: string;
  href: string;
  icon: typeof UsersIcon;
}

export const NavBarItem: FC<INavBarItem> = ({ name, href, icon }) => {
  const pathname = usePathname();
  const Icon = icon;

  const isCurrentPath = href === "/"
    ? pathname === "/"
    : pathname.startsWith(href);

  return (
    (<Link
      key={name}
      href={href}
      className={clsx(
        "no-underline",
        isCurrentPath
          ? "bg-gray-100 text-indigo-700 font-bold" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
        "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
      )}>

      <Icon
        className={clsx(
          isCurrentPath
            ? "text-indigo-700" : "text-gray-400 group-hover:text-gray-500",
          "mr-3 flex-shrink-0 h-6 w-6",
        )}
        aria-hidden="true"
      />
      { name }

    </Link>)
  );
};
