import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import { redirect } from "next/navigation";
import { cache } from "react";
import isUUID from "validator/lib/isUUID";

dayjs.extend(AdvancedFormat);

/**
 * Helper function for server components to validate a date string
 * and return the dayjs-parsed date, or redirect somewhere if not
 */
export const validateDateStringOrRedirect = cache((
  dateString: string,
  redirectTo: string,
) => {
  const date = dayjs(dateString as string | undefined, "YYYY-MM-DD", true);
  if (!date.isValid()) redirect(redirectTo);
  return date;
});

/**
 * Helper function for server components to validate a number
 * If the validation fails, will redirect.
 * if a fallback is passed, that will be used if number is not passed in.
 * If no fallback is passed, then the redirect will happen.
 */
export const validateNumberOrRedirect = cache((
  n?: string | null,
  redirectTo = "/",
  fallback: number | undefined = undefined,
): number => {

  if (n === undefined || n === null || n === "") {
    if (fallback) return fallback;
    redirect(redirectTo);
  }

  const parsed = parseInt(n, 10);
  if (Number.isNaN(parsed)) redirect(redirectTo);

  return parsed;
});

export const validateUuidOrRedirect = cache((
  id: string,
  redirectTo = "/",
) => {
  if (isUUID(id)) return id;

  return redirect(redirectTo);
});

export const DAYJS_FORMAT = {
  DATETIME: "HH:mm ddd Do MMM 'YY",
  DATE: "ddd Do MMM 'YY",
};
