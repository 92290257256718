import { FC } from "react";

import Button from "apps/website/components/base/Button/Button";

export interface IFlowback {
  onClick(): void;
  isDisabled: boolean;
}

export const FlowBack: FC<IFlowback> = ({
  onClick,
  isDisabled,
}) => (
  <Button onClick={onClick} design="underline" disabled={isDisabled}>Back</Button>
);
