import { FC, useCallback, useEffect } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

import Input from "apps/website/components/form/Input/Input";
import { InputField } from "libs/forms-schema/src";
import { AllowedTypes } from "apps/website/components/form/Input/Input.map";
import {
  FieldData,
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { getStoryBlokTooltip } from "apps/website/utils/storyblok/forms";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import { useQueryParams } from "apps/website/hooks/useQueryParams";
import useField from "apps/website/hooks/useField";

export interface IFormServiceInput {
  flowId: string;
  field: InputField;
  value?: FieldData;
  optional?: boolean,
  onChange(value: FieldData): void;
  linkingId: string;
  isValid?: boolean;
}

export const FormServiceInput: FC<IFormServiceInput> = ({
  flowId,
  field,
  value,
  optional,
  onChange,
  linkingId,
  isValid,
}) => {
  // After load, check if value is empty
  // if the value is empty, but the query parameter is not, use query param
  // clear queryParam

  const pathname = usePathname();
  const query = useSearchParams();
  const router = useRouter();
  const { chainQueryParamsToUrl } = useQueryParams();

  useEffect(() => {
    if (!value && field.query_parameter && query.has(field.query_parameter)) {
      const queryParamValue = query.get(field.query_parameter) as string;
      onChange({ submitValue: queryParamValue, displayValue: queryParamValue });
      const newQueryParams: Record<string, string> = { };
      for (const [ key, queryValue ] of query.entries()) {
        if (key !== field.query_parameter) {
          newQueryParams[key] = queryValue;
        }
      }

      void router.replace(chainQueryParamsToUrl(pathname, newQueryParams));
    }
  }, [ value, field, onChange, pathname, query ]);

  const handleChange = useCallback((strValue: string) => {
    console.log("handling input change.");
    onChange({
      submitValue: strValue,
      displayValue: strValue,
    });
  }, [ onChange ]);

  const { getLabelStyle } = useField(field);

  return (
    <>
      <Input
        labelStyle={ getLabelStyle }
        type={field.type as AllowedTypes}
        name={`${field.name}[${linkingId}]`}
        min={field.min}
        max={field.max}
        label={field.label ?? ""}
        optional={optional}
        placeholder={interpolateString(field.placeholder as string, getFlowFieldValuesForInterpolation(flowId, linkingId, "GET_RELATED"), Interpolator.FRONTEND)}
        hideLabel={field.hide_label}
        labelPosition={field.label_position}
        onChange={(event) => handleChange(event.target.value)}
        value={value?.displayValue as string}
        isValid={isValid}
        tooltip={field.tooltip ? getStoryBlokTooltip(
          field.tooltip,
          getFlowFieldValuesForInterpolation(flowId, linkingId, field.tooltip?.[0]?.interpolation_behavior),
        ) : undefined}
      />
    </>
  );
};
