import { FC, useCallback, useEffect } from "react";

import { StringRadioSelectField } from "libs/forms-schema/src";
import {
  FieldData,
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { getStoryBlokTooltip } from "apps/website/utils/storyblok/forms";
import useField from "apps/website/hooks/useField";

import ScoreSelect from "../../form/ScoreSelect/ScoreSelect";

export interface IFormServiceScoreSelect {
  flowId: string;
  field: StringRadioSelectField;
  onChange(value: FieldData): void;
  optional?: boolean;
  linkingId: string;
  selected?: FieldData;
}

export const FormServiceScoreSelect: FC<IFormServiceScoreSelect> = ({
  flowId,
  field,
  onChange,
  optional,
  linkingId,
  selected,
}) => {

  useEffect(() => {
    if (selected?.submitValue) {
      onChange({
        submitValue: selected.submitValue,
        displayValue: selected.submitValue,
      });
    }
  }, []);

  const handleChange = useCallback((strValue: string) => {
    onChange({
      submitValue: strValue,
      displayValue: strValue,
    });
  }, [ onChange ]);

  const { getLabelStyle } = useField(field);

  return (
    <ScoreSelect
      labelStyle={ getLabelStyle }
      label={field.label ?? ""}
      name={`${field.name}[${linkingId}]`}
      optional={optional}
      hideLabel={field.hide_label}
      labelPosition={field.label_position}
      onChange={(event) => handleChange(event.target.value)}
      tooltip={field.tooltip ? getStoryBlokTooltip(
        field.tooltip,
        getFlowFieldValuesForInterpolation(flowId, linkingId, field.tooltip?.[0]?.interpolation_behavior),
      ) : undefined}
      value={selected?.submitValue ? `${selected.submitValue}` : undefined}
    />
  );
};
