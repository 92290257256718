import { FC } from "react";

import { WithTestID } from "apps/website/types";
import Text from "apps/website/components/base/Text/Text";
import {
  AvailableTag,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

import Icon from "../../base/Icon/Icon";

export interface ICatDividerProps extends WithTestID {
  catName: string;
  tag?: AvailableTag;
}

const lineClass = "border-b-2 flex-1 h-0";

const CatDivider: FC<ICatDividerProps> = ({
  catName,
  tag = "h2",
  "data-testid": testId,
}) => (
  <div
    data-component={CatDivider.name}
    data-testid={testId}
    className="w-full flex flex-row items-center justify-center"
  >
    <div className={lineClass} />
    <div className="mx-2 flex flex-row items-center">
      <div className="w-[28px] h-[28px] lg:w-[44px] lg:h-[44px]">
        <Icon icon="catProfile" size="fit" />
      </div>
      <Text
        display="title"
        tag={tag}
        size={legacySizeCollectionMap.titleMd}
        className="ml-1"
      >
        { catName }
      </Text>
    </div>
    <div className={lineClass} />
  </div>
);

export default CatDivider;
