import { FC, useCallback, useEffect } from "react";

import { StringRadioSelectField } from "libs/forms-schema/src";
import { OptionColumnSize } from "apps/website/maps/Form.map";
import {
  FieldData,
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { getStoryBlokTooltip } from "apps/website/utils/storyblok/forms";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import useField from "apps/website/hooks/useField";

import RadioSelect, { TOptionStyle } from "../../form/RadioSelect/RadioSelect";

export interface IFormServiceRadioSelect {
  flowId: string;
  field: StringRadioSelectField;
  onChange(value: FieldData): void;
  optional?: boolean;
  linkingId: string;
  selected?: FieldData;
  optionStyle?: TOptionStyle;
}

export const FormServiceRadioSelect: FC<IFormServiceRadioSelect> = ({
  flowId,
  field,
  onChange,
  optional,
  linkingId,
  selected,
  optionStyle,
}) => {

  useEffect(() => {
    if (!selected?.submitValue) {
      const initialSelectedOption = field.options.map((option) => ({
        isSelected: option.selected,
        optValue: option.value,
        title: option.title,
      })).find((s) => s.isSelected);
      if (initialSelectedOption) {
        onChange({
          submitValue: initialSelectedOption.optValue,
          displayValue: initialSelectedOption.title,
        });
      }
    }
  }, []);

  const handleChange = useCallback((strValue: string) => {
    const actualSelectedOption = field.options.map((option) => ({
      isSelected: option.selected,
      optValue: option.value,
      title: option.title,
    })).find((s) => s.optValue as string === strValue);
    if (actualSelectedOption) {
      onChange({
        submitValue: actualSelectedOption.optValue,
        displayValue: actualSelectedOption.title,
      });
    }
  }, [ field.options, onChange ]);

  const { getLabelStyle } = useField(field);

  return (
    <>
      <RadioSelect
        labelStyle={ getLabelStyle }
        label={ field.label ?? "" }
        name={ `${field.name}[${linkingId}]` }
        options={ field.options.map((option) => ({
          optionStyle: optionStyle || field.style || "default",
          value: option.value,
          name: interpolateString(option.title, getFlowFieldValuesForInterpolation(flowId, linkingId, "GET_RELATED"), Interpolator.FRONTEND),
          selected: option.value === selected?.submitValue || (!selected?.submitValue && option.selected),
          icon: option.icon,
          image: option.image,
          advancedOptions: option.advanced_options,
          secondaryTag: option.secondary_tag,
          display: field.display,
          description: option.description,
        })) }
        optionSize={ field.optionSize as OptionColumnSize }
        optional={ optional }
        hideLabel={ field.hide_label }
        labelPosition={ field.label_position }
        onChange={ (event) => handleChange(event.target.value) }
        tooltip={ field.tooltip ? getStoryBlokTooltip(
          field.tooltip,
          getFlowFieldValuesForInterpolation(flowId, linkingId, field.tooltip?.[0]?.interpolation_behavior),
        ) : undefined }
      />
    </>
  );
};
