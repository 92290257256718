import { FC, useEffect, useState } from "react";

import { StringDataMatcherField } from "libs/forms-schema/src";
import { FieldData } from "libs/state/src/lib/stores/useFormServiceStore";

export interface IFormServiceDataMatcher {
  flowId: string;
  field: StringDataMatcherField;
  onChange(value: FieldData): void;
  linkingId: string;
  value: FieldData;
}

export const FormServiceDataMatcher: FC<IFormServiceDataMatcher> = ({
  field,
  onChange,
  value,
}) => {

  const [ trueValue, setTrueValue ] = useState<FieldData>();

  useEffect(() => {
    setTrueValue(value);
  }, []);

  useEffect(() => {
    if (value.submitValue && trueValue?.submitValue !== value.submitValue) {
      setTrueValue(value);
      const matchedValue = field.options.find((option) => option.value_to_match === value.submitValue);

      if (matchedValue) {
        onChange({
          submitValue: matchedValue.value,
          displayValue: matchedValue.title,
        });
      }
    }
  }, [ value ]);

  return (
    <></>
  );
};
