import {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";

import { FormComponent, Story } from "@forms/schema";
import {
  FlowDataStructureResponse,
} from "apps/forms-structure/src/app/dto/FormDataStructureResponse.dto";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Text from "apps/website/components/base/Text/Text";

interface IProgressStep {
  slug: string;
  name: string;
  sections: string[];
}
interface ILayoutDefault {
  flow: FlowDataStructureResponse;
  currentForm: string;
  currentSection: string;
}

export const ProgressSteps: FC<ILayoutDefault> = ({ flow, currentForm, currentSection }) => {

  const [ progressStepMap, setProgressStepMap ] = useState<IProgressStep[]>([]);

  const getCompletionPercentage = useCallback((step: IProgressStep) => {
    const currentProgressStepForm = progressStepMap.find((mappedStep) => mappedStep.slug === currentForm);
    const currentProgressStepFormIndex = progressStepMap.findIndex((mappedStep) => mappedStep.slug === currentForm);
    const currentProgressStepStepIndex = progressStepMap.findIndex((mappedStep) => mappedStep.slug === step.slug);
    if (currentProgressStepFormIndex > currentProgressStepStepIndex) return "100%";
    if (currentProgressStepFormIndex === currentProgressStepStepIndex && currentProgressStepForm) {
      if (currentProgressStepForm?.sections.length < 2) return "100%";
      const sectionStep = currentProgressStepForm.sections.findIndex(
        (mappedSection) => mappedSection === currentSection,
      ) + 1;

      return `${(sectionStep / currentProgressStepForm.sections.length) * 100}%`;
    }
    return "0%";
  }, [ currentForm, currentSection, progressStepMap ]);

  useEffect(() => {
    setProgressStepMap(flow.mappedFlow.forms.map((mappedForm: Story<FormComponent>): IProgressStep => ({
      slug: mappedForm.slug,
      name: mappedForm.name,
      sections: mappedForm.content.sections.map((mappedSection) => mappedSection.slug),
    })));
  }, [ flow ]);

  return (
    <>
      { progressStepMap.length > 1 && (
        <>
          <Spacer size="lg" />
          <div className="flex w-full">
            { progressStepMap.map((step, index) => (
              <div
                key={`progress-${index}`}
                className="flex flex-1 mx-1 flex-col text-center"
              >
                <Text align="center" size={{ default: "5xs", md: "2xs" }} className="uppercase px-1">{ step.name }</Text>
                <div className="h-2 w-full bg-white">
                  <div className="h-full bg-brand transition-all ease-in-out duration-300" style={{ width: getCompletionPercentage(step) }}>
                  </div>
                </div>
              </div>
            )) }
          </div>
        </>
      ) }
      <Spacer size="lg" />
    </>
  );
};
