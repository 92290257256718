import { FC, useCallback, useMemo } from "react";

import { FieldName } from "@forms/schema";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { getFieldValue } from "@./state";

interface IReviewFormDetails {
  flowSlug: string;
  label: string;
  fieldNames: FieldName[];
  linkingId: string;
  url: string;
}

export const ReviewFormDetails: FC<IReviewFormDetails> = ({
  flowSlug,
  label,
  fieldNames,
  url,
  linkingId,
}) => {

  const fieldValue = useMemo(() => {
    const results = fieldNames.map((fieldName) => getFieldValue(flowSlug, fieldName, linkingId)?.data?.displayValue?.toString() ?? "");
    return results?.join(" ") || "";
  }, [ fieldNames, flowSlug, linkingId ]);
  const navigate = useCallback(() => {
    window.location.href = url;
  }, []);

  return (
    <Column>
      <div className="flex justify-between">
        <Text tag="h3" display="title" size={legacySizeCollectionMap.titleXs}>{ label }</Text>
        <button onClick={navigate}>
          <Text tag="span" display="title" className="underline" size={legacySizeCollectionMap.titleXs}>Change</Text>
        </button>
      </div>
      <Text display="subtitle">
        { fieldValue }
      </Text>
      <Spacer size="md" />
    </Column>
  );
};
